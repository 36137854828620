import {Injectable, NgZone} from '@angular/core';
import {interval} from "rxjs";
import {MessageService} from "primeng/api";
import {T_EVENT} from "src/app/constant";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private msg: MessageService, private _zone: NgZone) {
    this._zone.runOutsideAngular(() => {
      const sub = interval(1000 * 2).subscribe((_) => {
        const e = localStorage.getItem('expiry');
        if (e) {
          if (parseInt(e) < Date.now()) {
            this.msg.add({
              key: 'action-error',
              data: {event: T_EVENT.LOGIN},
              sticky: true,
              summary: 'Session Expired',
              severity: 'error',
              closable: false,
              detail: 'Please Login Again....'
            });
            sub?.unsubscribe();
          }
        }
      });
    })

  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  // isAuthenticated(): boolean {
  //   const token = this.getToken();
  //
  //   return !!token;
  // }
  //

  async isAuthenticated() {
    const token = this.getToken();
    if (token) {
      if (await this.isTokenExpired(token)) {
        localStorage.removeItem('token');
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private async isTokenExpired(_token: string) {
    // const headers = new Headers();
    // headers.append("Authorization", _token);
    //
    // const requestOptions: RequestInit = {
    //   method: 'GET',
    //   headers: headers,
    //   redirect: 'follow'
    // };
    //
    // console.log(requestOptions, this.conf);
    // const res = await fetch(`${this.conf.appConfig.appBaseUrl}user/self/profile`, requestOptions);
    const res = {status: 200};
    return res.status !== 200;
  }
}
