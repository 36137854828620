import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import moment from 'moment-timezone';

@Injectable({
  providedIn: "root"
})
export class DateFormatterService {
  constructor(private http: HttpClient, public configService: ConfigService) {
  }

  private userTimezone?: Timezone;


  getTimezone() {
    return new Promise((resolve, reject) => {
      const tz = localStorage.getItem('userTimezone');
      if (tz) {
        this.userTimezone = JSON.parse(tz);
        resolve(tz);
      } else {
        this.http.get<Timezone>(`${this.configService.appConfig.appBaseUrl}user/self/timezone`)
          .subscribe({
            next: res => {
              this.setUserTimezone(res);
              resolve(res);
            },
            error: (er) => reject(er)
          });
      }
    })

  }

  setUserTimezone(timezone: Timezone) {
    this.userTimezone = timezone;
    localStorage.setItem('userTimezone', JSON.stringify(timezone))
  }

  resetUserTimezone() {
    this.userTimezone = null;
  }

  convertDateToSelectedTimezone(date, dateFormat?) {
    if (!date) {
      return "";
    }
    let timezone: string;
    if (this.userTimezone && this.userTimezone.timezone) {
      timezone = this.userTimezone.timezone;
    } else if (sessionStorage.getItem('timezone')) {
      timezone = sessionStorage.getItem('timezone');
    } else {
      throw new Error('No timezone found');
    }

    if (dateFormat) {
      const ans = moment(date)?.tz(timezone);
      return ans?.format(dateFormat?.toUpperCase());
    } else {
      return moment(date)?.tz(timezone)?.format('LLL');
    }
  }

  getFormattedTimeSlotForSingleTime(date, timeFormat?) {
    const slot = this.convertDateToSelectedTimezone(date, timeFormat ?? 'LT');
    let finalTime = ``;
    let time = '';
    let zValue = '';
    const aTimes = slot?.split(' ');
    if (aTimes && aTimes.length > 1 && aTimes[0] && aTimes[1]) {
      time = aTimes[0];
      zValue = aTimes[1].slice(0, 1).toLowerCase();
    }
    finalTime = `${time} ${zValue}`;
    return finalTime;
  }

  getDateComponents(dateString) {
    const inputDate = new Date(dateString);

    const day = inputDate.getDate();
    const month = new Intl.DateTimeFormat('en-IN', {month: 'short'}).format(inputDate);
    const year = inputDate.getFullYear();

    return {
      day,
      month,
      year
    };
  }

  getTimeDifferenceFromString(startDateString, endDateString, returnJustDiff = false) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const timeDifferenceInMilliseconds = Math.abs(startDate.getTime() - endDate.getTime());
    const minutes = Math.floor(timeDifferenceInMilliseconds / (1000 * 60));
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

    let timePassed;
    const endTime = this.getFormattedTimeSlotForSingleTime(endDate)

    if (minutes < 60) {
      timePassed = `${minutes} min${minutes !== 1 ? 's' : ''}`;
    } else if (hours < 24) {
      timePassed = `${hours} hr${hours !== 1 ? 's' : ''}`;
      const mins = minutes % 60
      if (mins) {
        timePassed += `, ${mins} min${mins !== 1 ? 's' : ''}`;
      }
    } else {
      timePassed = `${days} day${days !== 1 ? 's' : ''}`;
    }
    if (returnJustDiff) {
      return timePassed
    } else {
      return {
        timePassed,
        endTime
      }
    }

  }

}

export interface Timezone {
  timezone: string;
}
