import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { ConfigService } from './config.service';

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  getTranslation(lang: string): Observable<any> {
    const token = localStorage.getItem('token');
    const url = `${this.configService.appConfig.appBaseUrl}languageMeta/id/${lang}`;
    const headers = new HttpHeaders({
      authorization: token ? token : '',
    });
     return new Observable<any>(s => {
      const langFile = localStorage.getItem('lang_' + lang);
      if (langFile) {
        s.next(JSON.parse(langFile));
      }
      this.http.get(url, {headers: headers}).subscribe(res => {
        localStorage.setItem('lang_' + lang, JSON.stringify(res));
        s.next(res);
        s.complete();
      });
    });
  }
}
