import { optimizedCompoundCondition as e, FieldCondition as t, CompoundCondition as r, ITSELF as o, NULL_CONDITION as a, ObjectQueryParser as n, buildAnd as i } from "@ucast/core";
export { defaultInstructionParsers as defaultParsers } from "@ucast/core";
function s(e, t) {
  if (!Array.isArray(t)) throw new Error(`"${e.name}" expects value to be an array`);
}
function p(e, t) {
  if (s(e, t), !t.length) throw new Error(`"${e.name}" expects to have at least one element in array`);
}
const l = e => (t, r) => {
    if (typeof r !== e) throw new Error(`"${t.name}" expects value to be a "${e}"`);
  },
  c = {
    type: "compound",
    validate: p,
    parse(t, r, {
      parse: o
    }) {
      const a = r.map(e => o(e));
      return e(t.name, a);
    }
  },
  f = c,
  d = {
    type: "compound",
    validate: p
  },
  u = {
    type: "field",
    validate(e, t) {
      if (!(t && (t instanceof RegExp || t.constructor === Object))) throw new Error(`"${e.name}" expects to receive either regular expression or object of field operators`);
    },
    parse(e, o, a) {
      const n = o instanceof RegExp ? new t("regex", a.field, o) : a.parse(o, a);
      return new r(e.name, [n]);
    }
  },
  $ = {
    type: "field",
    validate(e, t) {
      if (!t || t.constructor !== Object) throw new Error(`"${e.name}" expects to receive an object with nested query or field level operators`);
    },
    parse(e, r, {
      parse: a,
      field: n,
      hasOperators: i
    }) {
      const s = i(r) ? a(r, {
        field: o
      }) : a(r);
      return new t(e.name, n, s);
    }
  },
  w = {
    type: "field",
    validate: l("number")
  },
  y = {
    type: "field",
    validate: s
  },
  x = y,
  v = y,
  h = {
    type: "field",
    validate(e, t) {
      if (!Array.isArray(t) || 2 !== t.length) throw new Error(`"${e.name}" expects an array with 2 numeric elements`);
    }
  },
  m = {
    type: "field",
    validate: l("boolean")
  },
  g = {
    type: "field",
    validate: function (e, t) {
      if (!("string" == typeof t || "number" == typeof t || t instanceof Date)) throw new Error(`"${e.name}" expects value to be comparable (i.e., string, number or date)`);
    }
  },
  b = g,
  E = b,
  j = b,
  O = {
    type: "field"
  },
  R = O,
  _ = {
    type: "field",
    validate(e, t) {
      if (!(t instanceof RegExp) && "string" != typeof t) throw new Error(`"${e.name}" expects value to be a regular expression or a string that represents regular expression`);
    },
    parse(e, r, o) {
      const a = "string" == typeof r ? new RegExp(r, o.query.$options || "") : r;
      return new t(e.name, o.field, a);
    }
  },
  q = {
    type: "field",
    parse: () => a
  },
  A = {
    type: "document",
    validate: l("function")
  };
var N = Object.freeze({
  __proto__: null,
  $and: c,
  $or: f,
  $nor: d,
  $not: u,
  $elemMatch: $,
  $size: w,
  $in: y,
  $nin: x,
  $all: v,
  $mod: h,
  $exists: m,
  $gte: g,
  $gt: b,
  $lt: E,
  $lte: j,
  $eq: O,
  $ne: R,
  $regex: _,
  $options: q,
  $where: A
});
class P extends n {
  constructor(e) {
    super(e, {
      defaultOperatorName: "$eq",
      operatorToConditionName: e => e.slice(1)
    });
  }
  parse(e, t) {
    return t && t.field ? i(this.parseFieldOperators(t.field, e)) : super.parse(e);
  }
}
const z = N;
export { v as $all, c as $and, $ as $elemMatch, O as $eq, m as $exists, b as $gt, g as $gte, y as $in, E as $lt, j as $lte, h as $mod, R as $ne, x as $nin, d as $nor, u as $not, q as $options, f as $or, _ as $regex, w as $size, A as $where, P as MongoQueryParser, z as allParsingInstructions };
