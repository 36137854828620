import {createReducer, on} from "@ngrx/store";
import {IAccountState} from "../../../app/interface/maestro-interface"
import {accountActions} from ".";

export const initialState: IAccountState = {
  profile: null,
  isInternetAvailable: true,
  syncing: {
    isSyncing: false,
    msg: 'hold',
    error: undefined,
    url: ''
  },
  transactionalData: {
    server: false,
  },
  versionUpdate: false,
};

export const AccountReducer = createReducer(
  initialState,
  on(accountActions.onUserProfileFetchSuccess, (state, {details}) => {
    localStorage.setItem('userId', details['_id']);
    return {
      ...state,
      profile: details,
    };
  }),
  on(accountActions.onUserProfileFetchFailure, (state) => {
    return {
      ...state,
      profile: null,
    };
  }),
  on(accountActions.checkInterconnectionAvailable, (state, {isOnline}) => {
    return {
      ...state,
      isInternetAvailable: isOnline,
    };
  }),
  on(accountActions.syncing, (state, syncing) => {
    return {
      ...state,
      syncing: {
        ...state.syncing, ...syncing
      }
    }
  }),
  on(accountActions.loadTransactionalData, (state, {server}) => {
    return {
      ...state,
      transactionalData: {
        server
      }
    }
  }),
  on(accountActions.updateVerisonAvailability, (state, {update}) => {
    return {
      ...state,
      versionUpdate: update
    }
  })
);
