import { inject, Injectable } from '@angular/core';
import { AppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { KioskSelectors } from '../state/kiosk';
import { ConfigService } from './config.service';
import { IotPrintersService } from '@zdIOTPlatform/ng-iot-printers';
// import { IotPrintersService } from '../../../dist/iot-printers';
import {
  setPrinterOffline,
  setPrinterStatus,
} from 'src/app/state/kiosk/kiosk.action';
import { IAssetInfo, IStatus } from 'src/app/interface/maestro-interface';
import IminPrinter from 'src/assets/js/imin-printer.esm.browser.min.js';
// declare let receiptline: any;
declare var epson: any | undefined;

@Injectable({
  providedIn: 'root',
})
export class PrintReceiptService {
  printer = inject(IotPrintersService);
  store = inject(Store<AppState>);
  conf = inject(ConfigService);
  userName = '';
  constructor() {
    try {
      this.printer.init({
        PrinterSettings: this.store.select(KioskSelectors.getPrinterData),
        receiptTemplates: this.store.select(
          KioskSelectors.getReceiptTemplateSelector
        ),
        userName: this.userName,
        serverUrl: this.conf.appConfig.appBaseUrl,
        iMinSdk: IminPrinter,
        epsonSdk: epson,
      });

      this.printer.printerStatus$.subscribe((status) => {
        console.log('printerStatus$', status);

        if (status.error) {
          this.store.dispatch(setPrinterOffline());
          return;
        }

        if (Object.keys(status).length > 0) {
          this.store.dispatch(
            setPrinterStatus({status: status as Record<string, IStatus>})
          );
          return;
        }
      });
    } catch (e) {
      console.error('Error in print receipt service', e);
    }
  }

  clean() {
    try {
      this.printer.clean();
      return Promise.resolve('clean');
    } catch (e) {
      console.error(e);
    }
  }

  async printReceipt(data: any, tName?: string) {
    return this.printer.printReceipt(data, tName);
  }

  async retryConnToPrinter(ip: string) {
    return this.printer.retryConnToPrinter(ip);
  }

  async printReceiptByContent(template: string, asset: IAssetInfo) {
    this.printer.rawPrint(asset, template, asset?.printConfig);
  }
}
