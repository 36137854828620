import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { SaleReport } from 'src/app/interface/maestro-interface';
import { RxdbSyncService } from 'src/sync/rxdb.sync.service';
import { Observable } from 'rxjs';
import { PrintReceiptService } from 'src/app/services/print-receipt.service';
import { CONSTANT, PRINT } from 'src/app/constant';
import { groupBy } from 'lodash';
import { username } from 'src/app/state/account/account.selector';
import { AppState } from 'src/app/state/app.state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class KioskAnalyticsService {
  orderHeaders = [
    {
      key: 'orderSourceName',
      header: 'order source',
      width: 22,
      type: 'string',
    },
    {
      key: 'store',
      header: 'store',
      width: 15,
      type: 'string',
    },
    {
      key: 'category',
      header: 'category',
      width: 18,
      type: 'string',
    },
    {
      key: 'product',
      header: 'product',
      width: 17,
      type: 'string',
    },
    {
      key: 'quantity',
      header: 'quantity',
      width: 18,
      type: 'string',
    },
    {
      key: 'sales',
      header: 'sales',
      width: 15,
      type: 'number',
    },
    {
      key: 'tax',
      header: 'tax amount',
      width: 15,
      type: 'number'
    },
  ];

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private rxdb: RxdbSyncService,
    private print: PrintReceiptService,
    private store: Store<AppState>
  ) { }

  d = (ms: number) => {
    const a = new Date(0);
    a.setUTCMilliseconds(ms);
    return a.toLocaleString();
  };

  getLocalDbSalesData(args: {
    start: number;
    end: number;
    isCount: boolean;
    skip?: number;
    limit?: number;
    groupedByDineType?: boolean
  }) {
    return new Promise((resolve, reject) => {
      this.rxdb
        .getOrdersBetweenTime(args.start, args.end)
        .then((r) => {
          const data = new Map<string, any>();
          // const groupedData = new Map();
          if (args.groupedByDineType) {
            for (let i2 = 0; i2 < r.length; i2++) {
              const i = r[i2];
              const o = i?.['_data']?.['data'];
              const dineType = o?.['dineType'] || 'unknown';
              if (!data.has(dineType)) {
                data.set(dineType, {
                  dineType: dineType,
                  store: o?.['pickupLocation']?.['name'],
                  items: []
                });
              }

              const dineTypeData = data.get(dineType);

              for (let i1 = 0; i1 < o?.['orderDetails']?.['items'].length; i1++) {
                const j = o?.['orderDetails']?.['items'][i1];
                let existingItem = dineTypeData.items.find(item => item.product === j['name']);
                if (existingItem) {
                  existingItem.quantity += j['quantity'];
                  existingItem.sales += j['quantity'] * j['unitPriceBeforeTax'];
                  existingItem.tax += j?.['totalPrice'] - (j['quantity'] * j['unitPriceBeforeTax']);
                  existingItem.parcelCharge += j?.['parcelChargeTotal'] ?? 0
                } else {
                  dineTypeData.items.push({
                    category: j?.['cName'],
                    product: j?.['name'],
                    quantity: j?.['quantity'],
                    sales: j['quantity'] * j['unitPriceBeforeTax'],
                    tax: j?.['totalPrice'] - (j['quantity'] * j['unitPriceBeforeTax']),
                    parcelCharge: j?.['parcelChargeTotal'] ?? 0
                  });
                }
              }
            }
          } else {
            for (let i2 = 0; i2 < r.length; i2++) {
              const i = r[i2];
              const o = i?.['_data']?.['data'];
              const dineType = o?.['dineType'] || 'unknown';
              for (let i1 = 0; i1 < o?.['orderDetails']?.['items'].length; i1++) {
                const j = o?.['orderDetails']?.['items'][i1];
                if (data.has(j['_id'])) {
                  const v = data.get(j['_id']);
                  v.quantity += j['quantity'];
                  v.sales += j['quantity'] * j['unitPriceBeforeTax'];
                  v.tax += j?.['totalPrice'] - (j['quantity'] * j['unitPriceBeforeTax']);
                  v.parcelCharge += j?.['parcelChargeTotal'] ?? 0
                  v.dineType = dineType
                } else {
                  data.set(j['_id'], {
                    orderSourceName: o?.['orderSource'],
                    store: o?.['pickupLocation']?.['name'],
                    category: j?.['cName'],
                    product: j?.['name'],
                    quantity: j?.['quantity'],
                    sales: j['quantity'] * j['unitPriceBeforeTax'],
                    tax: j?.['totalPrice'] - (j['quantity'] * j['unitPriceBeforeTax']),
                    parcelCharge: j?.['parcelChargeTotal'] ?? 0,
                  });
                }
              }
            }
          }
          const v = Array.from(data.values());
          const l = v.length - args.skip < args.limit ? undefined : args.limit;
          const resp = {
            headers: this.orderHeaders,
            data: v.slice(args.skip, l),
            total: args.groupedByDineType ? {
              amount: (v.reduce((acc, order) => {
                const orderTotal = order.items.reduce((orderAcc, item) => {
                  const sales = parseFloat(item.sales);
                  const tax = parseFloat(item.tax);
                  const parcelCharge = item.parcelCharge ? parseFloat(item.parcelCharge) : 0;
                  return orderAcc + sales + tax + parcelCharge;
                }, 0);
                return acc + orderTotal;
              }, 0)).toFixed(2),
              startDate: this.d(args.start),
              endDate: this.d(args.end),
            } : {
              amount: (v.reduce((acc, cur) => {
                const sales = parseFloat(cur?.sales);
                const tax = parseFloat(cur?.tax);
                const parcelCharge = cur?.parcelCharge ? parseFloat(cur?.parcelCharge) : 0;
                return acc + sales + tax + parcelCharge;
              }, 0)).toFixed(2),
              startDate: this.d(args.start),
              endDate: this.d(args.end),
            },
            showAmount: true,
          };
          console.log(resp);
          if (args.isCount) {
            resolve({ count: data.size });
          } else {
            resolve(resp);
          }
        })
        .catch((e) => reject(e));
    });
  }

  getProductSalesByStore(
    startDateMilliSec: any,
    endDateMilliSec: any,
    reportType: string,
    pickUpStoreIds: string,
    isCount: boolean,
    skip?: number,
    limit?: number,
    orderSource?: string,
    server = false
  ) {
    if (server) {
      let url = `${this.configService.appConfig.appBaseUrl}sok/analytics/productSales?startDateMilliSec=${startDateMilliSec}`;

      if (endDateMilliSec) {
        url += `&endDateMilliSec=${endDateMilliSec}`;
      }
      if (reportType) {
        url += `&reportType=${reportType}`;
      }
      if (pickUpStoreIds) {
        url += `&pickUpStoreIds=${pickUpStoreIds}`;
      }
      if (orderSource) {
        url += `&orderSource=${orderSource}`;
      }

      if (isCount) {
        url += `&isCount=true`;
      } else {
        url += `&isCount=false`;
        if (skip != null || skip !== undefined) {
          url += '&skip=' + skip;
        }
        if (limit != null || limit !== undefined) {
          url += '&limit=' + limit;
        }
      }
      return this.http.get<SaleReport>(url);
    } else {
      return new Observable((observer) => {
        this.getLocalDbSalesData({
          start: startDateMilliSec,
          end: endDateMilliSec,
          skip,
          limit,
          isCount,
        })
          .then((r) => observer.next(r))
          .catch((e) => observer.error(e))
          .finally(() => observer.complete());
      });
    }
  }

  printSalesReports(
    startDate: number,
    endDate: number,
    printType: string,
    isGroupByDinetype: boolean,
    server = false
  ) {
    if (server) {
      let url = `${this.configService.appConfig.appBaseUrl}maestro/analytics/productSales?`;
      if (startDate) {
        url = `${url}&startDateMilliSec=${startDate}`;
      }
      if (endDate) {
        url = `${url}&endDateMilliSec=${endDate}`;
      }
      if (printType) {
        url = `${url}&reportType=${printType}`;
      }
      if (isGroupByDinetype) {
        url = `${url}&groupByDineType=${isGroupByDinetype}`;
      }
      return this.http.post<SaleReport>(url, {});
    } else {
      return new Observable((observer) => {
        this.getLocalDbSalesData({
          start: startDate,
          end: endDate,
          isCount: false,
          groupedByDineType: isGroupByDinetype
        })
          .then(async (r) => {
            try {
              const pDataEntries = Object.entries(
                groupBy(r?.['data'], 'category')
              );
              const data = {
                storeName: JSON.parse(
                  localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION)
                )?.name,
                currentDate: new Date().toLocaleString(),
                startDate: this.d(startDate),
                endDate: this.d(endDate),
                productData: isGroupByDinetype ? this.groupItemsByCategory(r?.['data']) :
                  pDataEntries.map(([key, value]) => [value, key]) || {},
                username: await new Promise((r) =>
                  this.store.select(username).subscribe(r)
                ),
              };
              const res = await this.print.printReceipt(
                data,
                isGroupByDinetype ? PRINT.T_PRODUCT_SALES_REPORT_BY_DINETYPE : PRINT.T_PRODUCT_SALES_REPORT
              );
              observer.next(res);
            } catch (e) {
              observer.error(e);
            }
          })
          .catch((e) => observer.error(e));
      });
    }
  }

  groupItemsByCategory(data) {
    return data.map(entry => {
      const groupedItems = Object.values(entry.items.reduce((acc, item) => {
        if (!acc[item.category]) {
          acc[item.category] = {
            category: item.category,
            items: []
          };
        }
        acc[item.category].items.push(item);
        return acc;
      }, {}));

      return {
        dineType: entry.dineType,
        store: entry.store,
        items: groupedItems
      };
    });
  }
}
