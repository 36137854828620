import { createTranslatorFactory as o, ITSELF as t } from "@ucast/core";
export * from "@ucast/core";
import { MongoQueryParser as r, allParsingInstructions as e, defaultParsers as c } from "@ucast/mongo";
export * from "@ucast/mongo";
import { createJsInterpreter as n, allInterpreters as f, compare as s } from "@ucast/js";
export * from "@ucast/js";
function i(o) {
  return o instanceof Date ? o.getTime() : o && "function" == typeof o.toJSON ? o.toJSON() : o;
}
const m = (o, t) => s(i(o), i(t));
function p(e, c, f) {
  const s = new r(e),
    i = n(c, Object.assign({
      compare: m
    }, f));
  if (f && f.forPrimitives) {
    const o = {
        field: t
      },
      r = s.parse;
    s.setParse(t => r(t, o));
  }
  return o(s.parse, i);
}
const a = p(e, f),
  u = p(["$and", "$or"].reduce((o, t) => (o[t] = Object.assign({}, o[t], {
    type: "field"
  }), o), Object.assign({}, e, {
    $nor: Object.assign({}, e.$nor, {
      type: "field",
      parse: c.compound
    })
  })), f, {
    forPrimitives: !0
  }),
  j = a;
export { p as createFactory, j as filter, a as guard, u as squire };
