import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import * as _ from 'lodash';
// import { ICategorySequencePayload } from '../../../state/Assets/interfaces/IAsset.category.model';
import {
  AvailableKiosks,
  IGroupsOfKioskSettingsWithModule,
  IKioskTableFormSettings,
  IKisoskSettingsMetaData,
  IReceiptTemplate,
  IUserProfile,
  IUserShift,
  KAgent, Login,
} from '../interface/maestro-interface';
// import { number } from 'echarts';
import {BehaviorSubject, Observable} from 'rxjs';
import {RxdbSyncService} from "src/sync/rxdb.sync.service";
import {DB, MODE, TABLE_STATUS} from "src/app/constant";
import {LogArgs} from "../decorators/LogArgs";
import {AppState} from "src/app/state/app.state";
import {Store} from "@ngrx/store";
import {loadServerData} from "src/app/state/account/account.selector";
import {tap} from "rxjs/operators";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class KioskStoreService {
  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private rxdb: RxdbSyncService,
    private store: Store<AppState>
  ) {
  }

  getKAgents(): Observable<KAgent> {
    const url = `${this.configService.appConfig.appBaseUrl}kAgent/all`;
    return this.http.get<KAgent>(url);
  }

  rebootKioskAgent(machineId: string, rebootTo: string): Observable<{ msg: string }> {
    const url = `${this.configService.appConfig.appBaseUrl}kAgent/executeCommand/id/${machineId}`;
    const body = {
      "type": rebootTo,
      "command": "restart"
    }
    return this.http.put<{
      msg: string
    }>(url, body)
  }

  delinkKioskAgent(kAgentId: string): Observable<{ msg: string }> {
    const url = `${this.configService.appConfig.appBaseUrl}kAgent/delink/id/${kAgentId}`;
    return this.http.put<{ msg: string }>(url, {});
  }

  getKioskList(): Observable<AvailableKiosks> {
    const url = `${this.configService.appConfig.appBaseUrl}kAgent/kiosks`;
    return this.http.get<AvailableKiosks>(url);
  }

  linkKiosk(kAgentId: string, kioskId: string): Observable<{ msg: string }> {
    const url = `${this.configService.appConfig.appBaseUrl}kAgent/link/id/${kAgentId}`;
    return this.http.put<{ msg: string }>(url, {"kioskId": kioskId});
  }

  changeMachineName(kAgentId: string, machineName: string): Observable<KAgent> {
    const url = `${this.configService.appConfig.appBaseUrl}kAgent/id/${kAgentId}`;
    return this.http.put<KAgent>(url, {"machineName": machineName});
  }

  getKioskSettingsMetaData(groupType: string) {
    const url = `${this.configService.appConfig.appBaseUrl}account/settings/metaData/kiosk?groupType=${groupType}`;
    return this.http.get<IKisoskSettingsMetaData>(url)
  }

  getKioskSettingsMetaDataByGroups() {
    const url = `${this.configService.appConfig.appBaseUrl}settings/module/kiosk/types`;
    return this.http.get<IGroupsOfKioskSettingsWithModule>(url)
  }

  putKioskGenericSettings(payload: IKioskTableFormSettings, type: string) {
    const url = `${this.configService.appConfig.appBaseUrl}sok/settings/moduleType/${type}`;
    return this.http.put<IKioskTableFormSettings>(url, payload)
  }

  quantityCheck(cartItems: IProductData[], storeId: string) {
    const url = `${this.configService.appConfig.appBaseUrl}sok/stores/quantityCheck`;
    const body = {
      products: cartItems,
      storeId: storeId,
    };
    return this.http.post(url, body);
  }

  getProductGroups() {
    const url = `${this.configService.appConfig.appBaseUrl}assetGroup/?skip=0&limit=10`;
    return this.http.get(url);
  }

  effectsAddNewStoreLocation(payload: unknown) {
    const url = `${this.configService.appConfig.appBaseUrl}sok/stores/createStore`;
    return this.http.post(url, payload);
  }

  effectGetAllPaymentGateways() {
    const url = `${this.configService.appConfig.appBaseUrl}payments/gateways/all`;
    return this.http.get(url)
  }

  effectSendPaymentVerification(orderId: string | number) {
    const url = `${this.configService.appConfig.appBaseUrl}payments/updateStatus/status/verified/orderId/${orderId}`;
    return this.http.put(url, {})
  }

  effectPrintReceiptOnSOk(orderId: string | number) {
    const url = `${this.configService.appConfig.appBaseUrl}sok/orders/printReciept/orderId/${orderId}`;
    return this.http.post(url, {})
  }

  effectSendTransaction(payload: any, orderId: string): Observable<{ msg: string; status: boolean }> {
    const url = `${this.configService.appConfig.appBaseUrl}sok/orders/uploadFile/orderId/${orderId}/fileType/image?eventType=paymentProof`;
    const formData = new FormData();

    if (payload?.file) {
      const file = dataURLtoBlob(payload.file);
      formData.append('file', file, 'filename.png');
    }

    return this.http.put<{
      msg: string,
      status: boolean
    }>(url, formData)
  }

  dynamicAPIFromMetaData(urlRes: string, method: string) {
    const url = this.configService.appConfig.appBaseUrl + urlRes
    if (method === "get") {
      return this.http.get(url)
    }
  }

  getKioskOrderPageLayout() {
    return new Observable(o => {
      this.rxdb.getDbData(DB.POS_LAYOUT).subscribe(v => {
        const res = v?.[0]?.['_data']?.['data'];
        if (res) {
          o.next(res);
        } else {
          const url = `${this.configService.appConfig.appBaseUrl}settings/account/all/query/typeId:maestroLayout,module:kiosk?isMultiple=false`
          return this.http.get(url).subscribe((res) => {
            this.rxdb.insertData({
              userId: localStorage.getItem('userId'),
              data: res,
              collectionName: DB.POS_LAYOUT,
              updatedAt: Date.now()
            }).then(console.log).catch(console.error);
          })
        }
      })
    });
  }

  getKioskSettings(terminalId: string) {
    return new Observable(o => {
      const subscribe = this.rxdb.getDbData(DB.SETTINGS).subscribe(v => {
        const dbData = v?.[0]?.['_data']?.['data'];
        // if (res) {
        //   o.next(res);
        // } else {
        //
        // }
        console.log(dbData, v);
        this.http.get(`${this.configService.appConfig.appBaseUrl}sok/settings/?terminalId=${terminalId}`).subscribe({
          next: (res) => {
            this.rxdb.insertData({
              data: res,
              collectionName: DB.SETTINGS,
              updatedAt: Date.now()
            }).then(_ => o.next(res)).catch(console.error);
            subscribe.unsubscribe();
          },
          error: (er) => {
            console.error(er);
            o.next(dbData);
            o.unsubscribe();
          }
        });
      })
    });
  }

  getStoreList() {
    console.log('getStoreList')
    return new Observable(o => {
      this.rxdb.getDbData(DB.POS_STORE_LIST,).subscribe(v => {
        const dbData = v?.[0]?.['_data']?.['data'];

        this.http.get(`${this.configService.appConfig.appBaseUrl}asset?isLinkedReq=false&fI=name,noofFloors&type=location`)
          .subscribe({
            next: (res) => {
              if (_.isEqual(res, dbData)) {
                o.next(dbData)
              } else {
                this.rxdb.insertData({
                  userId: localStorage.getItem('userId'),
                  data: res,
                  collectionName: DB.POS_STORE_LIST
                }).then(_r => {
                  o.next(res);
                  o.unsubscribe();
                }).catch(console.error);
              }
            },
            error: er => {
              console.error(er)
              o.next(dbData);
            }
          });

      })
    });
  }

  getCategoriesAndProducts() {
    return new Observable<ICategoryData[]>(o => {
      const api = (dbData?: ICategoryData[]) => {
        const URL = `${this.configService.appConfig.appBaseUrl}maestro/categoryInfo/products`;
        const url = `${URL}/?isPopulate=true&categoryFI=name,parcelCharge,color,_id,sequence&productFI=_id,metaInfo.itemName,metaInfo.parcelCharge,metaInfo.price,metaInfo.prices,metaInfo.outOfStockStatus,description,groups,assetsLinked,parcelCharge`;
        this.http.get<ICategoryData[]>(url).subscribe({
          next: (res) => {
            this.rxdb.insertData({
              userId: localStorage.getItem('userId'),
              data: res,
              collectionName: DB.POS_CAT_PRODUCTS
            }).then(console.log)
              .catch(console.error);

            o.next(res);
            subscribe.unsubscribe();
          },
          error: (er) => {
            console.error(er);
            o.next(dbData);
            o.unsubscribe();
          },
        });
      }

      const subscribe = this.rxdb.getDbData(DB.POS_CAT_PRODUCTS).subscribe({
        next: (v) => {
          const dbData = v?.[0]?.['_data']?.['data'];
          api(dbData);
        },
        error: (err) => {
          console.error(err);
          api();
        }
      });
    });
  }

  getMaestroAreas() {
    return new Observable(o => {
      this.rxdb.getDbData(DB.POS_AREA,).subscribe(v => {
        const res = v?.[0]?.['_data']?.['data'];
        // console.log('getMaestroAreas', res);
        if (res) {
          o.next(res);
        } else {
          const url = `${this.configService.appConfig.appBaseUrl}maestro/areas`;
          return this.http.get(url).subscribe((res) => {
            this.rxdb.insertData({
              userId: localStorage.getItem('userId'),
              data: res,
              collectionName: DB.POS_AREA
            }).then(console.log).catch(console.error);
          });
        }
      })
    });
  }

  getOperators() {
    return new Observable(o => {
      const subscribe = this.rxdb.getDbData(DB.POS_OPERATORS).subscribe(v => {
        let dbLogins: Login[] = v?.[0]?.['_data']?.['data'];
        this.http.get<Login[]>(`${this.configService.appConfig.appBaseUrl}maestro/operators`).subscribe({
          next: (res) => {
            try {
              subscribe.unsubscribe();
              res = res.filter(r => !!r);

              localStorage.setItem(DB.POS_OPERATORS, JSON.stringify({
                accountId: localStorage.getItem('accountId'),
                data: res
              }));

              this.rxdb.insertData({
                data: {
                  data: res,
                  accountId: localStorage.getItem('accountId')
                },
                collectionName: DB.POS_OPERATORS
              }).then(console.log).catch(console.error);

              o.next(res);
            } catch (e) {
              o.next(dbLogins);
              subscribe.unsubscribe();
            }
          },
          error: (err) => {
            console.error(err);
            o.next(dbLogins);
            subscribe.unsubscribe();
          }
        });
      })
    });
  }

  getMaestroInfo() {
    return new Observable<PosData>(o => {
      const userId = localStorage.getItem('userId');

      const subscribe = this.rxdb.getDbData(DB.POS_INFO).subscribe(v => {
        const dbData = v?.[0]?.['_data']?.['data'] as PosData;
        console.log('getMaestroInfo', dbData);

        const url = `${this.configService.appConfig.appBaseUrl}maestro/info`;
        this.http.get<PosData>(url).subscribe({
          next: (res) => {

            subscribe.unsubscribe();
            this.rxdb.insertData({
              userId: userId,
              data: res,
              collectionName: DB.POS_INFO
            }).then(console.log)
              .catch(console.error)
              .finally(() => o.next(res));
            // if (res?.posId === dbData?.posId) {
            //   o.next(res as PosData)
            //   subscribe.unsubscribe();
            // } else {
            //   this.rxdb.insertData({
            //     userId: userId,
            //     data: res,
            //     collectionName: DB.POS_INFO
            //   }).then(_r => {
            //     o.next(res as PosData)
            //     subscribe.unsubscribe();
            //     // o.unsubscribe();
            //   }).catch(console.error)
            // }
          },
          error: (er) => {
            console.error(er)
            o.next(dbData);
          }
        });
      })
    });
  }

  @LogArgs()
  getMaestroTableWithAreaId(areaId?: string, status?: string) {
    return new Observable(o => {
      if (this.configService.mode === MODE.OFFLINE) {
        const get = this.rxdb.getTables(areaId, status) as BehaviorSubject<any>;

        const sub = get.subscribe(_v => {
          const res: any[] = _v?.map((t: any) => Array.isArray(t?.['_data']?.['data']) ? undefined : t?.['_data']?.['data'])
            .filter((x: any) => !!x);

          if (res.length === 0) {
            const testSub = this.store.select(loadServerData).subscribe(res => {
              if (res) {
                this.http.get(`${this.configService.appConfig.appBaseUrl}maestro/tableInfo?isPopulate=true`)
                  .subscribe(async (res: []) => {
                    // const f1 = t => t?.['aProperties']?.['status'] != 'available';
                    //
                    // const orders: any = res.filter(f1).map(o => {
                    //   o = o?.['aProperties']?.['orderData'];
                    //   return {
                    //     data: o,
                    //     collectionName: 'orders',
                    //     _id: o?.['_id'],
                    //     updatedAt: Date.now()
                    //   }
                    // });

                    const tables = new Array<unknown>();
                    res.forEach((t: any) => {
                      if (t?.['aProperties']?.['status'] != TABLE_STATUS.AVAILABLE) {
                        delete t['aProperties']['orderData'];
                        delete t['aProperties']['orderDocId'];
                        t['aProperties']['status'] = TABLE_STATUS.AVAILABLE;
                      }
                      tables.push({
                        data: t,
                        collectionName: DB.POS_TABLE,
                        _id: t['_id'],
                        updatedAt: Date.now()
                      })
                    });

                    await this.rxdb.bulkTableInsert(tables);
                    // await Promise.all([
                    //   this.rxdb.bulkTableInsert(tables),
                    //   // this.rxdb.bulkOrderInsert(orders),
                    // ]);
                  });
                testSub.unsubscribe();
              }
            });
          }


          console.log('tablews gettableswithfloorid', areaId, status);
          o.next(res);
          sub.unsubscribe();
        });
      }

      if (this.configService.mode === MODE.ONLINE) {
        let url = `${this.configService.appConfig.appBaseUrl}maestro/tableInfo?isPopulate=true&areaId=${areaId}`;
        if (status) {
          url = `${url}&status=${status}`
        }
        this.http.get(url).subscribe((res) => o.next(res));
      }
    });
  }

  getAdditionalDineServices() {
    return new Observable(o => {
      const sub = this.rxdb.getDbData(DB.POS_OTHER).subscribe({
        next: (v) => {
          const res = v?.[0]?.['_data']?.['data'];
          const FI = 'name,assetType,alreadyPaid,isPrintable';
          const url = `${this.configService.appConfig.appBaseUrl}maestro/otherServices?fI=${FI}`;
          return this.http.get(url).subscribe({
            next: (res) => {
              o.next(res);
              sub.unsubscribe();
              this.rxdb.insertData({
                userId: localStorage.getItem('userId'),
                data: res,
                collectionName: DB.POS_OTHER
              }).then(console.log).catch(console.error);
            },
            error: err => {
              console.error(err);
              o.next(res);
            }
          });
        },
        error: (err) => {
          console.error(err);
          o.error(err);
        }
      })
    });
  }

  getReceiptTemplate(posId: string) {
    return new Observable<IReceiptTemplate>(o => {
      const sub = this.rxdb.getDbData(DB.POS_TEMPLATE).subscribe({
        next: (v) => {
          const dbData = v?.[0]?.['_data']?.['data'];

          this.http.get<IReceiptTemplate>(`${this.configService.appConfig.appBaseUrl}maestro/templates?terminalId=${posId}`)
            .subscribe({
              next: (res) => {
                sub.unsubscribe();
                this.rxdb.insertData({
                  userId: localStorage.getItem('userId'),
                  data: res,
                  collectionName: DB.POS_TEMPLATE
                }).then(console.log).catch(console.error).finally(() => {
                  o.next(res);
                });
              },
              error: (er) => {
                console.error(er);
                dbData ? o.next(dbData) : o.error(er);
              }
            });
        },
        error: (err) => {
          console.error(err);
          o.error(err);
        }
      });
    });
  }

  checkIfUserShiftInProgress() {
    return new Observable<IUserShift>((s) => {
      if (this.configService.mode === MODE.OFFLINE) {
        // const shiftId = localStorage.getItem('shiftId');
        this.rxdb.isShiftInProgress().subscribe({
          next: r => {
            const data = r?._data?.['data']
            console.log('checkIfUserShiftInProgress', data);
            s.next(data)
          },
          error: err => s.error(err)
        })
      }


      if (this.configService.mode === MODE.ONLINE) {
        const url = `${this.configService.appConfig.appBaseUrl}userShift/shiftInProgress`;
        this.http.get<IUserShift>(url).subscribe(r => s.next(r));
      }
    });
  }

  addUserShift() {
    return new Observable<IUserShift>((s) => {
      if (this.configService.mode === MODE.OFFLINE) {
        this.rxdb.createShift().then(r => {
          const data = r?._data?.['data']
          localStorage.setItem('shiftId', data['_id']);
          s.next(data)
        }).catch(s.error)
      }


      if (this.configService.mode === MODE.ONLINE) {
        const url = `${this.configService.appConfig.appBaseUrl}userShift/add`;
        this.http.post<IUserShift>(url, {}).subscribe(r => s.next(r));
      }
    });
  }

  endUserShift() {
    return new Observable<IUserShift>((s) => {
      if (this.configService.mode === MODE.OFFLINE) {
        const modify = (shift: any) => {
          shift = structuredClone(shift);
          shift['data']['endDate'] = new Date().toISOString();
          shift['data']['inProgress'] = false;
          shift['data']['status'] = false;
          shift['updatedAt'] = Date.now();

          return shift;
        }

        this.rxdb.modifyShift(modify).then(r => {
          const data = r._data?.['data']
          localStorage.removeItem('shiftId');
          s.next(data)
        }).catch(s.error)
      }


      if (this.configService.mode === MODE.ONLINE) {
        const url = `${this.configService.appConfig.appBaseUrl}userShift/end`;
        this.http.put<IUserShift>(url, {}).subscribe(r => s.next(r))
      }
    });

  }

  getKAgentMetaData(module: string, typeId: string) {
    const url = this.configService.appConfig.appBaseUrl + `settings/all/query/module:${module},typeId:${typeId}`
    return this.http.get(url)
  }

  getUserAllShifts(startDate: number, endDate: number) {
    // const url = `${this.configService.appConfig.appBaseUrl}userShift/all?startDate=${startDate}&endDate=${endDate}`;
    // return this.http.get<IUserShift[]>(url);

    return new Observable(o => {
      this.rxdb.getAllShift(startDate, endDate).subscribe(r => {
        console.log('getAllShift', startDate, endDate, r);
        if (r?.length > 0) {
          const data = r.map((i: any) => i?.['_data']?.['data']).filter(x => !!x);
          console.log('getAllShift', data);
          o.next(data);
          // sub.unsubscribe();
        }
      });
    });
  }

  getUserProfile() {
    return this.http.get<IUserProfile>(`${this.configService.appConfig.appBaseUrl}user/self/profile`)
      .pipe(tap(res => {
        Sentry.setUser({
          id: res?._id,
          email: res?.email,
          username: res?.username,
        });
      }));
  }

  getCounterData() {
    const url = `${this.configService.appConfig.appBaseUrl}maestro/counters`;
    return this.http.get(url);
  }

}


export interface IStoreProductWindowAssignmentPayload {
  assetIds: string[]
  assetType: string
  days: string[]
  timeSlots: Slot[]
}

export interface Slot {
  start: Start
  end: End
}

export interface Start {
  h: number
  m: number
}

export interface End {
  h: number
  m: number
}


// categories table
export interface IKioskCategory {
  category: Category
  metaInfo?: MetaInfo
}

export interface Category {
  _id: string
  name: string
  description?: string
}

export interface MetaInfo {
  status?: boolean
  sequence?: number
}

export interface PosData {
  posId: string,
  posData: InnerPosData
  _id: string
}

export interface InnerPosData {
  canReceivePayment: boolean,
  area: string,
  isPrintable: boolean
}

export interface Image {
  baseUrl: string;
  imageBasePath: string;
}


export interface IProductData {
  metaInfo: {
    itemName: string;
    price: number;
    outOfStockStatus: boolean;
    categoryIds: string;
    groups?: string[];
  };
  productInfo: {
    _id: string;
    image: {
      baseUrl: string;
      imageBasePath: string;
    };
    assetsLinked?: string[];
  };
  imageURL?: string;
  qty?: number;
  idx?: number;
}

export interface CategoryInfo {
  _id: string;
  name: string;
  sequence: number;
  image: {
    baseUrl: string;
    imageBasePath: string;
  };
}

export interface ICategoryData {
  _id: string;
  productsCount: number;
  products: IProductData[];
  categoryInfo: CategoryInfo;
  outOfStockStatus: boolean;
}

export interface IQuantityStatus {
  status: boolean | null;
  statusMsg?: string;
}

export interface IGroupData {
  _id: string;
  label: string;
  color: string;
  assetTypes: string[];
  sequence: number;
  accountId: string;
  updated: string;
  __v: number;
}


function dataURLtoBlob(dataURL: string): Blob {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], {type: contentType});
}
