<router-outlet>
  <p-toast [life]="2000" key="notification"></p-toast>
  <p-toast key="action-error">
    <ng-template let-message pTemplate="message">
      <div class="flex flex-column align-items-start" style="flex: 1">
        <div class="flex align-items-center gap-2">
          <span class="font-bold text-900">{{ message.summary }}</span>
        </div>
        @if (message?.data?.template) {
          <div class="my-3">Template: {{ message?.data?.template }}</div>
        }
        <div class="my-3">{{ message.detail }}</div>
        <p-button class="p-button-sm" severity="secondary" [label]="message.data.event"
        (click)="actionToast(message.data)"></p-button>
      </div>
    </ng-template>
  </p-toast>
</router-outlet>
