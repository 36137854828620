<div>
  <div>
    <div (click)="handleZoomIn($event)" class="verification-code-inputs">
      @for (i of numberOfInput; track i; let idx = $index) {
        <input
          inputmode="numeric"
          type="number"
          maxlength="1"
          [id]="'pin-input-' + (idx + 1)"
          pInputNumber
          (focus)="onInputFocus($event)"
          (click)="handleZoomIn($event)"
          />
      }
    </div>
  </div>
  <br />
  <div class="keyboard-container-popup">
    <div class="simple-keyboard popup" id="popMyKeyboard"></div>
  </div>
</div>
