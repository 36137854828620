<div class="cart-card-container">
  <div class="cart-card-amount-info-container">
    <p><i class="pi pi-shopping-cart" style="margin-right: 0.5rem; font-size: 1.5rem;" pBadge
    value="{{subOrderData?.length ? subOrderLength: numItems}}"></i>Cart</p>
    <div class="cart-card-amount-info">
      <p style="margin: 0px;">Total ₹{{subOrderData?.length ?
      subOrderPrice.toFixed(2) : getCartTotal()}}</p>
      <!-- <p style="margin: 0px; margin-left: 0.75rem">({{numItems}} Items)</p> -->
    </div>
  </div>
  <div class="cart-card-btns-container">
    <div class="cart-card-btns">
      <p-button [ngClass]="{'hide-class' : subOrderData?.length ? selectedDineType === DineTypes?.TABLE: false}"
        styleClass="reset-cart-btn" (click)="confirm('resetCart')" label="{{'app.pos.clearCart' | translate }}"
      [raised]="true" severity="danger"></p-button>
      @if (cartOrderId?.orderId && !this.subOrderData?.length) {
        <p-button styleClass="reset-cart-btn"
          (click)="confirm('discardOrder')" label="{{'app.pos.discard' | translate}}" [raised]="true"
        severity="danger"></p-button>
      }
      @if (showCheckoutButton) {
        <p-button [disabled]="!!(subOrderData?.length && tableCartItems?.length)"
          styleClass="checkout-cart-btn paymentBtn" (click)="emitCheckoutCart()">{{
          ((selectedDineType !== DineTypes?.DINE_IN && selectedDineType !== DineTypes?.DINE_OUT &&
          selectedDineType !== DineTypes?.TABLE) ? 'app.pos.save' : cartOrderId?.orderId ? 'app.pos.updateCaps' :
        'app.pos.checkout') | translate }}</p-button>
      }
    </div>
  </div>
  <p-confirmPopup [key]="popUpKey"></p-confirmPopup>
</div>