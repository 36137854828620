import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {MODE} from "src/app/constant";

@Injectable({
  providedIn: "root"
})
export class ConfigService {

  mode = MODE.OFFLINE;

  public appConfig = {
    appBaseUrl: environment.server_url,
    baseURL: environment.base_url,
  };

  setMode(mode: string) {
    this.mode = mode
  }

}
