import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import {
  IAdditionalDineType,
  IOrder,
  IParcelCharge,
  IPayByCashCartParam,
  IPayment,
  IProductPricing,
  PosData,
  QrRes,
} from '../interface/maestro-interface';
import { RxdbSyncService } from 'src/sync/rxdb.sync.service';
import { AppState } from 'src/app/state/app.state';
import { Store } from '@ngrx/store';
import {
  additionalDinetype,
  getAvailableCategories,
  getCatNameFromId,
  getMaestroInfoData,
  parcelCharge,
  getProductPricing,
} from 'src/app/state/kiosk/kiosk.selector';
import {
  CONSTANT,
  DB,
  DINETYPE,
  MODE,
  ORDER,
  PAYMENT,
  PRINT,
  T_EVENT,
  TABLE_STATUS,
} from 'src/app/constant';
import { ExecTime } from 'src/app/decorators/ExecTime';
import { LogArgs } from 'src/app/decorators/LogArgs';
import { PrintReceiptService } from './print-receipt.service';
import { MessageService } from 'primeng/api';
import { isEqual, reject } from 'lodash';
import { RxDocument } from 'rxdb';
import pk from 'package.json';
import * as Sentry from '@sentry/angular';
import { TaxCalculationService } from '@zdIOTPlatform/zen-tax';
// import {IotPrintersService} from "@zdIOTPlatform/ng-iot-printers";

@Injectable({ providedIn: 'root' })
export class KioskOrderService implements OnDestroy {
  private destroy$ = new Subject<void>();
  availableActionToPrint = false;
  isAutoRemoveReceiptPrint = false;
  isSwitchTable = false;
  additionalDineTypes: IAdditionalDineType = [];
  pickup?: PosData;
  tax = new TaxCalculationService();
  // printer = inject(IotPrintersService);
  subscriptions$: Subscription[] = [];
  maestroInfoData: PosData;
  constructor(
    private http: HttpClient,
    private conf: ConfigService,
    private rxdb: RxdbSyncService,
    private store: Store<AppState>,
    private printReceiptService: PrintReceiptService,
    private messageService: MessageService
  ) {
    this.store
      .select(additionalDinetype)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.additionalDineTypes = data;
        }
      });
    this.subscriptions$.push(
      this.store.select(getMaestroInfoData).subscribe((info) => {
        this.maestroInfoData = info;
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions$.forEach((g) => g.unsubscribe());
  }

  @ExecTime()
  @LogArgs()
  getTax() {
    this.http.get(`${this.conf.appConfig.appBaseUrl}maestro/tax`).subscribe({
      next: async (res) => {
        await this.rxdb.insertData({
          data: res,
          collectionName: DB.TAX,
        });
      },
      error: (err) => console.error(err),
    });
  }

  @ExecTime()
  @LogArgs()
  checkoutCartOrders(
    products: any[],
    storeId: string,
    obj?: {
      [x: string]: any;
      dineType: any;
      tableNo: any;
      isTableOrder?: boolean;
      tableId?: any;
      table?: any;
    }
  ) {
    const body = {
      products: products,
      storeId: storeId,
      orderSource: 'iotzen-pos',
      dineType: obj?.dineType,
    };

    let table: any, orderId: string, dborder: any;

    if (obj?.dineType === DINETYPE.TABLE) {
      body['tableNo'] = obj?.tableNo;
      table = obj?.table;
      dborder = table?.['aProperties']?.['orderData'];
      orderId = dborder?.['_id'];
    }

    return new Observable((s) => {
      this.isAutoRemoveReceiptPrint = true;
      this.prepareOrder(products, table, orderId, dborder, obj?.dineType).then(
        (order) => {
          if (this.conf.mode === MODE.OFFLINE) {
            if (!order['_id']) {
              order['_id'] = this.createNewOrderId();
            }

            this.rxdb
              .createOrder({
                data: order,
                collectionName: DB.POS_ORDER,
                _id: order['_id'],
              })
              .then((d) => {
                s.next(d._data['data']);
              })
              .catch((er) => {
                s.error(er);
                Sentry.captureException(er);
              });
          }

          if (this.conf.mode === MODE.ONLINE) {
            this.http
              .post(`${this.conf.appConfig.appBaseUrl}maestro/checkOut`, body)
              .subscribe((res) => {
                s.next(res);
              });
          }
        }
      );
    });
  }

  @ExecTime()
  @LogArgs()
  createId() {
    return Date.now().toString(16);
  }

  @ExecTime()
  @LogArgs()
  createNewOrderId() {
    return `orders_${localStorage.getItem('userId')}_${this.createId()}`;
  }

  @ExecTime()
  @LogArgs()
  prepareOrder(
    products: any,
    table?: any,
    orderId?: string,
    dbOrder?: any,
    dineType?: string,
    _action?: string,
    _subOrderId?: string
  ) {
    return new Promise((resolve, reject) => {
      this.rxdb.getDbData('tax').subscribe(async (tax) => {
        try {
          const taxData = tax?.[0]?.['_data']?.['data'];
          const productPricing: IProductPricing = await new Promise((r) =>
            this.store.select(getProductPricing).subscribe(r)
          );
          dineType = dineType ? dineType : DINETYPE.TABLE;

          // update the item in suborder
          const getSubOrder = (prods: any, len?: number) => {
            console.log('creating sub orders', prods, _action, len);
            return {
              _id: `subOrder_${this.createId()}`,
              updated: new Date().toString(),
              items: prods.map((p: any) => {
                const metaInfo = p['all']?.metaInfo;
                return {
                  price: metaInfo?.prices?.[dineType]
                    ? metaInfo.prices[dineType]
                    : metaInfo?.price,
                  name: metaInfo?.itemName,
                  status: ORDER.PLACED,
                  quantity: p?.['all']?.qty < 0 ? 0 : p?.['all']?.qty,
                  all: p['all'],
                  _id: p['_id'],
                  assetsLinked: p?.['all']?.productInfo?.assetsLinked,
                };
              }),
            };
          };

          // update the item in order and cal tax
          const getProducts = async (prods: any) => {
            return Promise.all(
              prods.map(async (p: any) => {
                const metaInfo = p?.['all']?.metaInfo;
                return {
                  price: metaInfo?.prices?.[dineType]
                    ? metaInfo.prices[dineType]
                    : metaInfo?.price,
                  name: metaInfo?.itemName,
                  _id: p?._id,
                  cName: await new Promise((r) =>
                    this.store
                      .select(getCatNameFromId(metaInfo?.categoryIds))
                      .subscribe(r)
                  ),
                  all: p?.['all'],
                  assetsLinked: p?.['all']?.productInfo?.assetsLinked,
                  prices: metaInfo?.prices ?? null,
                  quantity: p?.['quantity'] > 0 ? p?.['quantity'] : 0,
                  isPriceIncludesTax: productPricing?.taxIncluded,
                  instruction: p?.instruction ?? '',
                  tax: taxData
                    .map((m: any) => {
                      return m['assetsLinked']?.includes(p?._id)
                        ? {
                          name: `${m.name}`,
                          percentage: Number(m.percentage),
                        }
                        : undefined;
                    })
                    .filter((x: any) => !!x),
                };
              })
            );
          };

          let order: IOrder;
          if (orderId && dbOrder) {
            order = structuredClone(dbOrder);
            let pList = [...order?.['orderDetails']?.['items']];

            // handle updated item
            for (let i = 0; i < products.length; i++) {
              const p1 = products[i];

              const dup = pList.find((x) => x._id === p1._id);
              if (dup) {
                dup.instruction = p1?.instruction;
                if (_action === 'remove') {
                  if (dup?.quantity > 0) {
                    dup.quantity -= p1.quantity; //= p1?.['all']?.qty
                  }
                }

                if (_action === 'add') {
                  dup.quantity += p1.quantity;
                }

                if (_action === 'update' && !table) {
                  dup.quantity = p1?.['all']?.qty < 0 ? 0 : p1?.['all']?.qty;
                }
              } else {
                pList.push(p1);
              }
            }
            // handle removed items
            if (!table) {
              pList = pList.filter((x) =>
                products.map((p: any) => p._id).includes(x._id)
              );
            }

            order['orderDetails']['items'] = await getProducts(pList);

            //handle sub order data
            if (table) {
              if (_action === 'remove') {
                const clone = structuredClone(order['subOrderData']);
                const currentRemovedItems = [];
                let copyItem = {};
                for (let i = 0; i < products.length; i++) {
                  const p1 = products[i];
                  const idx = clone.findIndex(
                    (s: { _id: string }) => s._id === _subOrderId
                  );
                  const item = clone[idx]['items'].find(
                    (p: { _id: string }) => p1._id === p._id
                  );
                  copyItem = { ...item };
                  copyItem['quantity'] = p1?.quantity < 0 ? 0 : p1?.quantity;
                  const ri = clone[idx]['removedItems'];
                  if (ri) {
                    const i = ri.find(
                      (r: { _id: string }) => r._id === item._id
                    );
                    if (!i) {
                      clone[idx]['removedItems'] = [...ri, item];
                    }
                  } else {
                    clone[idx]['removedItems'] = [item];
                  }

                  if (item) {
                    item['quantity'] = p1?.['all']?.qty;
                  }
                  currentRemovedItems.push(copyItem);
                }
                order['subOrderData'] = clone;
                order['currentRemovedItems'] = currentRemovedItems;

                // print when sub order modified
                if (this.isAutoRemoveReceiptPrint) {
                  this.printerAndNotify(order, PRINT.T_ON_TABLE_SO_UPDATE);
                }
              } else {
                const so = getSubOrder(
                  products,
                  order?.['subOrderData']?.length
                );
                if (_action === 'add') {
                  const len = order?.['subOrderData']?.length;
                  so['kId'] = len ? len + 1 : 1;

                  order['subOrderData'] = [...order['subOrderData'], so];
                }
                // print when new sub order added
                if (this.availableActionToPrint && !this.isSwitchTable) {
                  this.printerAndNotify(order, PRINT.T_ON_TABLE_SEND_KITCHEN);
                }
                this.isSwitchTable = false;
              }
            }

            if (_action === 'update') {
              order['orderLifeCycle'] = ORDER.UPDATED;
            }
          } else {
            const uProducts = await getProducts(products);

            let so: { _id: string; updated: string; items: any }[];
            if (table) {
              const s = getSubOrder(products);
              s['kId'] = 1;
              so = [s];
            }

            this.pickup = await new Promise((r) =>
              this.store.select(getMaestroInfoData).subscribe(r)
            );
            order = {
              accountId: localStorage.getItem('accountId'),
              tableId: table?.['_id'],
              orderLifeCycle: ORDER.CREATED,
              dineType: dineType,
              userId: localStorage.getItem('userId'),
              posId: this.pickup?.['posId'],
              areaName: this.pickup?.['areaData']?.name ?? null,
              pickupLocation: {
                _id: this.pickup?.['_id'],
                address: this.pickup?.['address'],
                contactNumber: this.pickup?.['contactNumber'],
                name: this.pickup?.['name'],
                email: this.pickup?.['email'],
              },
              tableData: table
                ? {
                  _id: table['_id'],
                  tableNo: table['tableNo'],
                }
                : undefined,
              storeId: JSON.parse(
                localStorage.getItem(CONSTANT.KIOSK.STORE_lOCATION)
              )['_id'],
              orderId: `L-${this.createId()}`,
              orderStatus: ORDER.CREATED,
              orderSource: 'iotzen-pos',
              assetType: 'order',
              orderDetails: {
                orderDate: Date.now(),
                items: uProducts,
              },
              payment: tax,
              subOrderData: so,
              date: {
                status: 'created',
                created: Date.now(),
              },
              createdAt: Date.now(),
              isPrintable: this.pickup?.['posData']?.isPrintable,
            };
          }
          const pc: IParcelCharge = await new Promise((r) =>
            this.store.select(parcelCharge).subscribe(r)
          );
          const getCatInfoByProductId = await new Promise((r) =>
            this.store.select(getAvailableCategories).subscribe(r)
          );
          const TaxCalculatedPayment: Record<string, IPayment> =
            this.tax.taxCalculation(
              dineType,
              order.orderDetails,
              productPricing,
              pc,
              getCatInfoByProductId
            );
          order['payment'] = TaxCalculatedPayment?.payment;
          this.isAutoRemoveReceiptPrint = false;
          resolve(order);
        } catch (e) {
          this.isAutoRemoveReceiptPrint = false;
          reject(e);
        }
      });
    });
  }

  @ExecTime()
  @LogArgs()
  submitTableOrder(
    tableId: string,
    prodData: any,
    orderDocId: string,
    subOrderId?: string,
    action = 'add'
  ) {
    const payload = {
      products: {
        [action]: prodData,
      },
      tableId: tableId,
      orderDocId: orderDocId,
    };

    if (subOrderId) {
      payload['subOrderId'] = subOrderId;
    }

    let url = `${this.conf.appConfig.appBaseUrl}maestro/table/order/`;
    if (action === 'remove') {
      url += action;
    }

    return new Observable((s) => {
      const db = this.dbCheckout(
        prodData,
        orderDocId,
        tableId,
        action,
        subOrderId
      );
      if (this.conf.mode === MODE.OFFLINE) {
        db.then((order) => {
          s.next(order);
        }).catch((er) => {
          s.error(er);
          Sentry.captureException(er);
        });
      }
      if (this.conf.mode === MODE.ONLINE) {
        this.http.post(url, payload).subscribe((res) => {
          db.then(console.log).catch((er) => Sentry.captureException(er));
          s.next(res);
        });
      }
    });
  }

  @ExecTime()
  @LogArgs()
  setOrderAsPicked(orderIds: string[], obj?: IPayByCashCartParam) {
    setTimeout(() => {
      Sentry.metrics.increment('order_paid_click', 1, {
        timestamp: Date.now(),
        tags: { _id: orderIds[0] },
      });
      Sentry.metrics.set('user', localStorage.getItem('userId'), {
        tags: { _id: orderIds[0] },
        timestamp: Date.now(),
      });
    });

    const promise = new Promise<unknown[]>(async (resolve, reject) => {
      try {
        const oId = orderIds[0];
        // const queries = new Array<Promise<unknown>>();
        const o: RxDocument = await this.rxdb.getOrderById(oId);
        if (o) {
          const tableId = o?._data?.['data']?.['tableData']?.['_id'];
          if (tableId) {
            const modifyFunc = async (table: any) => {
              table = structuredClone(table);
              if (table?.['data']) {
                table['data'] = {
                  ...table['data'],
                  aProperties: { status: TABLE_STATUS.AVAILABLE },
                };
              }
              return this.updateTimeStamp(table);
            };
            await this.rxdb.modifyTables(modifyFunc, tableId);
          }

          const modifyOrderFunc = (doc: any) => {
            if (doc?.['data']) {
              doc['data'] = structuredClone(doc?.['data']);
              doc['data'].orderStatus = ORDER.PLACED;
              doc['data'].placedAt = Date.now();
              doc['data'].payment = {
                ...doc?.['data']?.payment,
                amount: obj.amount,
                status: PAYMENT.PAID,
                paymentMode: obj.pMode,
                userId: localStorage.getItem('userId'),
                posId: this.pickup?.['posId'],
              };

              doc['data'].date = {
                ...(doc['data'].date ?? {}),
                status: ORDER.PLACED,
                placed: doc['data'].placedAt,
                paid: doc['data'].placedAt,
              };

              delete doc['data'].payment.items;

              if (doc['data']?.['orderDetails']?.['items']?.length > 0) {
                doc['data']['orderDetails']['items'].forEach(
                  (item: any) => delete item['all']
                );
              }

              if (doc['data']?.['subOrderData']) {
                doc['data']['subOrderData']?.forEach(
                  (s: Record<string, Record<string, any>[]>) => {
                    s?.['items']?.forEach(
                      (i: Record<string, any>) => delete i['all']
                    );
                    s?.['removedItems']?.forEach(
                      (i: Record<string, any>) => delete i['all']
                    );
                  }
                );
              }
              doc['data']['lastModifiedUserId'] =
                localStorage.getItem('userId');
              doc['data']['appVersion'] = pk.version;
            } else {
              throw new Error(
                `Doc not found ${orderIds}, ${JSON.stringify(doc)}`
              );
            }
            return this.updateTimeStamp(doc);
          };
          const update = await this.rxdb.modifyOrder(modifyOrderFunc, oId);

          resolve([update]);
        } else {
          reject(new Error(`order not found ${oId}`));
        }
      } catch (er) {
        console.error(er);
        reject(er);
      }
    });

    return new Observable((s) => {
      if (this.conf.mode === MODE.OFFLINE || this.conf.mode === MODE.SHADOW) {
        promise
          .then((r) => {
            const rxDoc = r?.find(
              (x) => x?.['_data']?.['collectionName'] === DB.POS_ORDER
            );
            const order = rxDoc?.['_data']?.['data'];

            // sending receipt print
            if (order['tableData']) {
              this.printerAndNotify(order, PRINT.T_ON_TABLE_PAY);
            } else {
              if (
                order?.dineType === DINETYPE?.DINE_IN ||
                order?.dineType === DINETYPE?.DINE_OUT
              ) {
                this.printerAndNotify(order);
              } else {
                this.additionalDineTypes?.forEach((e) => {
                  if (
                    e?.assetDetails?.name === order?.dineType &&
                    e?.assetDetails?.isPrintable
                  ) {
                    this.printerAndNotify(order);
                  }
                });
              }
            }

            s.next({
              r,
              orderDocId: orderIds,
              status: 200,
              msg: 'Order payment status Updated successfully',
            });
          })
          .catch((er) => {
            s.error(er);
            Sentry.captureException(er);
          });
      }

      if (this.conf.mode === MODE.ONLINE || this.conf.mode === MODE.SHADOW) {
        const url = `${this.conf.appConfig.appBaseUrl}payments/paidByCash`;
        const payload = {
          orderIds: orderIds,
        };

        if (obj) {
          payload['amount'] = obj?.amount;
          payload['pMode'] = obj?.pMode;
        }

        this.http.put(url, payload).subscribe((res) => {
          s.next(res);
          promise.then((r) => r);
        });
      }
    });
  }

  @ExecTime()
  @LogArgs()
  postPrintOrderReceipt(
    orderId: string,
    body: { orderDocId: string },
    tName?: string
  ) {
    return new Observable((s) => {
      if (this.conf.mode === MODE.OFFLINE) {
        this.rxdb
          .getOrderById(orderId)
          .then((o: RxDocument) => {
            if (o) {
              const tableId = o?._data?.['data']?.['tableId'];
              if (tableId) {
                const modifyFunc = async (table: any) => {
                  table = structuredClone(table);
                  if (table?.['data']) {
                    const orderData = table['data']['aProperties']['orderData'];
                    await this.printReceiptService.printReceipt(
                      orderData,
                      tName
                    );
                    table['data']['aProperties']['status'] =
                      TABLE_STATUS.RECEIPT_GEN;
                  }
                  return this.updateTimeStamp(table);
                };

                this.rxdb
                  .modifyTables(modifyFunc, tableId)
                  .then((_r) => {
                    s.next({ message: 'Print Initiated Successfully' });
                  })
                  .catch((er) => {
                    s.error(er);
                    Sentry.captureException(er);
                  });
              }
            } else {
              const er = new Error(`doc not found ${orderId}`);
              s.error(er);
              Sentry.captureException(er);
            }
          })
          .catch((er) => {
            s.error(er);
            Sentry.captureException(er);
          });
      }
      if (this.conf.mode === MODE.ONLINE) {
        this.http
          .post(
            `${this.conf.appConfig.appBaseUrl}sok/orders/printReciept/orderId/${orderId}`,
            body
          )
          .subscribe((r) => s.next(r));
      }
    });
  }

  @ExecTime()
  @LogArgs()
  checkoutUpdatedCartOrders(
    orderId: string,
    products: any[],
    storeId: any,
    obj?: {
      dineType: any;
      tableNo: any;
      tableId: any;
      table?: any;
      isTableOrder?: boolean;
    },
    action?: boolean
  ) {
    this.availableActionToPrint = action;
    const body = {
      products: products,
      storeId: storeId,
      dineType: obj?.dineType,
    };
    if (obj?.dineType === DINETYPE.TABLE) {
      body['tableNo'] = obj?.tableNo;
    }
    if (this.conf.mode === MODE.ONLINE) {
      return this.http.put(
        `${this.conf.appConfig.appBaseUrl}maestro/checkOut/${orderId}`,
        body
      );
    } else {
      return new Observable((s) => {
        this.dbCheckout(
          products,
          orderId,
          obj?.tableId,
          'update',
          undefined,
          obj?.dineType
        )
          .then((_r) => {
            // s.next(_r);
            this.rxdb.getOrderById(orderId).then((o: RxDocument) => {
              const latest = o.getLatest();
              s.next(latest?._data?.['data']);
            });
          })
          .catch((er) => {
            s.error(er);
            Sentry.captureException(er);
          });
      });
    }
  }

  printerAndNotify = (od: IOrder, t?: string, reprint = false) => {
    od = {
      ...od,
      gstin: this.maestroInfoData?.gstin ?? '',
      fssai: this.maestroInfoData?.fssai ?? '',
    };
    this.printReceiptService
      .printReceipt(od, t)
      .then((_r) => {
        this.messageService.add({
          severity: 'success',
          key: 'notification',
          summary: `Printer Success (Order ID: ${od['orderId']?.slice(-5)})`,
          detail: `${t ?? ''}  ${_r[0]?.['print'] || _r[0]?.['msg']}`,
        });
      })
      .catch((er) => {
        this.messageService.add({
          severity: 'error',
          summary: `Printer Error (ORDER ID: ${od['orderId']?.slice(-5)})`,
          key: 'action-error',
          data: { order: od, template: t, event: T_EVENT.REPRINT },
          sticky: true,
          detail: `ERROR: ${(er?.error?.msg || er?.msg || er.toString()) ?? ''
            }`,
        });
      });

    if (!reprint) {
      this.availableActionToPrint = false;
    }
  };

  @ExecTime()
  @LogArgs()
  switchTableOrder(payLoad: {
    [x: string]: string;
    sourceTableId?: any;
    destinationTableId?: any;
  }) {
    this.isSwitchTable = true;
    return new Observable((s) => {
      if (this.conf.mode === MODE.OFFLINE) {
        new Promise(async (resolve, reject) => {
          const get = await Promise.all([
            this.rxdb.getTables(
              undefined,
              undefined,
              payLoad['sourceTableId'],
              true
            ),
            this.rxdb.getTables(
              undefined,
              undefined,
              payLoad['destinationTableId'],
              true
            ),
          ]);

          const sTable = structuredClone(get[0]?.[0]?.['_data']?.['data']);
          const dTable = structuredClone(get[1]?.[0]?.['_data']?.['data']);

          const discardOrder = sTable?.['aProperties']?.['orderData'];
          discardOrder['switchTableOrder'] = {
            source: {
              _id: payLoad['sourceTableId'],
              tableNo: sTable?.['tableNo'],
              syncId: sTable?.['aProperties']?.['orderDocId'],
              area: {
                _id: sTable?.['areaData']?.['_id'],
                name: sTable?.['areaData']?.['name'],
              },
            },
            destination: {
              _id: payLoad['destinationTableId'],
              tableNo: dTable?.['tableNo'],
              area: {
                _id: dTable?.['areaData']?.['_id'],
                name: dTable?.['areaData']?.['name'],
              },
            },
          };
          const _discard = new Promise((resolve, reject) =>
            this.discardOrderId(discardOrder?.['_id'], 'switchTable').subscribe(
              {
                next: (_r) => {
                  // update new order for table and create new
                  discardOrder['_id'] = this.createNewOrderId();
                  discardOrder['tableData'] = {
                    _id: dTable['_id'],
                    tableNo: dTable['tableNo'],
                  };
                  discardOrder['orderId'] = `L-${this.createId()}`;
                  discardOrder['tableId'] = dTable['_id'];
                  this.rxdb
                    .createOrder({
                      data: discardOrder,
                      collectionName: DB.POS_ORDER,
                      _id: discardOrder['_id'],
                    })
                    .then((_order) => {
                      dTable['aProperties']['orderData'] = discardOrder;
                      dTable['aProperties']['status'] = TABLE_STATUS.OCCUPIED;
                      dTable['aProperties']['orderDocId'] = discardOrder['_id'];

                      this.rxdb
                        .modifyTables(async (t) => {
                          t = structuredClone(t);
                          t['data']['aProperties'] = dTable['aProperties'];
                          t['data']['aProperties']['status'] =
                            TABLE_STATUS.OCCUPIED;
                          t['data']['aProperties']['orderDocId'] =
                            discardOrder['_id'];
                          return this.updateTimeStamp(t);
                        }, dTable['_id'])
                        .then((_m) => resolve(_r))
                        .catch((er) => reject(er));
                    })
                    .catch((er) => reject(er));
                },
                error: (er) => reject(er),
              }
            )
          );
          try {
            await _discard;
          } catch (e) {
            reject(e);
          }

          this.rxdb
            .insertData({
              _id: `${DB.POS_SWITCH_TABLE}_${this.createId()}`,
              data: {
                ...payLoad,
                reason: 'POS switch tables',
                userId: localStorage.getItem('userId'),
              },
              collectionName: DB.POS_SWITCH_TABLE,
            })
            .then(console.log)
            .catch(console.error);

          resolve({ sTable, dTable });
        })
          .then((r) => {
            s.next({
              msg: 'Tables Switched Successfully',
              sourceTableId: r['sTable'],
              destinationTableData: r['dTable'],
            });
          })
          .catch((er) => {
            s.error(er);
            Sentry.captureException(er);
          });
      }

      if (this.conf.mode === MODE.ONLINE) {
        this.http
          .post(`${this.conf.appConfig.appBaseUrl}maestro/switchTable`, payLoad)
          .subscribe((v) => s.next(v));
      }
    });
  }

  @ExecTime()
  @LogArgs()
  dbCheckout(
    prodData: any,
    orderDocId: string,
    tableId?: string,
    action = 'add',
    subOrderId?: string,
    dineType?: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let order: any;
        let isE: boolean;
        const modifyTableFunc = async (table: any) => {
          try {
            table = structuredClone(table);
            if (table?.['data']) {
              order = table['data']?.['aProperties']?.['orderData'];
              if (order) {
                orderDocId = order['_id'];
              }

              // update the order for given action
              this.isAutoRemoveReceiptPrint = true;
              order = await this.prepareOrder(
                prodData,
                table['data'],
                orderDocId,
                order,
                dineType,
                action,
                subOrderId
              );
              if (order['_id']) {
                const func = (o: any) => {
                  o['data'] = order;
                  o['data']['lastModifiedUserId'] =
                    localStorage.getItem('userId');
                  o['data']['appVersion'] = pk.version;
                  return this.updateTimeStamp(o);
                };
                await this.rxdb.modifyOrder(func, order['_id']);
              } else {
                // create new order
                order['_id'] = this.createNewOrderId();
                await this.rxdb.createOrder({
                  data: order,
                  collectionName: DB.POS_ORDER,
                  _id: order['_id'],
                });
              }

              // update order inside table
              if (action === 'add' || action === 'remove') {
                table['data']['aProperties']['orderData'] = order;
                table['data']['aProperties']['orderDocId'] = order['_id'];
                table['data']['aProperties']['status'] = TABLE_STATUS.OCCUPIED;
                order = {
                  ...order,
                  pickUpLocation: table['data']['storeData'],
                };
                // order['pickUpLocation'] = table['data']['storeData'];
              }

              // calculate the tax and update the data inside table
              isE = isEqual(
                table['data']?.['aProperties']?.['orderData'],
                order
              );
              if (action === 'update' && !isE) {
                table['data']['aProperties']['orderData'] = order;
              }
            }

            resolve(order);
            return this.updateTimeStamp(table);
          } catch (e) {
            reject(e);
          }
        };

        if (tableId) {
          // update the table data
          await this.rxdb.modifyTables(modifyTableFunc, tableId);
        } else {
          this.rxdb.getOrderById(orderDocId).then(async (old: RxDocument) => {
            let order = old?._data?.['data'];
            this.isAutoRemoveReceiptPrint = true;
            order = await this.prepareOrder(
              prodData,
              undefined,
              orderDocId,
              order,
              dineType,
              action,
              subOrderId
            );
            const func = async (o: any) => {
              o['data'] = order;
              o['data']['lastModifiedUserId'] = localStorage.getItem('userId');
              o['data']['appVersion'] = pk.version;
              return this.updateTimeStamp(o);
            };

            await this.rxdb.modifyOrder(func, orderDocId);

            resolve(order);
          });
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  @ExecTime()
  @LogArgs()
  getQrCode(orderId: string): Observable<QrRes> {
    return this.http.get<QrRes>(
      `${this.conf.appConfig.appBaseUrl}sok/orders/checkOut/qrCode/${orderId}`
    );
  }

  @ExecTime()
  @LogArgs()
  getPaymentStatus(orderId: string) {
    return this.http.get(
      `${this.conf.appConfig.appBaseUrl}sok/orders/checkOut/payment/success/${orderId}`
    );
  }

  @ExecTime()
  @LogArgs()
  initiateRefund(orderId: string, amount: number, refundReason: string) {
    return this.http.post(
      `${this.conf.appConfig.appBaseUrl}payments/refund/orderId/${orderId}`,
      {
        amount: amount,
        reason: refundReason,
      }
    );
  }

  @ExecTime()
  @LogArgs()
  discardOrderId(orderId: string, screen: string) {
    const url = `${this.conf.appConfig.appBaseUrl}sok/orders/updatePickUpStatus/discarded`;
    const cancelOrderIds = [];
    cancelOrderIds.push(orderId);
    const body = {
      orderIds: cancelOrderIds,
      screen: screen,
    };
    return new Observable((s) => {
      if (this.conf.mode === MODE.ONLINE) {
        this.http.put(url, body).subscribe((x) => s.next(x));
      }

      if (this.conf.mode === MODE.OFFLINE) {
        const dn = new Promise((resolve) => {
          const oId = orderId;
          this.rxdb
            .getOrderById(oId)
            .then(async (o: RxDocument) => {
              if (o) {
                const tableId = o?._data?.['data']?.['tableId'];
                if (tableId) {
                  const modifyFunc = async (table: any) => {
                    table = structuredClone(table);
                    if (table?.['data']) {
                      delete table['data']['aProperties']['orderData'];
                      delete table['data']['aProperties']['orderDocId'];
                      table['data']['aProperties']['status'] =
                        TABLE_STATUS.AVAILABLE;
                    }

                    return this.updateTimeStamp(table);
                  };
                  await this.rxdb.modifyTables(modifyFunc, tableId);
                }

                const func = (doc: any) => {
                  if (doc?.['data']) {
                    doc['data'] = structuredClone(doc?.['data']);
                    doc['data']['orderLifeCycle'] = ORDER.DELETED;
                    doc['data'].orderStatus = ORDER.DISCARDED;
                    doc['data'].discardedAt = Date.now();
                    doc['data'].payment = {
                      ...doc?.['data']?.payment,
                      status: PAYMENT.NOT_PAID,
                    };

                    doc['data'].date = {
                      ...(doc['data'].date ?? {}),
                      status: ORDER.DISCARDED,
                      discarded: doc['data'].discardedAt,
                    };

                    delete doc['data'].payment.items;

                    if (doc['data']?.['orderDetails']?.['items']?.length > 0) {
                      doc['data']['orderDetails']['items'].forEach(
                        (item: any) => delete item['all']
                      );
                    }

                    if (doc['data']?.['subOrderData']) {
                      doc['data']['subOrderData']?.forEach((s: any) => {
                        s?.['items']?.forEach(
                          (i: Record<string, any>) => delete i['all']
                        );
                        s?.['removedItems']?.forEach(
                          (i: Record<string, any>) => delete i['all']
                        );
                      });
                    }

                    o['data']['lastModifiedUserId'] =
                      localStorage.getItem('userId');
                    o['data']['appVersion'] = pk.version;
                  } else {
                    throw new Error(
                      `Doc not found ${orderId}, ${JSON.stringify(doc)}`
                    );
                  }
                  return this.updateTimeStamp(doc);
                };

                const update = await this.rxdb.modifyOrder(func, orderId);

                resolve(update);
              } else {
                throw new Error('order not found ' + oId);
              }
            })
            .catch((er) => reject(er));
        });

        dn.then((x) => s.next(x)).catch((er) => {
          s.error(er);
          Sentry.captureException(er);
        });
      }
    });
  }

  @ExecTime()
  @LogArgs()
  getReceiptContent(orderId: string) {
    if (orderId) {
      return this.http.get(
        `${this.conf.appConfig.appBaseUrl}payments/getRecieptContent/orderId/${orderId}`
      );
    }
  }

  @ExecTime()
  @LogArgs()
  updateTimeStamp(data: any) {
    data['updatedAt'] = Date.now();
    return data;
  }

  @ExecTime()
  @LogArgs()
  sendPaymentLink(orderId: string, address: string) {
    if (orderId && address) {
      return this.http.post(
        `${this.conf.appConfig.appBaseUrl}payments/sendPaymentLink/orderId/${orderId}`,
        {
          sendAddress: address,
        }
      );
    }
  }

  // sendReceiptOnline(orderId: string, email: string) {
  //   if (orderId && email) {
  //     return this.http.put(`${this.conf.appConfig.appBaseUrl}payments/sendReceipt/?orderId=${orderId}&sendAddress=${email}`, {
  //       orderId: orderId,
  //       email: email,
  //     });
  //   }
  // }
  //
  // cancelOrder(orderId: string, screen: string) {
  //   let url = `${this.conf.appConfig.appBaseUrl}sok/orders/updatePickUpStatus/cancelled`;
  //   let cancelOrderIds = [];
  //   cancelOrderIds.push(orderId);
  //   const body = {
  //     orderIds: cancelOrderIds,
  //     screen: screen,
  //   };
  //   return this.http.put(url, body);
  // }
  //
  // checkOccupiedTable(id: string) {
  //   return this.http.get(`${this.conf.appConfig.appBaseUrl}sok/stores/tableInfo/locationId/${id}`);
  // }
}
