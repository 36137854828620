import {inject, Injectable} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {CanActivateFn, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {
  }

  async canActivate() {
    if (await this.authService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['']).then(console.log).catch(console.error);
      return false;
    }
  }
}


export const authGuard: CanActivateFn = (_route, _state) => {
  return inject(AuthGuard).canActivate();
};
