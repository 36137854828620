import { ButtonModule } from 'primeng/button';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, OnChanges, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DINETYPE } from 'src/app/constant';
import { AppState } from 'src/app/state/app.state';
import { Store } from '@ngrx/store';
import { additionalDinetype } from 'src/app/state/kiosk/kiosk.selector';
import { IAdditionalDineType } from 'src/app/interface/maestro-interface';
import { Subscription } from 'rxjs';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-cart-card',
  standalone: true,
  imports: [
    ButtonModule,
    FormsModule,
    BadgeModule,
    CommonModule,
    ConfirmPopupModule,
    TranslateModule
  ],
  templateUrl: './cart-card.component.html',
  styleUrl: './cart-card.component.scss',
})
export class CartCardComponent implements OnDestroy, OnChanges, OnInit {
  @Input() numItems: number;
  @Input() cartItems: any;
  @Input() showCheckoutButton: boolean;
  @Input() cartOrderId;
  @Input() selectedDineType: string;
  @Input() subOrderData;
  @Input() tableCartItems;

  @Output() resetCartEmitter = new EventEmitter<void>();
  @Output() removeItemFromCart = new EventEmitter<any>();
  @Output() overlayPanelEmitter = new EventEmitter<any>();
  @Output() setOverlayPanelItemEmitter = new EventEmitter<any>();
  @Output() checkoutCartEmitter = new EventEmitter<void>();
  @Output() bypassOtherServiceEmitter = new EventEmitter<void>();
  @Output() emitDiscardOrder = new EventEmitter<void>();

  cartTotal = 0;
  subOrderLength = 0;
  subOrderPrice = 0;
  DineTypes = DINETYPE;
  popUpKey = '';
  additionalDineTypes: IAdditionalDineType = [];
  additionalDineType$: Subscription;
  constructor(
    private confirmationService: ConfirmationService,
    private store: Store<AppState>,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(): void {
    this.getCartTotal();
    this.subOrderLength = 0;
    this.subOrderPrice = 0;
    for (let i = 0; i < this.subOrderData?.length; i++) {
      for (let j = 0; j < this.subOrderData?.[i]?.items?.length; j++) {
        this.subOrderLength++;
        this.subOrderPrice +=
          this.subOrderData?.[i]?.items?.[j]?.price *
          this.subOrderData?.[i]?.items?.[j]?.quantity;
      }
    }
  }
  ngOnInit() {
    this.additionalDineType$ = this.store
      .select(additionalDinetype)
      .subscribe((data) => {
        if (data) {
          this.additionalDineTypes = data;
        }
      });
  }

  ngOnDestroy() {
    this.additionalDineType$.unsubscribe();
  }

  getCartTotal() {
    let temp = 0;
    if (this.cartItems) {
      this.cartItems.forEach((item) => {
        const price = this.selectedDineType
          ? item?.['metaInfo']?.['prices']?.[this.selectedDineType] ||
            item?.['metaInfo']?.['price']
          : item?.['metaInfo']?.['price'];
        temp += item.qty * price;
      });
    }
    return temp.toFixed(2);
  }

  emitResetCart() {
    this.resetCartEmitter.emit();
  }

  emitRemoveElem(item: any) {
    this.removeItemFromCart.emit(item['productInfo']['_id']);
  }

  emitCartItemClick(data, item) {
    this.overlayPanelEmitter.emit(data);
    this.setOverlayPanelItemEmitter.emit(item);
  }

  emitCheckoutCart() {
    if (
      this.selectedDineType !== this.DineTypes?.DINE_IN &&
      this.selectedDineType !== this.DineTypes?.DINE_OUT &&
      this.selectedDineType !== this.DineTypes?.TABLE
    ) {
      this.additionalDineTypes.forEach((e) => {
        if (
          e?.assetDetails?.name === this.selectedDineType &&
          e?.assetDetails?.alreadyPaid
        ) {
          this.bypassOtherServiceEmitter.emit();
        } else if (
          e?.assetDetails?.name === this.selectedDineType &&
          !e?.assetDetails?.alreadyPaid
        ) {
          this.checkoutCartEmitter.emit();
        }
      });
    } else {
      this.checkoutCartEmitter.emit();
    }
  }
  discardOrder() {
    this.emitDiscardOrder.emit();
  }
  confirm(key) {
    this.confirmationService?.close();
    this.popUpKey = key;
    // setTimeout(() => {
    const translate = new TranslatePipe(this.translate, this.cd);
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        key === 'resetCart'
          ? translate.transform('app.pos.clearTheCartConfirm')
          : key === 'discardOrder'
          ? translate.transform('app.pos.discardOrderConfirm')
          : '',
      icon: 'pi pi-exclamation-triangle',
      key: key,
      accept: () => {
        if (key === 'resetCart') {
          this.emitResetCart();
        } else if (key === 'discardOrder') {
          this.discardOrder();
        }
      },
      reject: () => {},
    });
    // },200)
  }
}
