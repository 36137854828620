import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {CommonService, LoginData} from '../../services/common.service';
import {Router} from '@angular/router';
import {} from '@angular/common/http';
import {Ability} from '@casl/ability';
import {unpackRules} from '@casl/ability/extra';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {accountActions, accountSelectors} from '../../../app/state/account';
import pk from 'package.json';
import {LoaderComponent} from '../loader/loader.component';
import * as bcrypt from 'bcryptjs';
import {DB} from 'src/app/constant';
import {CardModule} from 'primeng/card';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ButtonModule,
    CommonModule,
    LoaderComponent,
    CardModule,
  ],
  // providers: [ClienteService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  loginStatus: string;
  email: string;
  password: string;
  versionNumber = pk.version;
  getIsInternetAvailable$ = this.store.select(
    accountSelectors.getIsInternetAvailable
  );
  showLoader = false;
  showPassword = false;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private ability: Ability,
    private store: Store<AppState>
  ) {
  }

  login() {
    this.showLoader = true;
    const obj = {
      username: this.email,
      password: this.password,
      language: 'en',
    };
    this.commonService.verifyUserDetails(obj).subscribe({
      next: async (res) => {
        if (res?.['token']) {
          // emptying roles on new login
          localStorage.setItem('storeId', res['storeIds']?.[0]);

          try {
            await this.setLoginData(res);
          } catch (e) {
            this.loginStatus = e?.error?.msg ?? e.message;
            this.showLoader = false
          }
        }
      },
      error: async (_err) => {
        try {
          this.loginStatus = _err?.error?.msg || 'Offline Login Init';
          //handle login with operator data

          const op = JSON.parse(localStorage.getItem(DB.POS_OPERATORS));

          if(!op?.data?.length){
            this.loginStatus = "No offline user found.";
          }

          for (let i = 0; i < op?.data?.length; i++) {
            const user = op?.data[i];
            const profile = user?.['profile'];
            if (profile) {
              if (profile?.['username'] === this.email) {
                const isValid = bcrypt.compareSync(this.password, profile?.password);
                if (isValid) {
                  if (!user?.storeIds) {
                    const storeIds = localStorage.getItem('storeId');
                    user['storeIds'] = [...storeIds];
                  }
                  await this.setLoginData(user);
                  localStorage.setItem('userId', profile['_id']);
                  this.showLoader = false;
                }
              }
            }
          }
        } catch (e) {
          this.loginStatus = `Offline Login Init: FAILED ${e.message}`;
          console.error(e);
        } finally {
          this.showLoader = false;
        }
      },
    });
  }

  async setLoginData(res: LoginData) {
    this.ability.update([]);
    localStorage.setItem('token', res?.['token']);
    localStorage.setItem('expiry', String(res?.['expiry'] ?? ''));

    const storeId = Array.isArray(res['storeIds']) ? res['storeIds']?.[0] : res['storeId'];
     if(storeId) {
       localStorage.setItem('storeId', storeId);
     }

    this.store.dispatch(accountActions.getUserProfile());

    localStorage.setItem('userRoles', JSON.stringify(res?.roles));
    localStorage.setItem('account', JSON.stringify(res?.['account']));

    const name = res?.['account']?.['name'];
    const accountId = res?.['account']?.['_id'];
    this.setRules(unpackRules(res.roleInfo.ability), res.roleInfo.landingPage);
    localStorage.setItem('rules', JSON.stringify(this.ability.rules));

    if (name && accountId) {
      localStorage.setItem('accountId', accountId);
      localStorage.setItem('name', name);
    }
    this.showLoader = false;
    await this.router.navigate(['zenpos']);
  }

  setRules(abilityFound, _landingPage) {
    this.ability.update(abilityFound);
    localStorage.setItem('rules', JSON.stringify(this.ability.rules));
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
