<div class="login-body">
  <span class="version-number">v-{{ versionNumber }}</span>
  <div class="login-topbar">
    <a href="#" class="logo" style="display: flex;">
      <img src="assets/icons/android/android-launchericon-512-512.png" alt="california-layout"/>
      <span class="logo-label">ZenPOS</span>
    </a>
  </div>

  <img class="login-image" src="assets/layout/images/image-access.png" alt="california-layout"/>

  <div class="login-panel p-fluid">
    <div class="grid">
      <div>
        <p-button [label]="(getIsInternetAvailable$ | async) ? 'Online' : 'Offline'"
        [ngClass]="{ 'bg-success' : (getIsInternetAvailable$ | async), 'bg-danger' : !(getIsInternetAvailable$ | async)}"></p-button>
      </div>
      <!-- <div class="col-12 login-icon-container">
      <img class="login-icon" src="assets/layout/images/icon-log  in.png" alt="california-layout"/>
    </div> -->
    <div class="col-12 lg:col-12 md:col-12 logo-container">
      <span class="login-summary">Sign in to ZenPOS</span>
    </div>
    <div class="col-12 lg:col-12 md:col-12">
      <div class="input-wrapper">
        <label>E-mail address</label>
        <input type="text" [(ngModel)]="email" autocomplete="off" placeholder="E-mail" pInputText>
      </div>
    </div>
    <div class="col-12 lg:col-12 md:col-12">
      <div class="input-wrapper">
        <label>Password</label>
        <i class="pi" [ngClass]="{'pi-eye': !showPassword, 'pi-eye-slash': showPassword}" (click)="togglePasswordVisibility()"></i>
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          [(ngModel)]="password"
          autocomplete="off"
          placeholder="Password"
          pInputText
          />
      </div>
    </div>
    <div class="col-12 lg:col-12 md:col-12 chkbox-container">
      <div class="remember-me">
        {{ loginStatus }}
      </div>
    </div>
    <div class="col-12 lg:col-12 md:col-12 button-container">
      <button type="button" pButton label="Sign In" (click)="login()"></button>
    </div>
  </div>
</div>
</div>

@if (showLoader) {
  <app-loader></app-loader>
}
