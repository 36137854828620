@if ((getIsVersionUpdated$ | async) && printerStatus?.allConnected) {
  <div>
    <p-messages severity="info">
      <ng-template pTemplate>
        <div class="ml-2 font-bold vUpdate">We've enhanced ZenPOS with some under-the-hood improvements. <span
        class="update-btn mr-2" (click)="window.location.reload()">Update </span> at your convenience to enjoy the
      latest version.</div>
    </ng-template>
  </p-messages>
</div>
}
@if (!printerStatus?.allConnected) {
  <div>
    <p-messages severity="error">
      <ng-template pTemplate>
        <div class="ml-2 font-bold vUpdate">Printer is Offline @if (offlinePrinters) {
          <span>&nbsp;- ( {{offlinePrinters}}
          )</span>
        }</div>
      </ng-template>
    </p-messages>
  </div>
}
<div class="add-orders-header">
  <div class="top-bar-left">
    <ng-container *ngTemplateOutlet="posMenu"></ng-container>
    <div class="top-bar-left__title">ZenPOS</div>
  </div>
  <div class="storeName-container">
    <span class="store-name mt-2">{{ current_branch?.name }}</span>
    @if ((userDetails$ | async); as userDetails) {
      <span class="username">{{ userDetails?.firstName }} {{
        userDetails?.lastName
      }}</span>
    }
  </div>
  <div class="left-header-actions">
    <ng-container *ngTemplateOutlet="posSearchBar"></ng-container>
    <div [ngClass]="{ 'bg-success' : isInternetAvailable, 'bg-danger' : !isInternetAvailable}">
      <i (click)="confirmPrinterAndInternetService($event,'internetStatus')" class="pi pi-wifi cursor-pointer"
      style="font-weight:600"></i>
    </div>
    <div [ngClass]="{ 'bg-success' : printerStatus?.allConnected, 'bg-danger' : !printerStatus?.allConnected}">
      <i (click)="confirmPrinterAndInternetService($event,'printerStatus')" class="pi pi-print"></i>
    </div>
    <div [ngClass]="{ 'bg-success' : !(syncing$ | async)?.error, 'bg-danger' : !!(syncing$ | async)?.error}">
      <i (click)="confirmPrinterAndInternetService($event,'syncStatus')" class="pi pi-sync"></i>

    </div>
    @if ((ACTIONS.SHIFT | able: SUBJECT.POS_MANAGEMENT)) {
      <div
        [ngClass]="{ 'bg-success' : shift.isStarted, 'bg-danger' : !shift.isStarted}">
        <i (click)="confirmShiftStart('confirmShiftStart')" class="pi pi-clock"></i>
      </div>
    }
    <span class="logoutIcon"><button (click)="confirmLogOut($event,'logoutKey')" [pTooltip]="'Logout'"
    icon="pi pi-sign-out" pButton tooltipPosition="top"></button></span>
  </div>
</div>
<div class="add-order">
  <div [ngClass]="selectedUiConfig.cartPosition === 'cartLeft' ? 'flex-row' : 'flex-row-reverse'"
    class="add-order__products">
    <div [ngClass]="selectedUiConfig.cartPosition === 'cartLeft' ? 'cartBorderRight' : 'cartBorderLeft'"
      class="add-order__products__cartDetails">
      <div class="add-order__products__cartDetails__billDetails"
        [ngClass]="{'showUpdateBanner' : (getIsVersionUpdated$ | async) || !printerStatus?.allConnected}">
        <div [ngClass]="selectedUiConfig.cartPosition === 'cartLeft' ? 'flex-row' : 'flex-row-reverse'"
          class="float-md-right mr-0 d-flex justify-content-between header-container ">
          <div class="add-order__products__cartDetails__selectBtn">
            <p-selectButton (onOptionClick)=" checkSelectedButton($event)" [options]="dineTypeSelectBtn"
              optionDisabled="disabled" optionLabel="label" optionValue="value" styleClass="dine-p-select-button">
              <ng-template let-item pTemplate>
                <span (click)="item?.key === 'otherServices' ? op.toggle($event) : ''"
                  [ngClass]="{'dine-active-p-select-button': SelectBtnValue===item.value}">{{
                  item?.label |
                  translate
                }}</span>
              </ng-template>
            </p-selectButton>
            <p-overlayPanel #op [style]="{ width: '330px' }" styleClass="dine-type-panel">
              <ng-template pTemplate="content">
                @for (dineType of additionalDineTypes; track dineType) {
                  <button (click)="checkSelectedButton(dineType.value,dineType);op.hide()"
                  [label]="dineType?.label | titlecase" [ngClass]="{
                 'services-button-active': SelectBtnValue===dineType?.label,
                 'services-button-inactive': SelectBtnValue!==dineType?.label
                 }" class="font-bold" pButton></button>
                }
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
        @if (showtableData) {
          <div class="p-2">
            <div class="add-order__products__cartDetails__billDetails__orderheader">
              <span class="add-order__products__cartDetails__billDetails__orderheader__container">
                <span class="add-order__products__cartDetails__billDetails__orderheader__container__tableDetails">
                  <span class="non-heightlited-font">Table no: </span>
                  <span style="font-size:1rem">({{ showSelectedTableData?.areaData?.name }})</span>
                </span>
                <span class="highlighted-font">&nbsp;{{ showSelectedTableData?.tableNo }}</span>
                <span>
                  @if ((ACTIONS.SWITCH_TABLE | able:SUBJECT.POS_MANAGEMENT)) {
                    <p-button (click)="switchTable()"
                      [disabled]="showLoader" class="p-button-text font-bold ml-2"
                    label="{{'app.pos.switchTable' | translate}}"></p-button>
                  }
                </span>
              </span>
              @if (cartOrderId?.orderId || showSelectedTableData?.aProperties?.orderData?.orderId) {
                <span>
                  <span class="non-heightlited-font">Order Id:</span>
                  <span class="highlighted-font"> {{
                    (showSelectedTableData?.aProperties?.orderData?.orderId ||
                    cartOrderId?.orderId) | slice:-5
                  }}</span>
                </span>
              }
            </div>
          </div>
        }
        <div class="table-container">
          @if (cartOrderId?.orderId && !showtableData) {
            <span class="orderIdData">
              <span class="non-heightlited-font">Order Id : </span>
              <h4>&nbsp;{{ cartOrderId?.orderId | slice:-5 }}</h4>
            </span>
          }
          @if (!showtableData) {
            <div>
              @if ((cartLength$ |async ) > 0) {
                <div class="card add-order__products__cartDetails__billDetails__table">
                  @for (item of (cartItems$ | async); track item) {
                    <div class="productItem">
                      @if (selectedUiConfig.view === 'rich') {
                        <div class="productItem__imgContainer">
                          <img [ngClass]="{'gray-scale': item?.metaInfo?.outOfStockStatus}" [src]="item['imageURL']"
                            alt="product image" class="productItem__img" height="168px"
                            onerror="src='https://livingstonbagel.com/wp-content/uploads/2016/11/food-placeholder.jpg'"
                            width="228px" />
                        </div>
                      }
                      <div class="productItem__info">
                        <div class="productItem__info__name">{{ item['metaInfo']['itemName'] }}</div>
                        <div class="productItem__info__price mt-1">₹
                          {{
                          ((item['qty']) * (SelectBtnValue ?
                          (item?.['metaInfo']?.['prices']?.[SelectBtnValue] ||
                          item?.['metaInfo']?.['price']) : item?.['metaInfo']?.['price'])).toFixed(2)
                          }}
                        </div>
                      </div>
                      <div class="productItem__actionBtns">
                        @if (!item?.metaInfo?.outOfStockStatus) {
                          <div class="btn-container">
                            <i (click)="decrementCartQty(item)" class="pi pi-minus dec-btn"></i>
                            <span>{{ item['qty'] }}</span>
                            <i (click)="incrementCartQty(item)" class="pi pi-plus inc-btn"></i>
                          </div>
                        }
                        <div (click)="removeCartItem(item['productInfo']['_id'])" class="productItem__info__remove"><i
                        class="pi pi-trash"></i></div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }
          @if (!isInKitchenItems) {
            <div>
              <ng-container *ngTemplateOutlet="manageTableItems"></ng-container>
            </div>
          }
          @if ((subOrderData?.length)) {
            <p-accordion [activeIndex]="activeIndex" [multiple]="true">
              @for (i of (sentKitchendData$|async); track i) {
                <p-accordionTab>
                  <ng-template pTemplate="header">
                    <div class="d-flex-horizontal table-items-header">
                      <div>
                        <span class="font-bold ml-1">{{ i?.kId }}.</span>
                        <span class="font-bold ml-2">{{ 'app.pos.inKitchen' | translate }}</span>
                      </div>
                      <span class="font-bold">{{ i.items.length }} Items</span>
                      <span class="font-bold">{{ dateFormatter.getFormattedTimeSlotForSingleTime(i.updated) }}</span>
                    </div>
                  </ng-template>
                  @if (isInKitchenItems && (selectedInKitchenProdcut?.kId===i?.kId)) {
                    <div>
                      <ng-container *ngTemplateOutlet="manageTableItems"></ng-container>
                    </div>
                  }
                  @if ((selectedInKitchenProdcut?.kId!==i?.kId) &&  (selectedUiConfig.view === 'rich')) {
                    <div
                      class="card add-order__products__cartDetails__billDetails__table">
                      @for (item of i.items; track item) {
                        <div class="productItem">
                          <div class="productItem__imgContainer">
                            <img [ngClass]="{'gray-scale': item?.metaInfo?.outOfStockStatus}" [src]="item?.['imageUrl']"
                              alt="product image" class="productItem__img" height="168px"
                              onerror="src='https://livingstonbagel.com/wp-content/uploads/2016/11/food-placeholder.jpg'"
                              width="228px" />
                          </div>
                          <div class="productItem__info">
                            <div class="productItem__info__name">{{
                              item?.name
                              }}&nbsp;x&nbsp;{{ item?.quantity }}
                            </div>
                            <div class="productItem__info__price mt-1">₹{{
                              (item['quantity'] *
                              item?.price).toFixed(2)
                              }}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  @if ((selectedInKitchenProdcut?.kId!==i?.kId) && (selectedUiConfig.view === 'simple')) {
                    <div>
                      @for (item of i.items; track item) {
                        <ng-container
                          class="card add-order__products__cartDetails__billDetails__table">
                          <div class="add-order__products__cartDetails__billDetails__table__items">
                            <div class="add-order__products__cartDetails__billDetails__table__items__head">
                              <span class="font-bold item-name">{{ item?.name }}&nbsp;x&nbsp;{{ item.quantity }}</span>
                              <span class="font-bold ml-2">₹{{
                                (item['quantity'] *
                                item?.price).toFixed(2)
                              }}</span>
                            </div>
                            @if (kioskSettings?.posUISettings?.showInstructions) {
                              <div
                                class="add-order__products__cartDetails__billDetails__table__items__instruction">{{
                                item?.all?.defaultInstruction }}
                                @if (item?.all?.defaultInstruction?.length && item?.all?.customInstruction) {
                                  <span>,</span>
                                }
                                @if (item?.all?.customInstruction) {
                                  <span>{{ item?.all?.customInstruction }}</span>
                                }
                              </div>
                            }
                          </div>
                        </ng-container>
                      }
                    </div>
                  }
                  @if ((selectedInKitchenProdcut?.kId!==i?.kId) && (ACTIONS.EDIT_KITCHEN_ORDER | able: SUBJECT.POS_MANAGEMENT)) {
                    <div
                      class="kitchen-edit-btn">
                      @if (i?.items?.length) {
                        <p-button (click)="(!hasAddAccessAfterReceiptGeneration() && (selectedTableData?.aProperties?.status === TABLE_STATUS.RECEIPT_GEN)) ? addItemBtnDisableCheck('noAccessToAdd') : editInTableItems(i)"
                          [disabled]="(tableCartItems?.length || i?.isSuborderQuantity)"
                        label="{{'app.pos.edit' | translate}}"></p-button>
                      }
                    </div>
                  }
                </p-accordionTab>
              }
            </p-accordion>
          }
        </div>
        <div class="cartContainer">
          @if (((cartLength$ |async ) > 0 || subOrderData?.length>0) && !showTaxBreakup) {
            <div>
              @if ((cartLength$ |async ) > 0 || subOrderData?.length>0) {
                <app-cart-card (checkoutCartEmitter)="checkoutCart()"
                  (bypassOtherServiceEmitter)="bypassThirdPartyServiceFlow()" (emitDiscardOrder)="discardCartOrder()"
                  (removeItemFromCart)="removeCartItem($event)"
                  (resetCartEmitter)="resetCartValues(); !cartOrderId?._id && handleAvailableDineTypes()"
                  (setOverlayPanelItemEmitter)="setSelectedCartItem($event)"
                  [cartItems]="(cartItems$|async)"
                  [cartOrderId]="cartOrderId" [numItems]="(cartLength$|async)" [selectedDineType]="SelectBtnValue"
                  [showCheckoutButton]="true" [subOrderData]="subOrderData" [tableCartItems]="tableCartItems"
                style="width: 100%;"></app-cart-card>
              }
            </div>
          }
          @if (((cartLength$ |async ) > 0|| subOrderData?.length>0) && showTaxBreakup) {
            <div class="bill-div">
              <div class="bill-val">
                <p style="margin: 0px;">{{ 'app.pos.subtotal' | translate }}</p>
                <p style="margin: 0px;">{{ cartOrderId?.payment?.price?.items?.length }} {{ 'app.pos.items' | translate }}
                </p>
                <p style="margin: 0px;">{{ cartOrderId?.payment?.price?.subTotal }}</p>
              </div>
              <div class="bill-val">
                <p style="margin: 0px;">{{ 'app.pos.tax' | translate }}</p>
                @if (cartOrderIdTotalTax !== '0.00') {
                  <p (click)="toggleTaxDetails()"
                    style="cursor: pointer; margin: 0px; color: #27c0b3;">
                  {{ (showTaxDetails ? 'app.pos.hideDetails' : 'app.pos.viewDetails') | translate }}</p>
                }
                <p style="margin: 0px;">₹{{
                  cartOrderId?.payment?.price?.items?.length > 0 ?
                  cartOrderIdTotalTax : '0.00'
                }}</p>
              </div>
              @if (showTaxDetails && cartOrderId?.payment?.price?.items?.length > 0) {
                <div class="tax-info">
                  @for (item of cartTax; track item) {
                    <p style="margin: 0px;">{{ item?.name }}
                    ₹{{ item?.amt?.toFixed(2) }}</p>
                  }
                </div>
              }
              @if (SelectBtnValue === 'dineOut') {
                <div class="bill-val">
                  <p style="margin: 0px;">{{ 'app.pos.parcelCharges' | translate }}</p>
                  <p style="margin: 0px;">{{ cartOrderId?.payment?.price?.parcelCharge?.total }}</p>
                </div>
              }
              @if ((productPricing$ | async)?.roundingEnabled && cartOrderId?.payment?.price?.roundedAmount) {
                <div class="bill-val"
                  >
                  <p style="margin: 0px;">{{'app.pos.roundedAmount' | translate}}</p>
                  <p style="margin: 0px;">{{ cartOrderId?.payment?.price?.roundedAmount }}
                  </p>
                </div>
              }
              <div class="bill-val font-bold" style="font-size: 2rem">
                <p style="margin: 0px;">{{ 'app.pos.total' | translate }}</p>
                <p style="margin: 0px;">₹{{
                  cartOrderId?.payment?.price?.items?.length > 0 ?
                  cartOrderId?.payment?.price?.total.toFixed(2) : '0.00'
                }}</p>
              </div>
              @if (shift.isStarted) {
                <div class="d-flex justify-content-between mt-2 paymentBtn">
                  <div>
                    @if (SelectBtnValue==='table' ? isKitchenEmpty : (cartOrderId?.orderId && !this.subOrderData?.length)) {
                      <p-button (click)="confirm('discardBtn')"
                        [disabled]="showLoader" [raised]="true" label="{{'app.pos.discard' | translate}}" severity="danger"
                      styleClass="discard-btn"></p-button>
                    }
                    @if ((ability.can(ACTIONS.SHIFT, SUBJECT.POS_MANAGEMENT) ? !!shift.isStarted : true)
                      && cartOrderId?.orderId && this.subOrderData?.length && this.SelectBtnValue === 'table'
                      && (ACTIONS.PRINT | able : SUBJECT.POS_MANAGEMENT)
                      && !isKitchenEmpty) {
                      <p-button (click)="checkPrinterBeforePrintOrderReceipt($event)" [disabled]="showLoader || isInKitchenItems" [ngStyle]="{'border':'none'}"
                        class="hidePrintLabel discard-btn border-0" icon="pi pi-print"
                      label="{{'app.pos.print' | translate}}"></p-button>
                    }
                  </div>
                  @if ((ability.can(ACTIONS.SHIFT, SUBJECT.POS_MANAGEMENT) ? !!shift.isStarted : true)) {
                    <div
                      class="payBtn">
                      @if ((ACTIONS.CASH | able: SUBJECT.POS_MANAGEMENT)) {
                        <p-button (click)="paidByCash()"
                          [disabled]="showLoader || (SelectBtnValue==='table' && isKitchenEmpty)"
                          class="p-button-text font-bold" icon="pi pi-money-bill"
                        label="{{'app.pos.cash' | translate}}"></p-button>
                      }
                      @if ((ACTIONS.ONLINE | able: SUBJECT.POS_MANAGEMENT)) {
                        <p-button
                          (click)="paidByCash();showCardModal = true"
                          [disabled]="showLoader || (SelectBtnValue==='table' && isKitchenEmpty)"
                          class="p-button-text font-bold" icon="pi pi-credit-card"
                        label="{{'app.pos.card' | translate }}"></p-button>
                      }
                      @if ((ACTIONS.QR | able: SUBJECT.POS_MANAGEMENT)) {
                        <p-button (click)="payOnline()"
                          [disabled]="showLoader || (SelectBtnValue==='table' && isKitchenEmpty)"
                        class="p-button-text font-bold" icon="pi pi-qrcode" label="{{'app.pos.qr' | translate}}"></p-button>
                      }
                    </div>
                  }
                </div>
              }
              @if ((ACTIONS.SHIFT | able: SUBJECT.POS_MANAGEMENT) && !shift.isStarted) {
                <div class="paymentBtn mt-2">
                  @if (!shift.isStarted) {
                    <span class="medium-font mt-2">{{ 'app.pos.pleaseStartShiftToPayment' | translate
                    }}</span>
                  }
                  @if ((ACTIONS.SHIFT | able: SUBJECT.POS_MANAGEMENT) && !shift.isStarted) {
                    <p-button (onClick)="confirmShiftStart('confirmShiftStart')"
                      [label]="shift.isStarted ? 'End Shift' : 'Start Shift'"
                      [pTooltip]="shift.isStarted ? 'End Shift' :'Start shift'" class="FR p-button-text font-bold"
                      icon="pi pi-clock" tooltipPosition="bottom">
                    </p-button>
                  }
                </div>
              }
            </div>
          }
        </div>
        @if ((cartLength$ |async ) === 0 && !subOrderData?.length && !tableCartItems?.length) {
          <div
            [ngClass]="{'emptyCart': (cartLength$ |async ) === 0}">
            <div>
              <p style="margin: 0px;font-size: 2rem"><i class="pi pi-shopping-cart mr-2" style="font-size: 2rem"></i>Empty
            </p>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="add-order__products__productDetails">
    @if (layoutt) {
      <div>
        <p-splitter
          [ngClass]="{'pSplitterGutter': selectedUiConfig.catPosition === 'catBottom','showUpdateBanner' : (getIsVersionUpdated$ | async) || !printerStatus?.allConnected}"
          [panelSizes]=panelSize [style]="{display:'flex',border:'none' }" layout={{layoutt}}>
          <ng-template pTemplate>
            @if (selectedUiConfig.catPosition === 'catLeft') {
              <div>
                <div class="add-order__products__productDetails__categories">
                  <div class="add-order__products__productDetails__categories__category">
                    @for (category of (allAvailableCategories$ | async); track category; let idx = $index) {
                      <div (click)="getSelectedCategory(category['_id'], idx,category)"
                        pTooltip="{{category?.['categoryInfo']?.['name']}}" tooltipPosition="bottom">
                        <div
                          [ngClass]="{'activeCat' :selectedCategoryData?._id && (category?._id !== selectedCategoryData?._id) , 'deActiveCat' : selectedCategoryData?._id && (category?._id === selectedCategoryData?._id) }"
                          class="cursor-pointer">
                      <div [ngStyle]="{
                        'color': 'white',
                        'background': (selectedUiConfig.view === 'rich' && category?.['imgURL']) ? 'url(' + category?.['imgURL'] + ') center/cover no-repeat' : category?.categoryInfo?.color,
                      }" class="category-image category-simple-mode">
                          </div>
                        </div>
                    <p class="category-name cursor-pointer" [ngStyle]="{
                      'text-shadow':(selectedUiConfig.view === 'rich' && category?.['imgURL']) ? '2px 2px 2px rgba(0, 0, 0, 1.5)' : ''
                    }">
                        {{ category['categoryInfo']['name'] }}</p>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
            @if (selectedUiConfig.catPosition !== 'catLeft') {
              <div>
                @if (selectedCategoryIdx >= 0) {
                  <div
                    [ngClass]="{'add-order__categoryName' : selectedCategoryData?.['categoryInfo']?.['name']}">
                    <h4 style="margin:0">{{ selectedCategoryData?.['categoryInfo']?.['name'] }}</h4>
                  </div>
                }
                <div [ngClass]="{'add-order__showCategoryName' : selectedCategoryData?.['categoryInfo']?.['name']}"
                  class="add-order__products__productDetails__products">
                  @if ((allAvailableProducts$ | async) ) {
                    <div
                      [ngClass]="selectedUiConfig.view === 'rich' ? 'add-order__products__productDetails__products__products-container' : 'add-order__products__productDetails__products__products-container__simpleView'"
                      class="add-order__products__productDetails__products__products-container">
                      @for (item of (allAvailableProducts$ | async) | productSearch:{searchedProduct,categoryIdStr}; track item) {
                        <app-order-card (decrementQtyEmitter)="decrementCartQty($event)"
                          (emitAddItemDisabledBtnClicked)="addItemBtnDisableCheck($event)"
                          (emitOutOfStock)="outOfStockCheck($event)" (emitTableData)="updateTableData($event)"
                          (incrementQtyEmitter)="incrementCartQty($event)"
                          (updateShowTaxBreakup)="updateShowTaxBreakup($event)" [tableData]="selectedTableData" [hasAddAccess]="hasAddAccessAfterReceiptGeneration()"
                          [SelectedViewValue]="selectedUiConfig.view" [changeCartItems]="!isInKitchenItems"
                          [currentBranch]="current_branch" [item]="item" [kioskSettings]="kioskSettings"
                        [selectedDineType]="SelectBtnValue" [showtableData]="showtableData"></app-order-card>
                      }
                    </div>
                  }
                  @if (((allAvailableProducts$ | async) | productSearch:{searchedProduct,categoryIdStr}).length === 0) {
                    <div
                      class="noProductFound">
                      <p class="d-flex align-items-center" style="margin: 0px;font-size: 2rem"><i
                      class="pi pi-times-circle mr-2" style="font-size: 2rem"></i>No
                      Products @if (selectedCategoryData && selectedCategoryIdx >= 0) {
                      <span>&nbsp;for {{
                        selectedCategoryData['categoryInfo']['name']
                      }}</span>
                    }
                  </p>
                </div>
              }
            </div>
          </div>
        }
      </ng-template>
      <ng-template pTemplate>
        @if (selectedUiConfig.catPosition === 'catLeft') {
          <div>
            @if (selectedCategoryIdx >= 0) {
              <div
                [ngClass]="{'add-order__categoryName' : selectedCategoryData?.['categoryInfo']?.['name']}">
                <h4 style="margin:0">{{ selectedCategoryData?.['categoryInfo']?.['name'] }}</h4>
              </div>
            }
            <div [ngClass]="{'add-order__showCategoryName' : selectedCategoryData?.['categoryInfo']?.['name']}"
              class="add-order__products__productDetails__products">
              @if ((allAvailableProducts$ | async) ) {
                <div
                  [ngClass]="selectedUiConfig.view === 'rich' ? 'add-order__products__productDetails__products__products-container' : 'add-order__products__productDetails__products__products-container__simpleView'"
                  class="add-order__products__productDetails__products__products-container">
                  @for (item of (allAvailableProducts$ | async) | productSearch:{searchedProduct,categoryIdStr}; track item) {
                    <app-order-card (decrementQtyEmitter)="decrementCartQty($event)"
                      (emitAddItemDisabledBtnClicked)="addItemBtnDisableCheck($event)"
                      (emitOutOfStock)="outOfStockCheck($event)" (emitTableData)="updateTableData($event)"
                      (incrementQtyEmitter)="incrementCartQty($event)"
                      (updateShowTaxBreakup)="updateShowTaxBreakup($event)" [tableData]="selectedTableData" [hasAddAccess]="hasAddAccessAfterReceiptGeneration()"
                      [SelectedViewValue]="selectedUiConfig.view" [changeCartItems]="!isInKitchenItems"
                      [currentBranch]="current_branch" [item]="item" [kioskSettings]="kioskSettings"
                    [selectedDineType]="SelectBtnValue" [showtableData]="showtableData"></app-order-card>
                  }
                </div>
              }
              @if (((allAvailableProducts$ | async)| productSearch:{searchedProduct,categoryIdStr}).length === 0) {
                <div
                  class="noProductFound">
                  <p class="d-flex align-items-center" style="margin: 0px;font-size: 2rem"><i
                  class="pi pi-times-circle mr-2" style="font-size: 2rem"></i>No
                  Products @if (selectedCategoryData && selectedCategoryIdx >= 0) {
                  <span>&nbsp;for {{
                    selectedCategoryData['categoryInfo']['name']
                  }}</span>
                }
              </p>
            </div>
          }
        </div>
      </div>
    }
    @if (selectedUiConfig.catPosition !== 'catLeft') {
      <div>
        <div class="add-order__products__productDetails__categories">
          <div class="add-order__products__productDetails__categories__category">
            @for (category of (allAvailableCategories$ | async); track category; let idx = $index) {
              <div (click)="getSelectedCategory(category['_id'], idx,category)"
                pTooltip="{{category?.['categoryInfo']?.['name']}}" tooltipPosition="bottom">
                <div
                  [ngClass]="{'activeCat' :selectedCategoryData?._id && (category?._id !== selectedCategoryData?._id) , 'deActiveCat' : selectedCategoryData?._id && (category?._id === selectedCategoryData?._id) }"
                  class="cursor-pointer">
                      <div [ngStyle]="{
                        'color':'white',
                        'background': (selectedUiConfig.view === 'rich' && category?.['imgURL']) ? 'url(' + category?.['imgURL'] + ') center/cover no-repeat' : category?.categoryInfo?.color ,
                      }" class="category-image category-simple-mode">
                  </div>
                </div>
                    <p class="category-name cursor-pointer" [ngStyle]="{
                      'text-shadow':(selectedUiConfig.view === 'rich' && category?.['imgURL']) ? '2px 2px 2px rgba(0, 0, 0, 1.5)' : ''
                    }">
                {{ category['categoryInfo']['name'] }}</p>
              </div>
            }
          </div>
        </div>
      </div>
    }
  </ng-template>
</p-splitter>
</div>
}
</div>
</div>
</div>
<p-dialog [(visible)]="showWallpaper" [baseZIndex]="1000" [closable]="false" [draggable]="false" [modal]="true"
  [resizable]="false" class="table-order" header="Select Order Type" styleClass="order-type-select-dialog">
  @if (showLoader) {
    <div class="loader-overlay">
      <app-loader></app-loader>
    </div>
  }
  <div class="selectTypeButton">
    @for (defDineType of dineTypeSelectBtn; track defDineType) {
      @if (defDineType?.label !== 'app.pos.Other') {
        <button (click)="setDineType(defDineType?.value)"
        [label]="defDineType?.label | translate" class="font-bold" pButton></button>
      }
    }
    @for (dineType of additionalDineTypes; track dineType) {
      <button (click)="setDineType(dineType?.value,dineType)"
      [label]="dineType?.label | titlecase" class="font-bold" pButton></button>
    }
    @if (!additionalDineTypes?.length && !kioskSettings?.posUISettings?.dineType?.table && !kioskSettings?.posUISettings?.dineType?.dineIn && !kioskSettings?.posUISettings?.dineType?.dineOut) {
      <div
        >
        <div class="W100FL TAC">
          <h3 class="asset-not-found"><span>&ldquo;</span>{{
          'app.pos.no-dineType-selected' | translate
          }}<span>&rdquo;</span>
        </h3>
        <button (click)="showAddOrdersModal=false;showWallpaper=false" label="Close" pButton></button>
      </div>
    </div>
  }
</div>
</p-dialog>

<p-dialog (onHide)="switchDineType();checkIfTableSelected();" [(visible)]="showTableModal" [baseZIndex]="1000"
  [draggable]="false" [modal]="true" [resizable]="false" [style]="{width: '95vw', height: '95vh', zIndex: '1000' }"
  class="table-order">
  <ng-template pTemplate="header">
    <div style="display: flex; align-items: center; gap: 40px;">
      <span style="margin-right: 10px; font-weight: 600;font-size:1.5rem">{{
        isSwitchTable ? 'Switch table to' :
        'Table status'
      }}</span>
      <p-selectButton (onChange)="getTablesWithFloorId()" [(ngModel)]="currentFloor" [options]="maestroAreaData"
        [unselectable]="true" optionLabel="name" optionValue="_id">
      </p-selectButton>
    </div>
  </ng-template>
  @if (currentFloor && occupiedTableData?.length) {
    <div class="order-table">
      @for (item of occupiedTableData; track item) {
        <div (click)="isSwitchTable ? (openConfirmSwitchDialog(item)) : onTableSelect(item)"
          >
      <div [ngStyle]="{'background-color': item?.aProperties?.status === TABLE_STATUS.OCCUPIED ? '#DE3163' :
              item?.aProperties?.status === TABLE_STATUS.AVAILABLE ? '#378D4F' :
              item?.aProperties?.status === TABLE_STATUS.RECEIPT_GEN ? '#FFA500' :
              '#E0E5E1'}" class="order-table__container cursor-pointer" pTooltip="{{item.status}}"
            tooltipPosition="top">
            <span class="table-num">{{ item?.tableNo }}</span>
            @if (item?.timeDetails) {
              <span class="time-details" pTooltip="{{'Since ' + item.timeDetails.timePassed}}"
                tooltipPosition="bottom">
                {{ (item.timeDetails.createdTime) + ' ( ' + (item.timeDetails.timePassed) + ' )' }}
              </span>
            }
          </div>
        </div>
      }
    </div>
  }
  @if (!currentFloor || !occupiedTableData?.length) {
    <div>
      <div class="W100FL TAC flex">
        <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data-available' | translate }}<span>&rdquo;</span>
      </h3>
    </div>
  </div>
}
</p-dialog>
<p-dialog [(visible)]="showEditOrderOption" [baseZIndex]="1000" [draggable]="false" [modal]="true" [resizable]="false"
  [style]="{zIndex: '1000'}" class="table-order" header="Table No: {{selectedTableData?.tableNo}}">
  <div class="flex">
    <div class="table-order__status">Status : {{ selectedTableData?.aProperties?.status | titlecase }}
    </div>
    <button (click)="editOrBookOrder(selectedTableData)" class="mt-2" pButton>{{
      selectedTableData?.status ===
      'available' ? 'Book' : 'Edit'
      }}
    </button>
  </div>
</p-dialog>

@if ((cartItems$ | async); as cartData) {
  <div>
    <app-payment-gateway (emitCashRecieved)="goToPrintReciept($event)" (emitLoader)="setLoader($event)"
      (emitModalsAreClosed)="closeCashModal()" (emitQrIsClosed)="closeQRModal()" [TaxBreakupDetails]="TaxBreakupDetails"
      [branchName]="current_branch" [cartItems]="(cartItems$ | async)" [modalProps]="displayPayementStatusModal"
      [orderId]="cartOrderId" [qrProps]="showQrScanner" [selectedDineType]="SelectBtnValue"
      [showCardModal]="showCardModal" [showCashModal]="showCashModal">
    </app-payment-gateway>
  </div>
}

<p-dialog [baseZIndex]="1000" [closable]="false" [draggable]="false" [modal]="true" [resizable]="false"
  [style]="{width: '35vw', height: '25vh', zIndex: '2000'}" [visible]="reloadAgainState" class="table-order"
  header="Reload Required">
  <div class="selectTypeButton">
    <div>
      <div class="W100FL TAC">
        <h3 class="asset-not-found"><span>&ldquo;</span>{{
        'app.pos.new-changes-detected' | translate
        }}<span>&rdquo;</span>
      </h3>
      <button (click)="reloadApplication()" label="Reload" pButton></button>
    </div>
  </div>
</div>
</p-dialog>
<p-toast [life]="2000" key="kiosk-order-page"></p-toast>
@if (showLoader) {
  <div class="loader-overlay">
    <app-loader></app-loader>
  </div>
}


<ng-template #manageTableItems>
  @if (isInKitchenItems ? true : showtableData) {
    <div>
      @if (isInKitchenItems ? false : showWarningBorder) {
        <p class="w-100 text-center font-bold">{{
        'app.pos.itemsAreNotAdded' | translate }}</p>
      }
      @if ((isInKitchenItems ? true : tableCartItems?.length)) {
        <div
          [ngClass]="{'warning-border': isInKitchenItems ? false : showWarningBorder}" class="table-data">
          <p-fieldset legend="{{ (isInKitchenItems ? 'app.pos.kitchenItems' : 'app.pos.newItems') | translate }}">
            <div class="manage-cart-items">
              <div class="card add-order__products__cartDetails__billDetails__table">
                @for (item of (isInKitchenItems ? editInKitchenItems : tableCartItems); track item) {
                  <div
                    class="productItem flex-column">
                    <div class="productItem__container">
                      @if (selectedUiConfig.view === 'rich') {
                        <div class="productItem__imgContainer">
                          <img [ngClass]="{'gray-scale': isInKitchenItems ? false : item?.metaInfo?.outOfStockStatus}"
                            [src]="isInKitchenItems ? item['imageUrl'] : item['imageURL']" alt="product image"
                            class="productItem__img" height="168px"
                            onerror="src='https://livingstonbagel.com/wp-content/uploads/2016/11/food-placeholder.jpg'"
                            width="228px" />
                        </div>
                      }
                      <div class="productItem__info">
                        <div class="productItem__info__name">{{ isInKitchenItems ? item?.name : item['metaInfo']['itemName']
                          }}
                          @if (!isInKitchenItems && kioskSettings?.posUISettings?.showInstructions) {
                            <i
                              class="pi pi-plus-circle productItem__info__name__instrction"
                            (click)="setOrderInstruction(item);showAddInstructuinDialog = true"></i>
                          }
                        </div>
                        <div class="productItem__info__price mt-1 productAmount">₹
                          {{
                          (isInKitchenItems ? (item.price * item['quantity']) :
                          ((item['qty']) * (SelectBtnValue ? (item?.['metaInfo']?.['prices']?.[SelectBtnValue] ||
                          item?.['metaInfo']?.['price']) : item?.['metaInfo']?.['price']))).toFixed(2)
                          }}
                        </div>
                      </div>
                      <div class="productItem__actionBtns">
                        @if (isInKitchenItems ? true : !item?.metaInfo?.outOfStockStatus) {
                          <div class="btn-container">
                            <i (click)="actionOnTableCartItems(item,'decrement',$event)"
                            [ngClass]="{'disabled-btn': !item?.['quantity']}" class="pi pi-minus dec-btn"></i>
                            <span>{{ isInKitchenItems ? item['quantity'] : item['qty'] }}</span>
                            <i (click)="actionOnTableCartItems(item,'increment',$event)"
                              [ngClass]="{'disabled-btn': item?.['quantity']===item?.['originalQty']}"
                            class="pi pi-plus inc-btn"></i>
                          </div>
                        }
                        <div class="productItem__info__remove">
                          <i class="pi pi-trash"
                          (click)="isInKitchenItems ? removeInKitchenItem(item) : removeCartItem(item['productInfo']['_id'])"></i>
                        </div>
                      </div>
                    </div>
                    <div [ngClass]="{'ml-3': selectedUiConfig.view !== 'rich'}">{{ item?.defaultInstruction }} @if (item?.defaultInstruction?.length && item?.customInstruction) {
                      <span
                      >,</span>
                      } @if (item?.customInstruction) {
                      <span
                      >{{ item?.customInstruction }}</span>
                    }</div>
                  </div>
                }
              </div>
            </div>
            <div class="table-action-btn-container">
              @if (isInKitchenItems && !editInKitchenItems?.length) {
                <div>No Items</div>
              }
              @if (isInKitchenItems) {
                <p-button (click)="cancelKitchenItemsMod()" [raised]="true"
                label="{{'app.pos.cancel' | translate}}" severity="danger" styleClass="discard-btn"></p-button>
              }
              @if (isInKitchenItems) {
                <p-button (click)="actionOnTableCartItems(null,'remove-from-kitchen',$event)"
                  [disabled]="!changedInKitchenItems?.products?.remove?.length">
                  {{ 'app.pos.update' | translate }}
                </p-button>
              }
              @if (!isInKitchenItems) {
                <p-button (click)="clearCart('clearCart')"
                  [ngClass]="{'hide-class' : (isInKitchenItems ? true : subOrderData?.length) ? (isInKitchenItems ? editInKitchenItems : tableCartItems)?.length===0: false}"
                  [raised]="true" label="{{'app.pos.clearCart' | translate }}" severity="danger"
                styleClass="discard-btn"></p-button>
              }
              @if (!isInKitchenItems) {
                <p-button (click)="actionOnTableCartItems(null,'add-to-kitchen',$event)"
                  class="sbmt-btn">
                  {{ 'app.pos.sendToKitchen' | translate }}
                  &nbsp;
                  @if (!isInKitchenItems) {
                    <div class="color-changing-text">{{ selectedTableData?.tableNo }}</div>
                  }
                </p-button>
              }
            </div>
          </p-fieldset>
        </div>
      }
    </div>
  }

</ng-template>

<p-dialog (onHide)="resetModalData()" [(visible)]="salesModal.displayModal" [baseZIndex]="10000" [draggable]="false"
  [header]="salesModal.header" [modal]="true" [resizable]="false" [style]="{width: '95vw'}" class="totalSalesDialog">


  <p-table [value]="salesModal.tabledata" dataKey="_id" scrollHeight="65vh" styleClass="p-datatable">
    <ng-template let-columns pTemplate="header">
      <tr>
        @for (col of salesModalTableData; track col) {
          <th>
            <span>
              {{ col.field }}
            </span>
            <ng-template>
              {{ col.header }}
            </ng-template>
          </th>
        }

      </tr>
    </ng-template>

    <ng-template let-columns="columns" let-tableData pTemplate="body">
      <tr>
        @for (col of salesModalTableData; track col) {
          <td>
            <span>
              @if ((col['type']!=='date') && (col['type']!=='number') ) {
                <span>
                  {{ tableData[col?.key] ? tableData[col?.key] : '-' }}
                </span>
              }
              @if ((col['type']==='date')) {
                <span>
                  {{ tableData[col?.key] ? buildExpectedDeliveryDateTime(tableData[col?.key]) : '-' }}
                </span>
              }
              @if ((col['type']==='number')) {
                <span>
                  {{ tableData[col?.key] ? tableData?.[col?.key]?.toFixed(2) : '-' }}
                </span>
              }
            </span>
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">

      @if (salesModal.tabledata.length===0 && !salesModal.appLoader) {
        <div class="flex">
          <div class="W100FL TAC">
            <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data-available' | translate }}<span>&rdquo;</span>
          </h3>
        </div>
      </div>
    }

  </ng-template>
</p-table>

<ng-template pTemplate="header" style="align-items: center;padding-bottom: 0;">
  <div class="w-100 salesReportPopup">
    <h5>
      <b>
        {{ salesModal.header }} <span class="headerDate">
        (From: {{ dateFormatter.convertDateToSelectedTimezone(startRangeDate, 'DD/MM/YY, LT') }}
        -
        To: {{ dateFormatter.convertDateToSelectedTimezone(endRangeDate, 'DD/MM/YY, LT') }})
      </span>
    </b>
  </h5>
  <div class="salesReportPopup__reportPrint">
    @if (salesModal?.tabledata?.length > 0 && (ACTIONS.POS_OVERALL_SALES_REPORT | able: SUBJECT.POS_REPORTS)) {
      <button (click)="printSalesReport()"
        class="mr-3 mb-3 p-button-sm mt-1" icon="pi pi-print" pButton label="Overall Sales Report"
      pTooltip="Overall Sales Report"></button>
    }
    @if (salesModal?.tabledata?.length > 0 && (ACTIONS.POS_SALES_REPORT_BY_DINETYPE | able: SUBJECT.POS_REPORTS)) {
      <button (click)="printSalesReport(true)"
        class="mr-3 mb-3 p-button-sm mt-1" icon="pi pi-print" label="Sales Report by Dine Type" pButton
      pTooltip="Sales Report by dine type"></button>
    }
  </div>
</div>
</ng-template>

<!-- <ng-template pTemplate="footer" style="margin-top: -46px"> -->
<ng-container>
  @if ((salesModal?.pagination?.totalNoOfRecords > 0) && (salesModal.tabledata.length != 0)) {
    <p-paginator
      (onPageChange)="salesModalPaginate($event)" [rowsPerPageOptions]="[20,30,40]"
      [rows]="salesModal?.pagination?.recordsPerPage" [showJumpToPageDropdown]="true" [showPageLinks]="true"
      [totalRecords]="salesModal?.pagination?.totalNoOfRecords"
      currentPageReportTemplate="{first} to {last} of {totalRecords}">
    </p-paginator>
  }
</ng-container>
<!-- </ng-template> -->

@if (salesModal.appLoader) {
  <div class="store-table-loader">
    <app-loader></app-loader>
  </div>
}

</p-dialog>
<p-dialog [(visible)]="salesModal.displayConfirmModal" [baseZIndex]="10000" [closable]="false" [draggable]="true"
  [modal]="true" [resizable]="false" [style]="{'min-width': '30vw'}" class="sells-dialog">
  <ng-template pTemplate="header">
    <p class="sells-header">
      {{ 'app.pos.sales' | translate }}
    </p>
  </ng-template>
  <div class="sells-dialog-content">
    @if (maestroInfoData?.posData?.salesReportPassword?.type === 'string') {
      <div class="sells-password">
        <input pInputText placeholder="none" style="display: none;" type="text">
        <input [(ngModel)]="salesModal.password" id="password" pInputText placeholder="Enter Password" type="password" />
      </div>
    }
    @if (maestroInfoData?.posData?.salesReportPassword?.type === 'integer') {
      <app-pin-keyboard
        (emitConstructedCode)="getPinPassword($event)"
      [numberOfInputDigits]="maestroInfoData?.posData?.salesReportPassword?.value?.toString()?.length"></app-pin-keyboard>
    }
    <div class="sells-action-btns">
      <button (click)="salesModal.displayConfirmModal=false" icon="pi pi-times cancel-btn"
      label="{{'app.pos.cancel' | translate}}" pButton></button>
      <button (click)="checkPasswordToOpenSales()" icon="pi pi-eye" label="{{'app.pos.view' | translate}}"
      pButton></button>
    </div>
  </div>
</p-dialog>

<p-dialog (onHide)="resetModalData()" [(visible)]="salesModal.showShiftListModal" [baseZIndex]="10000"
  [draggable]="true" [modal]="true" [resizable]="false" [style]="{width: '50vw'}" class="sells-dialog shift-dialog"
  header="Shift List">

  <ng-template pTemplate="header">
    <p-selectButton (onChange)="salesOptionsChange()" [(ngModel)]="salesModal.selectedSalesOption"
    [options]="salesModal.salesOptions" optionLabel="label" optionValue="value" unselectable="true"></p-selectButton>
    @if (salesModal.selectedSalesOption === 'shiftBase' && (userDetails$ | async); as userDetails) {
      <div>Shift List of
        {{ userDetails?.firstName }}
        {{ userDetails?.lastName }} for last 24 hrs
      </div>
    }
    @if (isInternetAvailable) {
      <div><span style="font-size: 0.8em">From Server </span>
      <p-inputSwitch [ngModel]="reportGenFromServer()"
      (onChange)="reportGenFromServer.set($event.checked)"></p-inputSwitch>
    </div>
  }
</ng-template>

@if (salesModal.selectedSalesOption === 'shiftBase' && (userShiftData$|async); as userShifts) {
  <div>
    <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="userShifts">
      <ng-template pTemplate="header">
        <tr>
          <th>Sl.</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Duration</th>
          <th>Status</th>
          <th>View</th>
        </tr>
      </ng-template>
      <ng-template let-rowIndex="rowIndex" let-shift pTemplate="body">
        <tr>
          <td>{{ rowIndex + 1 }}</td>
          <td>{{
            dateFormatter.convertDateToSelectedTimezone(shift?.startDate, 'DD/MM/YY') + ' ' +
            dateFormatter.getFormattedTimeSlotForSingleTime(shift?.startDate)
            }}
          </td>
          <td>{{
            shift?.endDate ? dateFormatter.convertDateToSelectedTimezone(shift?.endDate, 'DD/MM/YY') + ' ' +
            dateFormatter.getFormattedTimeSlotForSingleTime(shift?.endDate) : '-'
            }}
          </td>
          <td>{{ getDuration(shift?.startDate, shift?.endDate) }}</td>
          <td>
            <p-tag [rounded]="true" [severity]="shift?.inProgress? 'warning' : 'success'"
            [value]="shift?.inProgress ? 'In progress' : 'Completed'"></p-tag>
          </td>
          <td><i (click)="getSelectedShiftSales(shift)" class="pi pi-eye cursorPointer"></i></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <!-- no data available -->
        @if (userShifts.length===0) {
          <div>
            <div class="W100FL TAC">
              <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.no-data-available' | translate }}
              <span>&rdquo;</span>
            </h3>
          </div>
        </div>
      }
    </ng-template>
  </p-table>
</div>
}

@if (salesModal.selectedSalesOption === 'timeBase') {
  <div>
    <div class="sells-dialog-content">
      <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="[{}]">
        <ng-template pTemplate="header">
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Duration</th>
            <th>View</th>
          </tr>
        </ng-template>
        <ng-template let-rowIndex="rowIndex" pTemplate="body">
          <tr>
            <td>
              <p-calendar #startCalender (onSelect)="onSalesDateSelected('start')" [(ngModel)]="startRangeDate"
                [dateFormat]="'dd/mm/yy'" [hideOnDateTimeSelect]="false" [maxDate]="maxSalesDetailsDate"
                [minDate]="minSalesDetailsDate" [readonlyInput]="true" [showTime]="true" appendTo="body" hourFormat="12"
                placeholder="Select Date Range" selectionMode="single">
                <ng-template pTemplate="footer">
                  <div class="sales-date-range">
                    @if (selectedDataRange.start) {
                      <p><span>Start Time:- </span>{{ selectedDataRange.start }}</p>
                    }
                    <i (click)="startCalender.toggle()" class="pi pi-check"></i>
                  </div>
                </ng-template>
              </p-calendar>
            </td>
            <td>
              <p-calendar #endCalender (onSelect)="onSalesDateSelected('end')" [(ngModel)]="endRangeDate"
                [dateFormat]="'dd/mm/yy'" [hideOnDateTimeSelect]="false" [maxDate]="maxSalesDetailsDate"
                [minDate]="minSalesDetailsDate" [readonlyInput]="true" [showTime]="true" appendTo="body" hourFormat="12"
                placeholder="Select Date Range" selectionMode="single">
                <ng-template pTemplate="footer">
                  <div class="sales-date-range">
                    @if (selectedDataRange.end) {
                      <p><span>End Time:- </span>{{ selectedDataRange.end }}</p>
                    }
                    <i (click)="endCalender.toggle()" class="pi pi-check"></i>
                  </div>
                </ng-template>
              </p-calendar>
            </td>
            <td>{{ getDuration(startRangeDate, endRangeDate) }}</td>
            <td><i (click)="getSelectedTimeBaseSales(startRangeDate,endRangeDate)" class="pi pi-eye cursorPointer"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
}

</p-dialog>

<ng-template #posSearchBar>
  <div (mouseenter)="openSearchBar= true;clearTimeout()" (mouseleave)="toggleSearchBar()" [ngClass]="{
      'search-box-focused': openSearchBar
  }" class="search-box">
    <input (onchange)="toggleSearchBar()" [(ngModel)]="searchedProduct" [disabled]="salesModal.displayConfirmModal"
      [ngClass]="{ 'search-input-focused': openSearchBar}" [placeholder]="placeholderValue" autocomplete="off"
      class="search-input" spellcheck="false" type="text">

    <p-button class="search-button" icon="pi pi-search"></p-button>

    @if (searchedProduct?.trim()?.length) {
      <i (click)="toggleSearchBar(true)"
      [ngClass]="{ 'hide-clear-icon': !openSearchBar,'show-clear-icon': openSearchBar}" class="pi pi-times"></i>
    }
  </div>
</ng-template>

<ng-template #posMenu>
  <div>
    <p-button (click)="menuButton.toggle($event)"
    [icon]="menuVisible ? 'pi pi-chevron-circle-up' : 'pi pi-chevron-circle-down'" class="menu-icon"></p-button>
    <p-overlayPanel #menuButton (onHide)="menuVisible=false" (onShow)="menuVisible=true">
      <ng-template pTemplate="content">
        <div class="menu-container">
          <div>
            <span class="menu-action-label">{{'app.pos.view' | translate}}</span>
            <p-selectButton (onChange)="changeSelectedViewValue('view',$event)" [(ngModel)]="selectedUiConfig.view"
              [options]="viewOption" [unselectable]="true" optionLabel="label" optionValue="value"
            styleClass="pos-menu-select-btn"></p-selectButton>
          </div>
          @if (this.kioskSettings?.posUISettings?.layout?.isButtonVisibile) {
            <div>
              <span class="menu-action-label">{{'app.pos.cart' | translate}}</span>
              <p-selectButton [(ngModel)]="selectedUiConfig.cartPosition" [options]="orderPageLayout?.cart"
                [unselectable]="true" optionLabel="label" optionValue="value" styleClass="pos-menu-select-btn"
                (onChange)="changeSelectedViewValue('cartPosition',$event)">
                <ng-template let-option pTemplate="option">
                  @switch (option.label) {
                    @case ('Cl') {
                      <i class="pi pi-arrow-left"></i>
                    }
                    @case ('Cr') {
                      <i class="pi pi-arrow-right"></i>
                    }
                  }
                </ng-template>
              </p-selectButton>
            </div>
          }
          @if (this.kioskSettings?.posUISettings?.layout?.isButtonVisibile) {
            <div>
              <span class="menu-action-label">{{'app.pos.category' | translate}}</span>
              <p-selectButton (onChange)="changeSelectedViewValue('catPosition',$event)"
                [(ngModel)]="selectedUiConfig.catPosition" [options]="orderPageLayout?.category" [unselectable]="true"
                optionLabel="label" optionValue="value" styleClass="pos-menu-select-btn">
                <ng-template let-option pTemplate="option">
                  @switch (option.label) {
                    @case ('catL') {
                      <i class="pi pi-arrows-h"></i>
                    }
                    @case ('catB') {
                      <i class="pi pi-arrows-v"></i>
                    }
                  }
                </ng-template>
              </p-selectButton>
            </div>
          }
          @if ((ACTIONS.SALES | able: SUBJECT.POS_MANAGEMENT)) {
            <div class="menu-sells-conatiner">
              <span class="menu-action-label">{{ 'app.pos.sales' | translate }}</span>
              <button (click)="openSalesModal()" [pTooltip]="'Click to view today sales'" icon="pi pi-money-bill"
                label="{{'app.pos.viewSales' | translate}}" pButton style="margin-top:0.5rem"
              styleClass="pos-menu-select-btn"></button>
            </div>
          }
          <div>
            <span class="menu-action-label">{{ 'app.pos.language' | translate }}</span>
            <p-selectButton [(ngModel)]="selectedLang" [options]="lang" optionLabel="code" optionValue="code"
              styleClass="pos-menu-select-btn" (onChange)="onLangChange()">
            </p-selectButton>
          </div>
        </div>
        <span (click)="showDbUrlEditDialog = true" class="version-number">v-{{ versionNumber }}</span>
      </ng-template>
    </p-overlayPanel>
  </div>
</ng-template>

<p-dialog [(visible)]="switchToTable" [closable]="false" [modal]="true"
  header="{{'app.pos.switchTableConfirm' | translate}}" styleClass="table-switch-dialog">
  <ng-template pTemplate="content">
    <div class="tables-switch-panel">
      <div class="tables-in-switch">
        <div>
          <p class="floor-label">{{ selectedTableData?.areaData?.name || 'Floor' }}</p>
          <div [ngStyle]="{'background-color': '#DE3163'}" class="order-table__container cursor-pointer"
            pTooltip="Occupied" tooltipPosition="top">
            <span class="table-num">{{ selectedTableData?.tableNo }}</span>
          </div>
        </div>

        <div>
          <i class="pi pi-arrow-right switch-icon"></i>
        </div>
        <div>
          <p class="floor-label">{{ switchToTable?.areaData?.name || 'Floor' }}</p>
          <div [ngStyle]="{'background-color': '#378D4F'}" class="order-table__container cursor-pointer"
            pTooltip="Available" tooltipPosition="top">
            <span class="table-num">{{ switchToTable?.tableNo }}</span>
          </div>
        </div>

      </div>
      <div class="table-switch-confirm-buttons">
        <p-button (click)="switchToTable = null" [raised]="true" icon="pi pi-times"
        label="{{'app.pos.cancel' | translate}}" severity="danger" styleClass="discard-btn"></p-button>
        <p-button (click)="switchTableData(switchToTable)" [raised]="true" icon="pi pi-check"
        label="Confirm"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showDbUrlEditDialog" [baseZIndex]="10000" [closable]="true" [draggable]="true" [modal]="true"
  [resizable]="false" [style]="{width: '20vw'}" class="sells-dialog sells-dialog-width">
  <ng-template pTemplate="header">
    <div>
      {{ 'app.pos.about' | translate }}
    </div>
  </ng-template>
  <div class="sells-dialog-content">
    <div class="sells-password">
      <input [(ngModel)]="syncdb.url" id="url" pInputText placeholder="Enter dbUrl" type="text" />
    </div>
    <div class="sells-password">
      <input [(ngModel)]="syncdb.userid" pInputText placeholder="Enter UserId" type="text" />
    </div>
    <div class="sells-password">
      <input [(ngModel)]="syncdb.password" pInputText placeholder="Enter Password" type="password" autocomplete="off" />
    </div>
    <div class="about-btn-container">
      <div class="sells-action-btn">
        <button (click)="updateDbUrl();showDbUrlEditDialog= false" icon="pi pi-check"
        label="{{'app.pos.update' | translate}}" pButton></button>
      </div>
    </div>
    <p-divider></p-divider>
    <div class="about-btn-container">
      <div class="sells-action-btn">
        <div>**{{ 'app.pos.thisWillClearAllAppDataFromDevice' | translate }}:</div>
        <p-button (click)="resetPosStoredData();showDbUrlEditDialog= false" severity="danger" icon="pi pi-reply"
          label="{{'app.pos.resetApp' | translate }}">
        </p-button>
      </div>
    </div>
  </div>
</p-dialog>


@if ((syncing$ | async); as sync) {
  <p-dialog [(visible)]="sync.isSyncing" [baseZIndex]="10000" [closable]="false" [draggable]="false" [modal]="true"
    [resizable]="false" [style]="{width: '20vw'}" class="sells-dialog sells-dialog-width">
    <ng-template pTemplate="header">
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <i class="pi pi-spin pi-sync" style="font-size: 1.5rem"></i>
        <span class="font-bold white-space-nowrap">Syncing...</span>
      </div>
    </ng-template>
    <div style="color: green">Please wait, make sure you have stable Internet connection.</div>
    <br>
      <div>From: {{ sync.url }}</div>
      <br>
        <div style="margin: 0 auto;overflow-wrap: break-word;word-wrap: break-word;">{{ sync.msg }}</div>
        <br> <br>
        @if (sync?.error) {
          <div>
            <div>
              @if (isInternetAvailable) {
                <p style="color: palevioletred; font-size: 0.7rem">**Resetting device will loose
                  all the transactional data
                  <b style="color: red">(Please contact support)</b>
                </p>
              }
            </div>
            <div style="display: flex; justify-content: space-between">
              @if (isInternetAvailable) {
                <p-button (click)="resetPosStoredData()" severity="danger">Reset Device</p-button>
              }
              <p-button (click)="logout()" outlined severity="secondary">Logout</p-button>
            </div>
          </div>
        }
      </p-dialog>
    }

    <p-dialog header="Add instruction" [(visible)]="showAddInstructuinDialog" [baseZIndex]="10000" [closable]="true"
      [draggable]="true" [modal]="true" [resizable]="false" [style]="{width: '30vw'}"
      class="sells-dialog sells-dialog-width">
      <div class="sells-dialog-content">
        <div class="chips-container">
          @for (chip of chips; track chip) {
            <div class="chip" [class.selected]="chip.selected" (click)="toggleChipSelection(chip)">
              @if (chip.selected) {
                <span class="check-mark"><i class="pi pi-check"></i></span>
              }
              {{ chip.label }}
            </div>
          }
        </div>
        <div style="padding:10px 0;font-weight:600">Additional Instruction</div>
        <div class="sells-password">
          <input #ins [ngModel]="currentAddOrderInstructionObj?.customInstruction" id="instruction" pInputText
            placeholder="Type additional instruction" type="text" />
        </div>
        <div class="about-btn-container">
          <div class="btns">
            <p-button (click)="showAddInstructuinDialog = false" label="{{'app.pos.cancel' | translate }}"
            icon="pi pi-times" [outlined]="true" severity="danger"></p-button>
          </div>
          <div class="btns">
            <p-button (click)="addOrderInstruction(ins?.value)" icon="pi pi-check" label="Add" [raised]="true"></p-button>
          </div>
        </div>
      </div>
    </p-dialog>

    @if (syncdb?.isLocalNetwork && (syncing$ | async); as sync) {
      <p-dialog [visible]="!!sync?.error && !sync?.['syncing']" [closable]="false" [closeOnEscape]="false" [modal]="true">
        <div class="sync-error-container" style="display: flex;align-items:center;flex-direction: column;">
          <i class="pi pi-exclamation-triangle sync-error-container__icon"></i>
          <div class="sync-error-container__title">Sync Error</div>
          <p-button (onClick)="reSync()" [rounded]="true" icon="pi pi-refresh" label="Re-Sync">
          </p-button>
          <div class="sync-error-container__subtitle">( Please contact manager )</div>
        </div>
      </p-dialog>
    }

    <p-confirmPopup [key]="popUpKey">
      <ng-template let-message pTemplate="content">
        <div [ngClass]="{'content': popUpKey === 'logoutKey'}">
          <div style="display: flex">
            <i [class]="isInternetAvailable && (popUpKey === 'internetStatus') ? 'pi pi-info-circle' : printerStatus?.allConnected && (popUpKey === 'printerStatus') ? 'pi pi-info-circle' : 'pi pi-exclamation-triangle' "
              class="text-2xl text-primary-500"
            [ngClass]="{'popupIcon': popUpKey === 'printReceipt' || popUpKey === 'addToKitchen'}"></i>
            <p class="font-bold" [ngClass]="{'text-danger': popUpKey === 'printReceipt' || popUpKey === 'addToKitchen'}"
              style="margin-left:6px">{{
            message?.message }} </p>
          </div>
          <p-divider></p-divider>
          @if (popUpKey==='logoutKey') {
            <div style="display: flex;margin-left:2rem;flex-direction: column;">
              @if ((userDetails$ | async); as userDetails) {
                <div>{{ 'app.pos.username' | translate }}
                  : {{ userDetails?.firstName }}
                </div>
              }
              @if (shift?.isStarted && isInternetAvailable) {
                <div> {{ 'app.pos.shiftTimeAndDuration' | translate
                  }}
                  : {{ shift.isStarted ? shift.startTimeFormatted : '' }}
                </div>
              }
            </div>
          }
          @if (popUpKey==='printerStatus') {
            <div>
              @if (printerStatus?.status; as statuss) {
                <div style="padding: 1rem 1rem 0">
                  @if (statuss) {
                    <div>
                      @for (item of statuss | keyvalue; track item) {
                        <div class="printerStatus">
                          @if (item.value?.['type'] && item.key) {
                            <p-card [header]="item.key" [subheader]="item.value?.['type']">
                              <div>
                                @if (item?.value) {
                                  <span
                                    [ngClass]="{ 'text-success' : item?.value?.isConnected , 'text-danger' : !item?.value?.isConnected}">
                                    {{ item?.value?.event }}
                                  </span>
                                } @else {
                                  <div>
                                    <span
                                      [ngClass]="{ 'text-success' : item?.value?.isConnected , 'text-danger' : !item?.value?.isConnected}">
                                      {{ item?.value?.event }}
                                    </span>
                                  </div>
                                }
                              </div>
                              @if (!item?.value?.isConnected) {
                                <ng-template pTemplate="footer">
                                  <p-button (onClick)="retryConnTOPrinter(item?.key)" [rounded]="true" icon="pi pi-refresh"
                                    label="Reconnect">
                                  </p-button>
                                </ng-template>
                              }
                            </p-card>
                          }
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
          }
          @if (popUpKey === 'internetStatus') {
            <div style="margin-top:1rem">
              @if (isInternetAvailable) {
                <span>{{ 'app.pos.yourApplicationIsIn' | translate }}
                  <span class="font-bold">{{ 'app.pos.onlineMode' | translate }}</span>.</span>
                } @else {
                  <span> {{ 'app.pos.yourApplicationIsIn' | translate }} <span class="font-bold">{{
                  'app.pos.offlineMode' | translate }}</span>{{ 'app.pos.withLimitedFunctionality' | translate
                  }}
                  .<br> {{ 'app.pos.pleaseReconnectToInternet' | translate }}.</span>
                }
              </div>
            }
            @if (popUpKey === 'syncStatus') {
              <div style="margin-top:1rem">
                <div>
                  <p> {{ (syncing$ | async).error?.toString() ?? 'Sync Active' }}</p>
                  <p-button (onClick)="reSync()" [rounded]="true" icon="pi pi-refresh" label="Re-Sync">
                  </p-button>
                </div>
              </div>
            }
            @if (popUpKey === 'confirmShiftStart') {
              <div style="margin-top:1rem">
                <div>
                  @if (shift.isStarted) {
                    <p class="ml-5">{{ 'app.pos.shiftTime' | translate }}
                    : {{ shift.startTimeFormatted }}</p>
                  }
                </div>
              </div>
            }
            @if (popUpKey === 'addToKitchen' || popUpKey === 'printReceipt') {
              <div style="padding: 0 1rem;font-size:1.2rem">
                <div class="font-bold">{{printerStatusData.ipAddress}}</div>
                <div class="text-danger">{{printerStatusData.error}}</div>
                @if (popUpKey === 'addToKitchen') {
                  <div class="font-bold mt-1">Do you still want to proceed ?</div>
                }
                @if (popUpKey === 'addToKitchen') {
                  <div>Note: If you proceed, receipt will not print.</div>
                }
              </div>
            }
          </div>
        </ng-template>
      </p-confirmPopup>
