import { buildOr as t, buildAnd as n, CompoundCondition as r } from "@ucast/mongo2js";
function e(t) {
  return Array.isArray(t) ? t : [t];
}
function o(t, n, r) {
  let e = t;
  let o = n;
  if (n.indexOf(".") !== -1) {
    const r = n.split(".");
    o = r.pop();
    e = r.reduce((t, n) => {
      t[n] = t[n] || {};
      return t[n];
    }, t);
  }
  e[o] = r;
}
const i = t => Array.isArray(t) ? t.join(",") : t;
function s(t, n) {
  return t.map(t => {
    const r = [i(t.action || t.actions), typeof n === "function" ? e(t.subject).map(n).join(",") : i(t.subject), t.conditions || 0, t.inverted ? 1 : 0, t.fields ? i(t.fields) : 0, t.reason || ""];
    while (r.length > 0 && !r[r.length - 1]) r.pop();
    return r;
  });
}
function u(t, n) {
  return t.map(([t, r, e, o, i, s]) => {
    const u = r.split(",");
    const c = {
      inverted: !!o,
      action: t.split(","),
      subject: typeof n === "function" ? u.map(n) : u
    };
    if (e) c.conditions = e;
    if (i) c.fields = i.split(",");
    if (s) c.reason = s;
    return c;
  });
}
function c(t, n, r, e) {
  const o = t.detectSubjectType(r);
  const i = t.possibleRulesFor(n, o);
  const s = new Set();
  const u = s.delete.bind(s);
  const c = s.add.bind(s);
  let f = i.length;
  while (f--) {
    const t = i[f];
    if (t.matchesConditions(r)) {
      const n = t.inverted ? u : c;
      e.fieldsFrom(t).forEach(n);
    }
  }
  return Array.from(s);
}
class f {
  constructor(t, n, r) {
    this.t = t;
    this.o = n;
    this.i = r;
  }
  ofType(t) {
    return c(this.t, this.o, t, {
      fieldsFrom: this.u(t)
    });
  }
  of(t) {
    return c(this.t, this.o, t, {
      fieldsFrom: this.u(this.t.detectSubjectType(t))
    });
  }
  u(t) {
    return n => n.fields || this.i(t);
  }
}
function l(t, n, r) {
  return t.rulesFor(n, r).reduce((t, n) => {
    if (n.inverted || !n.conditions) return t;
    return Object.keys(n.conditions).reduce((t, r) => {
      const e = n.conditions[r];
      if (!e || e.constructor !== Object) o(t, r, e);
      return t;
    }, t);
  }, {});
}
function h(t, n, r, e) {
  const o = {};
  const i = t.rulesFor(n, r);
  for (let t = 0; t < i.length; t++) {
    const n = i[t];
    const r = n.inverted ? "$and" : "$or";
    if (!n.conditions) {
      if (n.inverted) break;else {
        delete o[r];
        return o;
      }
    } else {
      o[r] = o[r] || [];
      o[r].push(e(n));
    }
  }
  return o.$or ? o : null;
}
function a(t) {
  if (!t.ast) throw new Error(`Ability rule "${JSON.stringify(t)}" does not have "ast" property. So, cannot be used to generate AST`);
  return t.inverted ? new r("not", [t.ast]) : t.ast;
}
function d(r, e, o) {
  const i = h(r, e, o, a);
  if (i === null) return null;
  if (!i.$and) return i.$or ? t(i.$or) : n([]);
  if (i.$or) i.$and.push(t(i.$or));
  return n(i.$and);
}
export { f as AccessibleFields, s as packRules, c as permittedFieldsOf, d as rulesToAST, l as rulesToFields, h as rulesToQuery, u as unpackRules };
