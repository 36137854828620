<p-dialog class="online-payment" (onHide)="closeCashModal()" [(visible)]="showCashModal" [modal]="true"
  [baseZIndex]="10000" [draggable]="false" [resizable]="false" [draggable]="true">
  <ng-template pTemplate="header" class="position-relative">
    <div class="col-3 float-md-right mr-0 d-flex col-12 justify-content-between header-container" style="width:93%">
      @if (!showRefundModal && !showCardModal) {
        <p>Cash Payment</p>
      }
      @if (showCardModal) {
        <p>Online Payment</p>
      }
      @if (showRefundModal) {
        <p>Refund the Payment</p>
      }
      @if (orderId && showRefundModal) {
        <p class="mr-4">Order Id: {{orderId}}</p>
      }
    </div>
  </ng-template>
  <div
    style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%; height: calc(85vh - 58px);">
    <div class="order-detail-container">
      @if (orderId) {
        <p class="mr-4 font-bold" style="font-size: 1.5rem">Order Id: {{orderId | slice:-5}}</p>
      }
      <div class="orderDetails">
        <div class="card add-order__products__cartDetails__billDetails__orderDetailsTable">
          <p-table [columns]="orderDetailsPrintPageTable" [value]="cartItems" class="productTable">
            <ng-template pTemplate="header" let-columns>
              <tr>
                @for (col of columns; track col) {
                  <th>
                    {{ col.header }}
                  </th>
                }
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-columns="columns">
              <tr>
                <td class="item-name">{{rowIndex+1}}</td>
                <td class="item-name">{{item['metaInfo']['itemName']}}</td>
                <td class="item-name">{{ item.qty }}</td>
                <td>₹
                  {{
                  (item['qty']) * (selectedDineType ?
                  (item?.['metaInfo']?.['prices']?.[selectedDineType] ||
                  item?.['metaInfo']?.['price']) :item?.['metaInfo']?.['price'])
                  }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="bill-div">
          <div class="bill-val">
            <p style="margin: 0px;">{{'app.pos.subtotal' | translate}}</p>
            <p style="margin: 0px;">{{TaxBreakupData?.orderDetails?.payment?.price?.items?.length}}
              {{'app.pos.items' | translate}}
            </p>
            <p style="margin: 0px;">{{TaxBreakupData?.formattedTotal}}</p>
          </div>
          <div class="bill-val">
            <p style="margin: 0px;">{{'app.pos.tax' | translate}}</p>
            @if (TaxBreakupData?.totalTax !== '0.00') {
              <p
                style="cursor: pointer; margin: 0px; color: #27c0b3;" (click)="toggleTaxDetails()">
              {{ (showTaxDetails ? 'app.pos.hideDetails' : 'app.pos.viewDetails') | translate }}</p>
            }
            <p style="margin: 0px;">₹{{TaxBreakupData?.orderDetails?.payment?.price?.items?.length > 0 ?
            TaxBreakupData?.totalTax : '0.00'}}</p>
          </div>
          @if (showTaxDetails && TaxBreakupData?.orderDetails?.payment?.price?.items?.length > 0) {
            <div class="tax-info"
              >
              @for (item of TaxBreakupData?.cartTax; track item) {
                <p style="margin: 0px;">{{item?.name}}
                ₹{{item?.amt?.toFixed(2)}}</p>
              }
            </div>
          }
          <div class="bill-val">
            <p style="margin: 0px;">{{'app.pos.parcelCharges' | translate}}</p>
            <p style="margin: 0px;">{{TaxBreakupData?.orderDetails?.payment?.price?.parcelCharge?.total}}
            </p>
          </div>
          @if ((productPricing$ | async)?.roundingEnabled && TaxBreakupData?.orderDetails?.payment?.price?.roundedAmount) {
            <div class="bill-val"
              >
              <p style="margin: 0px;">{{'app.pos.roundedAmount' | translate}}</p>
              <p style="margin: 0px;">{{TaxBreakupData?.orderDetails?.payment?.price?.roundedAmount}}
              </p>
            </div>
          }
          <div class="bill-val font-bold" style="font-size: 2.3rem">
            <p style="margin: 0px;">{{'app.pos.total' | translate}}</p>
            <p class="total-price" style=" margin: 0px;">
              ₹{{TaxBreakupData?.orderDetails?.payment?.price?.items?.length > 0 ?
            TaxBreakupData?.orderDetails?.payment?.price?.total.toFixed(2):'0.00'}}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      @if (showRefundModal) {
        <p style="margin-bottom: 2rem; font-size: 1.5rem; font-weight: 600;">Actual Amount:
        ₹ {{(totalAmount)}}</p>
      }
      <div>
        <div class="mb-2 d-flex cash-modal-payment-label">
          @if (!showRefundModal && !showCardModal) {
            <i
            class="d-flex align-items-center pi pi-money-bill"></i>
          }
          @if (showCardModal) {
            <i class="d-flex align-items-center pi pi-credit-card"></i>
          }
          <p class="font-bold total-price ml-2">{{((!showRefundModal && !showCardModal) ?
            'app.pos.cashPayment' :
          showCardModal ? 'app.pos.cardPayment' : '') | translate}}</p>
        </div>
        @if (!showRefundModal) {
          <label style="font-size: 1.5rem;">{{'app.pos.enterTheAmountReceived' |
          translate}} ₹</label>
        }
        @if (showRefundModal) {
          <label style="font-size: 1.5rem;">Enter the amount to be refunded: ₹</label>
        }
        <div class="flex align-items-center">
          @if (!showRefundModal) {
            <p-inputNumber [(ngModel)]="amountReceived"
              (keyup)="getAmountToBeGivenBack($event)" [min]="0"
              placeholder="{{'app.pos.enterTheAmount' | translate}}" />
          }
          <p-button label="Rcv. exact Amt" class="p-button-text font-bold ml-4" [autofocus]="true"
          (click)="exactAmtSave()"></p-button>
        </div>
        @if (showRefundModal) {
          <p-inputNumber [(ngModel)]="amountToRefund"
            (keyup)="getAmountToBeRefunded($event)" [min]="0"
            placeholder="{{'app.pos.enterTheAmount' | translate}}" />
        }
        <div class="charged-amt">
          @if (showRefundModal && amountToRefund && (amountToRefund <= totalAmount) ) {
            <label
            style="font-size: 1.5rem;">Charged Amount: {{(totalAmount - amountToRefund) | number}}</label>
          }
          @if (showRefundModal && amountToRefund && (amountToRefund > totalAmount)) {
            <label class="refund-warn"
              > Refund amount is
            more than total amount !</label>
          }

        </div>

      </div>
      @if (!showRefundModal && !showAmount) {
        <p style="margin-block: 2rem; font-size: 1.5rem;">
          {{(amountToGiveBack < 0? 'app.pos.amountToBePaid' :'app.pos.amountToBeGivenBack') | translate }} <span
          style="font-weight: 600;font-size:2rem"
          [ngStyle]="{'color': amountToGiveBack < 0 ? 'red' :' #6cc027' }">
        ₹{{amountToGiveBack < 0? (-1*amountToGiveBack)?.toFixed(2):amountToGiveBack?.toFixed(2)}} </span>
      </p>
    }
    @if (showAmount) {
      <p style="margin-block: 2rem; font-size: 1.5rem;">{{'app.pos.amountToBePaid' |
        translate}}
        <span style="font-weight: 600;font-size:2rem" [ngStyle]="{'color': 'red'}">
          ₹{{(amountToBePaid*1).toFixed(2)}}
        </span>
      </p>
    }
    @if (!showRefundModal && showCashModal) {
      <div>
        <button type="button" class="cash-modal-cta" pButton [disabled]="amountReceived < (amountToBePaid*1)"
          (click)="goToPrintReciept()">{{(isUpdatingPayment? 'Pay':'app.pos.goToPrintInvoice') | translate
        }}</button>
      </div>
    }
    @if (showRefundModal) {
      <div class="refund-modal">
        <button [loading]="refundInitialisationCall" pButton (click)="startRefund()" class="cash-modal-btn"
          label="{{isUpdatingPayment? 'Pay':'Start Refund'}}"
        [disabled]="(amountToRefund < (0)) || (amountToRefund > totalAmount) || refundInitialisationCall"></button>
      </div>
    }
  </div>
</div>
</p-dialog>



<!-- refund-modal -->
<p-dialog class="online-payment" (onHide)="closeCashModal()" [(visible)]="showActualRefundModal" [modal]="true"
  [style]="{width: '50vw', height: '85vh' }" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header" class="position-relative">
    <div class="col-3 float-md-right mr-0 d-flex col-12 justify-content-between header-container">
      @if (!showActualRefundModal) {
        <p>Cash Payment</p>
      }
      @if (showActualRefundModal) {
        <p>Refund the Payment</p>
      }
      @if (orderId) {
        <p>Order Id: {{orderId}}</p>
      }
    </div>
  </ng-template>

  <!-- <p-dialog class="order-summary" header="Order Summary" [(visible)]="displayReciept">
  <img class="order-img" src={{orderObject}} alt="reciept">
</p-dialog> -->
<div class="img-scroll">
  <img src={{recieptImgUrl}}>
</div>
<div class="order-actn">
  @if (showActualRefundModal) {
    <p class="refund-amount"
      style="margin-bottom: 2rem; font-size: 1.5rem; font-weight: 600;">Actual Amount: ₹ {{(totalAmount) |
    number}}</p>
  }
  <div style="display: flex; flex-direction: column;">
    @if (!showActualRefundModal) {
      <label
        style="font-size: 1.5rem;white-space: nowrap;">{{'app.pos.enterTheAmountReceived' | translate}}
      ₹</label>
    }
    @if (showActualRefundModal) {
      <label style="font-size: 1.5rem;white-space: nowrap;">Enter the amount to be
      refunded: ₹</label>
    }
    @if (showActualRefundModal) {
      <p-inputNumber [(ngModel)]="amountToRefund"
        (keyup)="getAmountToBeRefunded($event)" [min]="0"
        placeholder="{{'app.pos.enterTheAmount' | translate}}" />
    }

    <div class="charged-amt">
      @if (showActualRefundModal && amountToRefund && (amountToRefund <= totalAmount) ) {
        <label>Charged
        Amount: {{(totalAmount - amountToRefund) | number}}</label>
      }
      @if (showActualRefundModal && amountToRefund && (amountToRefund > totalAmount)) {
        <label class="refund-warn"
          > Refund amount is
        more than total amount !</label>
      }

    </div>

  </div>
  @if (showActualRefundModal) {
    <div class="refund-modal">
      @if (showActualRefundModal) {
        <input class="refund-reason" (keyup)="getReasonForRefund($event)" type="text"
          placeholder="Enter the reason for refund" />
      }
      <button [loading]="refundInitialisationCall" pButton (click)="startRefund()" class="cash-modal-btn"
        label="{{isUpdatingPayment? 'Pay':'Start Refund'}}"
      [disabled]="(amountToRefund < (0)) || (amountToRefund > totalAmount) || refundInitialisationCall"></button>
    </div>
  }
</div>
</p-dialog>



<p-dialog class="pre-refund-dialog" header="Do you want to proceed to Refund ?" (onHide)="closeCashModal()"
  [(visible)]="showRefundModal" styleClass="refund-modal">
  <div class="pre-refund-dialog-button-contanier">
    <p-button (click)="showRefundModal=false" icon="pi pi-times" styleClass="p-button-outlined" label="Cancel"
    class="p-button-danger"></p-button>
    <p-button (click)="showActualRefundModal=true" icon="pi pi-check" label="Continue"></p-button>
  </div>
</p-dialog>

<p-toast key="refund-status-msg" [position]="'center'"></p-toast>