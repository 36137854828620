import { createReducer, on } from '@ngrx/store';
import { IStatus, KioskState } from 'src/app/interface/maestro-interface';
import { KioskActions } from '.';
import {
  decrementQtyViaProduct,
  extractProductDataFromCategory,
  getKAgentWithOnlineStatus,
  incrementQtyViaProduct,
  removeCartItem,
  setAvaialableProducts,
  updateCartValue,
  updateKAgentStatusAfterSocketPush,
} from './kiosk.reducer.helper';
import { PRINT } from 'src/app/constant';

export const initialState: KioskState = {
  status: 'Pending',
  error: null,
  currentBranch: null,
  branchList: null,
  productsCount: null,
  unlinkedProductCount: null,
  productList: null,
  unlinkedProductList: [],
  allProducts: null,
  allProductsCount: null,
  allOrders: null,
  productLinkResponseMsg: null,
  productLinkResponseType: null,
  productGroupResponseMsg: null,
  assetLinkSuccess: null,
  productRemovedresponse: null,
  delinkGroupResponse: null,
  categoryList: null,
  groupList: null,
  assetConfig: null,
  modifyFlag: null,
  delinkGroupErrResponse: null,
  assetLinkMetaFailureResponse: null,
  kioskSettings: null,
  kioskSettingsMetaData: null,
  kioskSettingsUpdatedResponse: null,
  genericSettingUpdateLoader: false,
  genericSettingsByGroupLoader: false,
  allGroupsNamesSetttingForKiosk: null,
  kioskStoreUpdated: {
    updated: null,
  },
  categoryTableData: [],
  catergoryReorderedKiosk: {},
  addStoreSidebarBoolean: false,
  payamentGatewayList: [],
  availableCategories: [],
  availableProducts: [],
  cartItems: [],
  productGroups: [],
  quantityStatus: {
    status: null,
    statusMsg: '',
  },
  cartLen: 0,
  itemQty: new Map<
    string,
    {
      qty: number;
      idx: number;
    }
  >(),
  verificationProcess: {
    processingDone: false,
    status: '',
    data: null,
  },
  razorpayData: null,
  razorpayConfig: {
    key: '',
    name: '',
    description: 'True couverture chocolates',
    image: '',
    amount: '5000',
    currency: 'INR',
    order_id: 'order_JZMufGF8DhHO9Y',
    handler: new Function(),
    modal: {
      backdropclose: true,
      ondismiss: new Function(),
      animation: true,
    },
    notes: {
      orderId: '',
      payment_id: '',
      accountId: '',
      orderDocId: '',
      customerId: '',
      linkedAccount: '',
      env: '',
    },
    theme: {
      color: '',
    },
  },
  kAgents: null,
  kioskData: null,
  kAgentLoader: false,
  kAgentMetadata: null,
  maestroAreas: null,
  kitchenSentData: null,
  maestroData: null,
  userShift: {
    status: false,
    allShifts: [],
    currentShiftData: null,
  },
  receiptTemplate: null,
  maestroTable: [],
  printerStatus: null,
  isSwitchTable: false,
  additionalDinetype: [],
};

export const KioskReducer = createReducer(
  initialState,
  on(KioskActions.loadBranchListSuccess, (state, { branchList }) => {
    return {
      ...state,
      status: 'Success',
      error: '',
      branchList: branchList,
    };
  }),
  on(KioskActions.loadBranchListFailure, (state, error) => {
    return {
      ...state,
      error: error.err,
      status: 'Error',
    };
  }),
  on(KioskActions.currentBranch, (state, { currentBranch }) => {
    return {
      ...state,
      currentBranch: currentBranch,
    };
  }),

  on(KioskActions.reset, (state) => {
    return {
      ...state,
      status: 'Pending',
      error: null,
      currentBranch: null,
      branchList: null,
      productsCount: null,
      productList: null,
      allProducts: null,
      allOrders: null,
      unlinkedProductCount: null,
      unlinkedProductList: null,
      productLinkResponseMsg: null,
      productLinkResponseType: null,
      productGroupResponseMsg: null,
      assetLinkSuccess: null,
      productRemovedresponse: null,
      delinkGroupResponse: null,
      categoryList: null,
      groupList: null,
      assetConfig: null,
      modifyFlag: null,
      delinkGroupErrResponse: null,
      assetLinkMetaFailureResponse: null,
      kioskSettings: null,
      kioskSettingsUpdatedResponse: null,
    };
  }),

  on(KioskActions.resetProductLinkResponseType, (state) => {
    return {
      ...state,
      productLinkResponseType: null,
    };
  }),

  on(KioskActions.getKioskSettingsSuccess, (state, { response }) => {
    console.log(response, 'radheradhe');
    if (response) {
      if (response?.slots?.window) {
        const ans = [...response?.slots?.window];
        ans.sort((a, b) => {
          return a.start.h - b.start.h;
        });

        const obj = {
          ...response,
          slots: {
            ...response.slots,
            window: ans,
          },
          syncdb:
            state?.kioskSettings?.['maestroData']?.['posData']?.['syncdb'] ??
            response?.syncdb,
          printerData: {
            printConfig: response?.printConfig,
            kAgent: response?.kAgent,
            isPrinterPolingEnabled:
              response?.kioskUISettings?.miscellaneous?.printerPollingEnabled,
          },
        };
        return {
          ...state,
          kioskSettings: { ...state.kioskSettings, ...obj },
        };
      } else {
        return {
          ...state,
          kioskSettings: {
            ...state.kioskSettings,
            ...response,
            syncdb:
              state?.kioskSettings?.['maestroData']?.['posData']?.['syncdb'] ??
              response?.syncdb,
            printerData: {
              printConfig: response?.printConfig,
              kAgent: response?.kAgent,
              isPrinterPolingEnabled:
                response?.kioskUISettings?.miscellaneous?.printerPollingEnabled,
            },
          },
        };
      }
    } else {
      return {
        ...state,
      };
    }
  }),

  on(KioskActions.getKioskSettingsFailure, (state, error) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(KioskActions.removeTimeSlotsToSelectedProductsSuccess, (state) => {
    return {
      ...state,
      kioskStoreUpdated: { updated: true },
    };
  }),

  on(KioskActions.addStore, (state, { isAddStoreSidebarOpen }) => {
    return {
      ...state,
      addStoreSidebarBoolean: isAddStoreSidebarOpen,
    };
  }),

  on(KioskActions.getCategoriesSuccess, (state, { availableCategories }) => {
    const extractedProducts =
      extractProductDataFromCategory(availableCategories);
    const availableCategoriesCopy = availableCategories.filter(
      (res) => res?.productsCount !== 0
    );
    const { cartItems, itemQty, availableProducts } = setAvaialableProducts(
      extractedProducts,
      state.cartItems,
      state.itemQty
    );

    return {
      ...state,
      status: 'Success',
      error: '',
      availableCategories: availableCategoriesCopy,
      availableProducts,
      cartItems,
      itemQty,
    };
  }),
  on(KioskActions.getCategoriesFailure, (state, error) => {
    return {
      ...state,
      error: error.err,
      status: 'Error',
    };
  }),
  on(KioskActions.incrementQtyViaProduct, (state, { product, isTable }) => {
    const { cartItems, availableProducts, itemQty, cartLen } =
      incrementQtyViaProduct(
        product,
        state.cartItems,
        state.availableProducts,
        state.itemQty,
        state.cartLen
      );

    return {
      ...state,
      availableProducts,
      cartItems: isTable ? state.cartItems : cartItems,
      itemQty,
      cartLen: isTable ? state.cartLen : cartLen,
    };
  }),
  on(KioskActions.decrementQtyViaProduct, (state, { product, isTable }) => {
    const upDatedData = decrementQtyViaProduct(
      product,
      state.cartItems,
      state.availableProducts,
      state.itemQty,
      state.cartLen
    );
    let cartLen = state.cartLen;
    if (typeof upDatedData?.cartLen === 'number') {
      cartLen = upDatedData.cartLen;
    }

    return {
      ...state,
      availableProducts:
        upDatedData?.availableProducts || state.availableProducts,
      cartItems: isTable
        ? state.cartItems
        : upDatedData?.cartItems || state.cartItems,
      itemQty: upDatedData?.itemQty || state.itemQty,
      cartLen: isTable ? state.cartLen : cartLen,
    };
  }),
  on(KioskActions.updateCartItems, (state, { products }) => {
    const upDatedData = updateCartValue(
      products,
      state.cartItems,
      state.availableProducts,
      state.itemQty,
      state.cartLen
    );
    let cartLen = state.cartLen;
    if (typeof upDatedData?.cartLen === 'number') {
      cartLen = upDatedData.cartLen;
    }

    return {
      ...state,
      availableProducts:
        upDatedData?.availableProducts || state.availableProducts,
      cartItems: upDatedData?.cartItems || state.cartItems,
      itemQty: upDatedData?.itemQty || state.itemQty,
      cartLen,
    };
  }),
  on(KioskActions.removeCartItem, (state, { _id, isTable }) => {
    const { cartItems, itemQty, cartLen, availableProducts } = removeCartItem(
      _id,
      state.cartItems,
      state.availableProducts,
      state.itemQty,
      state.cartLen
    );

    return {
      ...state,
      cartItems: isTable ? state.cartItems : cartItems,
      availableProducts,
      itemQty: isTable ? state.itemQty : itemQty,
      cartLen: isTable ? state.cartLen : cartLen,
    };
  }),
  on(KioskActions.resetCartValues, (state) => {
    const itemQtyInitial = new Map<
      string,
      {
        qty: number;
        idx: number;
      }
    >();
    const availableProducts = state.availableProducts.map((v) => {
      if (v.qty > 0) {
        const copy = { ...v };
        copy.qty = 0;
        return copy;
      } else {
        return v;
      }
    });

    const updatedMap = state.itemQty.size ? state.itemQty : itemQtyInitial;
    for (const [_, value] of updatedMap) {
      value.qty = 0;
      value.idx = -1;
    }
    return {
      ...state,
      cartItems: [],
      availableProducts,
      itemQty: updatedMap,
      cartLen: 0,
    };
  }),

  on(KioskActions.updateTableCart, (state, { prodId }) => {
    const newAvailableProducts = state.availableProducts.map((v) => {
      if (v?.productInfo?._id === prodId) {
        const copyValue = { ...v };
        copyValue.qty = 0;
        return copyValue;
      }
      return v;
    });

    return {
      ...state,
      availableProducts: newAvailableProducts,
    };
  }),

  on(KioskActions.quantityCheckSuccess, (state, { quantityStatus }) => {
    return {
      ...state,
      status: 'Success',
      error: '',
      quantityStatus,
    };
  }),

  on(KioskActions.getMaestroAreasSuccess, (state, { areas }) => {
    return {
      ...state,
      maestroAreas: areas.reduce((acc, curr) => {
        acc.push(curr.assetDetails);
        return acc;
      }, []),
    };
  }),

  on(KioskActions.updateKitchenSentData, (state, { kitchenData }) => {
    const newKitchenData = structuredClone(kitchenData);
    for (let i = 0; i < newKitchenData?.length; i++) {
      for (let j = 0; j < newKitchenData[i]?.items?.length; j++) {
        for (let k = 0; k < state?.availableProducts?.length; k++) {
          if (
            state?.availableProducts?.[k]?.productInfo?._id ===
            newKitchenData?.[i]?.items?.[j]?._id
          ) {
            newKitchenData[i].items[j]['imageUrl'] =
              state?.availableProducts?.[k]?.imageURL;
          }
        }
        const isSuborderQuantityZero = newKitchenData[i]?.items?.every(
          (item) => item?.quantity === 0
        );
        newKitchenData[i]['isSuborderQuantity'] = isSuborderQuantityZero;
      }
    }

    return {
      ...state,
      kitchenSentData: newKitchenData,
    };
  }),

  on(KioskActions.quantityCheckDataReset, (state) => {
    return {
      ...state,
      quantityStatus: {
        status: null,
        statusMsg: '',
      },
    };
  }),
  on(KioskActions.quantityCheckFailure, (state, error) => {
    return {
      ...state,
      error: error.err,
      status: 'Error',
    };
  }),
  on(KioskActions.getProductGroupsSuccess, (state, { productGroups }) => {
    return {
      ...state,
      status: 'Success',
      error: '',
      productGroups,
    };
  }),
  on(KioskActions.getProductGroupsFailure, (state, error) => {
    return {
      ...state,
      error: error.err,
      status: 'Error',
    };
  }),
  on(KioskActions.showLoaderForGenericSettings, (state, { val }) => {
    return {
      ...state,
      genericSettingUpdateLoader: val,
    };
  }),
  on(KioskActions.getKioskSettingsMetadata, (state) => {
    return {
      ...state,
      genericSettingsByGroupLoader: true,
    };
  }),

  on(
    KioskActions.setKioskSettingsMetadata,
    (state, { kioskMetaData, groupType }) => {
      return {
        ...state,
        kioskSettingsMetaData: {
          ...state.kioskSettingsMetaData,
          [groupType]: kioskMetaData,
        },
        genericSettingsByGroupLoader: false,
      };
    }
  ),
  on(KioskActions.getAllGroupsForKioskSettings, (state) => {
    return {
      ...state,
      genericSettingUpdateLoader: true,
    };
  }),
  on(KioskActions.getAllGroupsForKioskSettingsSuccess, (state, { res }) => {
    return {
      ...state,
      genericSettingUpdateLoader: false,
      allGroupsNamesSetttingForKiosk: res,
    };
  }),

  on(KioskActions.putGenericKioskSettings, (state) => {
    return {
      ...state,
      genericSettingUpdateLoader: true,
    };
  }),

  on(
    KioskActions.putGenericKioskSettingsSuccess,
    (state, { res, moduleType }) => {
      return {
        ...state,
        kioskSettings: {
          ...state.kioskSettings,
          [moduleType]: res,
        },
        genericSettingUpdateLoader: false,
      };
    }
  ),
  on(KioskActions.errorHandlerForGenericKioskSettings, (state) => {
    return {
      ...state,
      genericSettingUpdateLoader: false,
    };
  }),

  on(KioskActions.getAllPaymentGatewayListSucces, (state, { response }) => {
    return {
      ...state,
      payamentGatewayList: response,
    };
  }),
  on(KioskActions.sendPaymentVerificationSucess, (state, { res }) => {
    const status = {
      status: 'success',
      processingDone: true,
      data: res,
    };
    return {
      ...state,
      verificationProcess: status,
    };
  }),
  on(KioskActions.resetVerifcation, (state) => {
    return {
      ...state,
      verificationProcess: {
        processingDone: false,
        status: '',
      },
    };
  }),
  // on(KioskActions.getRazorpayDataSuccess, (state, {
  //   razorpay,
  //   account
  // }) => {
  //   return {
  //     ...state,
  //     razorpayData: razorpay,
  //     razorpayConfig: buildRazorPayConfig(razorpay, state.razorpayConfig, account)
  //   }
  // }),
  on(KioskActions.getAllKagnetsSuccess, (state, { res }) => {
    return {
      ...state,
      kAgents: getKAgentWithOnlineStatus(res),
    };
  }),

  on(
    KioskActions.updateLiveStatusOfKAgent,
    (state, { machineId, lastStatusUpdate }) => {
      return {
        ...state,
        kAgents: updateKAgentStatusAfterSocketPush(
          machineId,
          lastStatusUpdate,
          state.kAgents
        ),
      };
    }
  ),

  on(KioskActions.getMaestroInfoSuccess, (state, { maestroInfo }) => {
    return {
      ...state,
      maestroData: maestroInfo,
      kioskSettings: {
        ...state.kioskSettings,
        syncdb: {
          ...state?.kioskSettings?.syncdb,
          ...maestroInfo?.posData?.syncdb,
        },
        printerData: {
          ...state?.kioskSettings?.printerData,
          counterPrinting: maestroInfo?.posData?.counterPrinting,
        },
      },
    };
  }),

  on(KioskActions.linkKioskAgentProcessSuccess, (state) => {
    return {
      ...state,
      kAgentLoader: false,
    };
  }),

  on(KioskActions.linkKioskAgent, (state) => {
    return {
      ...state,
      kAgentLoader: true,
    };
  }),

  on(KioskActions.delinkKioskAgent, (state) => {
    return {
      ...state,
      kAgentLoader: true,
    };
  }),

  on(KioskActions.linkKioskAgentProcessFailure, (state) => {
    return {
      ...state,
      kAgentLoader: false,
    };
  }),

  on(KioskActions.kAgentTabAPiFailure, (state) => {
    return {
      ...state,
      kAgentLoader: false,
    };
  }),

  on(KioskActions.filledStateWithTableData, (state, { tableData }) => {
    const cartItems = [];

    let idx = 0;
    for (let i = 0; i < tableData?.length; i++) {
      for (let j = 0; j < tableData?.[i]?.items?.length; j++) {
        const prod = {
          metaInfo: {
            itemName: tableData?.[i]?.items?.[j]?.name,
            price: tableData?.[i]?.items[j]?.price,
          },
          productInfo: {
            _id: tableData?.[i]?.items[j]?._id,
          },
          imageURL: tableData?.[i]?.items[j]?.imageUrl,
          qty: tableData?.[i]?.items[j]?.quantity,
          idx: idx,
        };
        cartItems.push(prod);
        idx++;
      }
    }

    return {
      ...state,
      cartItems: cartItems,
      cartLen: cartItems?.length,
    };
  }),

  on(KioskActions.getAvailableKioskSuccess, (state, { kioksData }) => {
    return {
      ...state,
      kioskData: kioksData,
    };
  }),

  on(KioskActions.changeTheMachinename, (state) => {
    return {
      ...state,
      kAgentLoader: true,
    };
  }),

  on(KioskActions.changeTheMachinenameSuccess, (state) => {
    return {
      ...state,
      kAgentLoader: false,
    };
  }),
  on(KioskActions.setKioskKAgentMetadata, (state, { kioskKAgentMetaData }) => {
    return {
      ...state,
      kAgentMetadata: kioskKAgentMetaData,
    };
  }),
  on(KioskActions.getLayoutKioskOrderPageSuccess, (state, { response }) => {
    return {
      ...state,
      kioskSettings: {
        ...state.kioskSettings,
        orderPageLayout: response,
      },
    };
  }),
  on(
    KioskActions.checkIfUserShiftInProgressSuccess,
    (state, { status, created }) => {
      return {
        ...state,
        userShift: {
          ...state.userShift,
          status: status,
          currentShiftData: created,
        },
      };
    }
  ),
  on(KioskActions.getUserAllShiftSucess, (state, { shifts }) => {
    return {
      ...state,
      userShift: {
        ...state.userShift,
        allShifts: shifts,
      },
    };
  }),
  on(KioskActions.getReceiptTemplateSuccess, (state, { template }) => {
    const temp = {
      ...template,
      templates: [...template?.templates],
      assetsInfo: [...template?.assetsInfo],
    };
    const printerStatus = {
      status: {},
      allConnected: false,
    };
    for (let i = 0; i < temp.assetsInfo.length; i++) {
      const a = temp.assetsInfo[i];
      let key = a.ipaddress;
      const pc = state?.kioskSettings?.printConfig;
      if (
        pc?.type === PRINT.TYPE.USB ||
        (pc?.type === PRINT.TYPE.ZEN_HB && pc?.mode?.toLowerCase() === 'usb')
      ) {
        key = a.serialNumber;
      }

      printerStatus.status[key] = {
        isConnected: false,
        event: 'Connecting',
        type: state?.kioskSettings?.printConfig?.type,
      };
      const ObjKey: string[] = Object.keys(temp.assetsInfo[i]);
      const isPrinterPolingEnabled =
        state?.kioskSettings?.kioskUISettings?.miscellaneous
          ?.printerPollingEnabled;
      temp.assetsInfo[i] = {
        ...temp.assetsInfo[i],
        isPrinterPollingEnabled: ObjKey.includes('printerPollingEnabled')
          ? temp.assetsInfo[i]?.printerPollingEnabled
          : isPrinterPolingEnabled || false,
      };
    }
    return {
      ...state,
      receiptTemplate: temp,
      printerStatus,
    };
  }),
  on(KioskActions.getMaestroTableSuccess, (state, { maestroTable }) => {
    return {
      ...state,
      maestroTable: maestroTable,
    };
  }),
  on(KioskActions.setPrinterStatus, (state, res) => {
    const status = res?.status;

    let s: Record<string, IStatus> = {};
    if (status && state?.printerStatus?.status) {
      s = { ...state.printerStatus.status };
      for (const k in state.printerStatus.status) {
        if (status?.[k]) {
          s[k] = status[k];
        }
      }
    }

    const allConnected = Object.values(s)?.every(
      (o) => o?.['isConnected'] === true
    );
    return {
      ...state,
      printerStatus: {
        status: { ...state.printerStatus.status, ...s },
        allConnected: allConnected,
        type: state?.kioskSettings?.printConfig?.type,
      },
    };
  }),

  on(KioskActions.updatePrinterStatus, (state, { status }) => {
    return {
      ...state,
      printerStatus: status,
    };
  }),

  on(KioskActions.setPrinterOffline, (state) => {
    if (state?.printerStatus) {
      const updateOffline = setOfflineStatus({ ...state?.printerStatus });
      return {
        ...state,
        printerStatus: updateOffline,
      };
    } else {
      return {
        ...state,
        printerStatus: { ...state?.printerStatus },
      };
    }
  }),

  on(KioskActions.updateSyncDbUrl, (state, { url, userId, userPassword }) => {
    return {
      ...state,
      kioskSettings: {
        ...state.kioskSettings,
        syncdb: {
          ...state.kioskSettings.syncdb,
          url: url,
          userid: userId,
          password: userPassword,
        },
      },
    };
  }),
  on(KioskActions.setSwitchTable, (state, { isswitchTable }) => {
    return {
      ...state,
      isSwitchTable: isswitchTable,
    };
  }),
  on(KioskActions.setAdditionalDineType, (state, { data }) => {
    return {
      ...state,
      additionalDinetype: data,
    };
  }),
  on(KioskActions.loadCounterDataSuccess, (state, { data }) => {
    return {
      ...state,
      kioskSettings: {
        ...state.kioskSettings,
        printerData: {
          ...state?.kioskSettings?.printerData,
          counterData: data,
        },
      },
    };
  })
);

function setOfflineStatus(inputObject) {
  const obj = { ...inputObject, status: { ...inputObject?.status } };

  if (inputObject.hasOwnProperty('status')) {
    for (const ipAddress in obj.status) {
      if (obj.status.hasOwnProperty(ipAddress)) {
        obj.status[ipAddress] = {
          ...obj.status[ipAddress],
          isConnected: false,
          event: 'Offline',
        };
      }
    }
  }

  obj.allConnected = false;
  return obj;
}

// function buildRazorPayConfig(razorpayData: RazorPay, razorpayConfig: RazorPayConfig, accountDetails: Account) {
//   const rzpThemeColor = getComputedStyle(
//     document.documentElement
//   ).getPropertyValue("--primary-color");

//   const config: RazorPayConfig = {...razorpayConfig}
//   config.key = razorpayData?.apiKey;
//   config.notes = {
//     ...config.notes,
//     customerId: razorpayData?.customer_id ?? null
//   };
//   config.notes = {
//     ...config.notes,
//     linkedAccount: razorpayData?.linkedAccount ?? null
//   };
//   config.name = accountDetails?.name ?? '';

//   if (accountDetails?.["images"]?.logo?.razorpayDisplayUrl) {
//     config.image = accountDetails?.["images"]?.logo?.razorpayDisplayUrl;
//   } else {
//     config.image = "data:image/png;base64," + accountDetails?.["images"]?.logo?.data;
//   }

//   config.theme = {
//     ...config.theme,
//     color: rzpThemeColor ?? '#b1812d'
//   };
//   return config
// }

export function moveElement(arr: any[], from: number, to: number): any[] {
  const element = orderByField(arr, 'sequence').splice(from, 1)[0];
  arr.splice(to, 0, element);
  return setReArrangedSequence(arr);
}

function orderByField(array: unknown[], field: string): unknown[] {
  return array.sort((a, b) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  });
}

export function setReArrangedSequence(data) {
  const cpData = data.map((c, index) => {
    const cp = {
      ...c,
      sequence: index + 1,
    };

    return cp;
  });
  return cpData;
}
