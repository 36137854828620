import {isDevMode} from "@angular/core";

export function ExecTime(tag = "tag"): MethodDecorator {
  return (_target: object, _propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const targetMethod = descriptor.value;
    descriptor.value = function (...args) {
      if (isDevMode()) {
        const startTime = performance.now();
        const result = targetMethod.apply(this, args);
        const endTime = performance.now();
        const timespan = endTime - startTime;

        console.log('EXEC TIME:', _propertyKey, tag, timespan, 'ms');

        return result;
      }

      return targetMethod.apply(this, args);
    };

    return descriptor;
  };
}
