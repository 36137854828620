import {SwUpdate} from "@angular/service-worker";
import {inject, Injectable, NgZone} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../state/app.state";
import {updateVerisonAvailability} from "../state/account/account.action";

@Injectable({providedIn: 'root'})
export class PwaUpdateService {
  private swUpdate = inject(SwUpdate);
  private zone = inject(NgZone);
  private store = inject(Store<AppState>);


  checkUpdate() {
    const is = this.swUpdate.isEnabled;
    console.log("Check Update", is);
    this.zone.runOutsideAngular(async () => {
      try {
        if (!is) {
          return;
        }
        console.log('checkForUpdate');
        this.swUpdate.unrecoverable.subscribe(r => {
          console.log("unrecoverable", r.type, r.reason);
        });

        const isNewVersionAvail = await this.swUpdate.checkForUpdate();
        console.log('checkForUpdate->isNewVersionAvail', isNewVersionAvail);
        if (isNewVersionAvail) {
          this.store.dispatch(updateVerisonAvailability({update: true}));
        } else {
          this.store.dispatch(updateVerisonAvailability({update: false}));
        }
      } catch (e) {
        this.store.dispatch(updateVerisonAvailability({update: false}));
        console.error(e);
      }
    }).then(console.log).catch(console.error);
  }

}
