import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class WebWorkersService {
  private worker: Worker;
  private sharedWorker: SharedWorker;
  ports: Record<string, MessagePort> = {};
  callbacks: Record<string, webWorkMessageFn[]> = {};

  constructor() {
    this.worker = new Worker(new URL('./app.worker', import.meta.url), {type: "module"});
    // this.sharedWorker = new SharedWorker(new URL('./app.worker', import.meta.url), { type: "module" });

    this.worker.onmessage = (data) => {
      if (this.callbacks[data.data.event]) {
        this.callbacks[data.data.event].forEach((cb) => {
          cb(data.data);
        });
      }
    }
  }

  getSharedWorker(key: string) {
    if (!this.ports[key]) {
      this.ports[key] = this.sharedWorker.port;
    }

    return this.ports[key];
  }

  emit(event: string, data: {event: string, data: any}) {
    return this.worker.postMessage({ event, data });
  }

  on(event: string, cb: webWorkMessageFn) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }

    this.callbacks[event].push(cb);
  }
}


export type webWorkMessageFn = (data: { event: string, result: any, error?: Error }) => void;
