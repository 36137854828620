import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, from, EMPTY, forkJoin} from 'rxjs';
import {switchMap, map, catchError, tap, withLatestFrom, mergeMap} from 'rxjs/operators';
import {Store,} from '@ngrx/store';
import {AppState} from '../app.state';
import {KioskActions} from '.';
import {
  ICategoryData,
  // IKioskCategory,
  IGroupData,
  IQuantityStatus,
  PosData,
  GatewayList,
  IKisoskSettingsMetaData,
  IUserShift,
  ICounterData
} from 'src/app/interface/maestro-interface';
import {sequencedCategories, setCategoryImage} from './kiosk.reducer.helper'
import {getAllKioskSettingsMetaData} from './kiosk.selector';
import {KioskStoreService} from 'src/app/services/kiosk.store.service';


@Injectable()
export class KioskEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private API: KioskStoreService,
  ) {
  }

  loadBranches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.loadBranchList),
      switchMap(() => {
        return from(this.API.getStoreList()).pipe(
          map((res) => {
            return KioskActions.loadBranchListSuccess({branchList: res})
          }),
          catchError((error) => of(KioskActions.loadBranchListFailure({err: error})))
        )
      })
    )
  );

  getMaestroAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getMaestroAreas),
      switchMap(() => {
        return from(this.API.getMaestroAreas()).pipe(
          map((res) => {
            return KioskActions.getMaestroAreasSuccess({areas: res})
          })
        )
      })
    )
  );

  getMaestroInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getMaestroInfo),
      switchMap(() =>
        from(this.API.getMaestroInfo()).pipe(
          switchMap((res: PosData) => {
            return [
              KioskActions.getMaestroInfoSuccess({maestroInfo: res}),
              KioskActions.getReceiptTemplate({ posId: res?.posId }),
              KioskActions.getKioskSettingsByModule({ settingsType: 'All',terminalId: res?.posId })
            ]
          })
        )
      )
    )
  );

  getKioskSettingsByModule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getKioskSettingsByModule),
      switchMap((_) => {
        // if (stateData) {
        //   return of(KioskActions.getKioskSettingsSuccess({response: stateData}))
        // } else {
          return from(this.API.getKioskSettings(_?.terminalId).pipe(
            map((value) => KioskActions.getKioskSettingsSuccess({response: value})),
            catchError((error) => of(KioskActions.getKioskSettingsFailure({error: error})))
          ))
        // }
      })
    )
  )
   loadCounterData$ = createEffect(() =>
  this.actions$.pipe(
    ofType(KioskActions.loadCounterData),
    switchMap(() =>
      from(this.API.getCounterData()).pipe(
        switchMap((res: ICounterData[]) => {
          return [
            KioskActions.loadCounterDataSuccess({data: res}),
          ]
        })
      )
    )
  )
);


  getCategoriesAndProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getCategories),
      switchMap(() => {
        return from(this.API.getCategoriesAndProducts().pipe(
          map((res: ICategoryData[]) => {
            const sequencedCategoriesData = sequencedCategories(res)
            const categoriesWithImage = setCategoryImage(sequencedCategoriesData)
            return KioskActions.getCategoriesSuccess({availableCategories: categoriesWithImage})
          }),
          catchError((error) => of(KioskActions.getCategoriesFailure({err: error})))
        ))
      })))
  getLayoutKioskOrderPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getLayoutKioskOrderPage),
      switchMap(() => {
        return from(this.API.getKioskOrderPageLayout()).pipe(
          map((res: any) => KioskActions.getLayoutKioskOrderPageSuccess({response: res})),
          catchError((error) => of(KioskActions.getCategoriesFailure({err: error})))
        )
      })))
  addNewStoreForKioskUsingForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.addNewStoreForKioskUsingForm),
      switchMap((action) =>
        from(this.API.effectsAddNewStoreLocation(action.formData)).pipe(
          tap(() => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'success',
            //   summary: 'Updated',
            //   detail: 'Added store location successfully'
            // });
          }),
          switchMap(() => {
            return [
              KioskActions.loadBranchList(),
              // AssetsActions.loadAssetCountByType({ assetType: CONSTANT.ASSET_TYPES.LOCATION }),
              KioskActions.addStore({isAddStoreSidebarOpen: false}),
            ];
          }),
          catchError(() => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'error',
            //   summary: 'Error',
            //   detail: 'Failed to add new store location ',
            // });
            return of();
          })
        )
      )
    )
  );

  quantityCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.quantityCheck),
      switchMap((action) => {
        return from(this.API.quantityCheck(action.productArray, action.storeId)).pipe(
          map((res: IQuantityStatus) => {
            return KioskActions.quantityCheckSuccess({quantityStatus: res})
          }),
          catchError((error) => of(KioskActions.quantityCheckFailure({err: error})))
        )
      })
    )
  );

  getProductGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getProductGroups),
      switchMap(() => {
        return from(this.API.getProductGroups()).pipe(
          map((res: IGroupData[]) => {
            return KioskActions.getProductGroupsSuccess({productGroups: res})
          }),
          catchError((error) => of(KioskActions.getProductGroupsFailure({err: error})))
        )
      })
    )
  );
  getAllPaymentGateways$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getAllPaymentGatewayList),
      switchMap(() =>
        from(this.API.effectGetAllPaymentGateways().pipe(
          switchMap((value: GatewayList[]) => [KioskActions.getAllPaymentGatewayListSucces({response: value})]),
          catchError(() => {
            return []
          })
        ))
      )
    )
  );

  sendPaymentVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.sendPaymentVerification),
      switchMap((action) => {
        return from(this.API.effectSendPaymentVerification(action.orderId)).pipe(
          tap(() => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'success',
            //   summary: 'Updated',
            //   detail: 'Successfully Verified Payment'
            // });
          }), switchMap((res) => {
            return [KioskActions.sendPaymentVerificationSucess({
              res: {
                orderNo: res?.['orderId'],
                orderId: res?.['orderDocId']
              }
            })]
          }),
        )
      })
    )
  );


  printReceiptOnSok$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.printReceiptOnSok),
      switchMap((action) => {
        return from(this.API.effectPrintReceiptOnSOk(action.orderId)).pipe(
          tap((_res) => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'success',
            //   summary: 'Updated',
            //   detail: res?.['message']
            // });
          }), switchMap(() => {
            return []
          }),
          catchError(() => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'error',
            //   summary: 'Error',
            //   detail: 'Failed to print receipt on sokc'
            // })
            return []
          })
        )
      })
    )
  );


  sendTrasactionProofImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.sendTrasactionProofImage),
      switchMap((action) =>
        from(this.API.effectSendTransaction(action.payload, action.orderId)).pipe(
          tap(() => {
            // this.messageService.add({ key: 'global-notification', severity: 'success', summary: 'Updated', detail: res?.['msg'] ? res?.['msg'] : res?.['message'] });
          }),
          switchMap((res) => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'success',
            //   summary: 'Updated',
            //   detail: res?.['msg'] ? res?.['msg'] : res?.['message']
            // });
            return [KioskActions.sendPaymentVerificationSucess({
              res: {
                orderNo: res?.['orderId'],
                orderId: res?.['orderDocId']
              }
            }), KioskActions.sendTrasactionProofImageSuccess()]
          }), // Dispatch the success action
          catchError(() => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'error',
            //   summary: 'Error',
            //   detail: 'Failed to print receipt on socket'
            // });
            return EMPTY; // Optionally, dispatch an error action
          })
        )
      ),
      map(() => ({type: 'NO_ACTION'}))
    )
  );

  effectGetKioskSettingsMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getKioskSettingsMetadata),
      withLatestFrom(this.store.select(getAllKioskSettingsMetaData)),
      switchMap(([action, stateData]) => {
        if (stateData && stateData[action.groupType]) {
          return of(KioskActions.setKioskSettingsMetadata({
            kioskMetaData: stateData[action.groupType],
            groupType: action.groupType
          }));
        } else {
          return from(this.API.getKioskSettingsMetaData(action.groupType)).pipe(
            switchMap((res: IKisoskSettingsMetaData) => {
              const dynamicDropDownObservables = [];

              // Finding if Is Dynamic is true anywhere in Metadata response/
              res?.headers?.forEach((el) => {
                res[el]?.actions?.forEach((e, id) => {
                  if (e?.type === 'dropDown' && e?.isDynamic) {
                    dynamicDropDownObservables.push(
                      this.API.dynamicAPIFromMetaData(e.apiDetails.url, e.apiDetails.method).pipe(
                        catchError(() => []),
                        mergeMap(ress => {
                          // Setting the value according to api res
                          res[el].actions[id].dropDownValues = [...ress];
                          return [KioskActions.setKioskSettingsMetadata({
                            kioskMetaData: res,
                            groupType: action.groupType
                          })];
                        })
                      )
                    );
                  }
                  if (e?.data) {
                    const accordianData = e?.data
                    accordianData?.map((ele, idx) => {
                      if (ele?.subActions) {
                        const subaction = ele?.subActions
                        subaction?.map((val: any, i) => {
                          if (val.type === 'dropDown' && val?.isDynamic) {
                            dynamicDropDownObservables.push(
                              this.API.dynamicAPIFromMetaData(val.apiDetails.url, val.apiDetails.method).pipe(
                                catchError(() => []),
                                mergeMap(ress => {
                                  // Setting the value according to api res
                                  res[el].actions[id].data[idx].subActions[i].dropDownValues = [...ress]
                                  return [KioskActions.setKioskSettingsMetadata({
                                    kioskMetaData: res,
                                    groupType: action.groupType
                                  })];
                                })
                              )
                            );
                          }
                        })
                      }
                    })
                  }
                });
              });
              if (dynamicDropDownObservables.length === 0) {
                return [res]
              } else {
                return forkJoin(dynamicDropDownObservables).pipe(
                  map(() => {
                    return res
                  }),
                  catchError(() => []),
                );

              }
            }),
            switchMap((res: IKisoskSettingsMetaData) => {
              return [KioskActions.setKioskSettingsMetadata({
                kioskMetaData: res,
                groupType: action.groupType
              })]
            }),
            catchError(() => of(KioskActions.showLoaderForGenericSettings({val: false})))
          );
        }
      })
    )
  );

  effectGetGenericKioskSettingsByGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getAllGroupsForKioskSettings),
      switchMap(() =>
        from(this.API.getKioskSettingsMetaDataByGroups().pipe(
          map((value) =>
            KioskActions.getAllGroupsForKioskSettingsSuccess({res: value?.settings})),
          catchError(() => [KioskActions.errorHandlerForGenericKioskSettings()])
        ))
      )
    )
  )


  effectPutGenericKioskSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.putGenericKioskSettings),
      switchMap((action) =>
        from(this.API.putKioskGenericSettings(action.payload, action.moduleType).pipe(
          tap(() => {
            // this.messageService.add({
            //   key: 'global-notification',
            //   severity: 'success',
            //   summary: 'Successfully Updated',
            //   detail: `Kiosk ${action.moduleType}  Settings`
            // });
          }, (error) => {
            console.log("Error Occured!", error)
            // this.messageService.add({key: 'global-notification', severity: 'error', summary: 'Something Went Wrong!'});
          }),
          map(() =>
            // console.log(value),
            KioskActions.putGenericKioskSettingsSuccess({
              res: action.payload,
              moduleType: action.moduleType
            })),
          catchError(() => [KioskActions.errorHandlerForGenericKioskSettings()])
        ))
      )
    )
  )

  getAllKagnets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getAllKagnets),
      switchMap(() => {
        return from(this.API.getKAgents()).pipe(
          map((res) => {
            return KioskActions.getAllKagnetsSuccess({res: res})
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  );


  rebootKioskAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.kioskAgentReboot),
      switchMap((action) => {
        return from(this.API.rebootKioskAgent(action.machineId, action.rebootTo)).pipe(
          map(() => {
            return KioskActions.kioskAgentRebootSuccess()
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  );

  delinkKioskAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.delinkKioskAgent),
      switchMap((action) => {
        return from(this.API.delinkKioskAgent(action.kAgentId)).pipe(
          mergeMap(() => {
            return [KioskActions.linkKioskAgentProcessSuccess(), KioskActions.getAllKagnets()]
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  );

  getAvailableKiosk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getAvailableKiosk),
      switchMap(() => {
        return from(this.API.getKioskList()).pipe(
          map((res) => {
            return KioskActions.getAvailableKioskSuccess({kioksData: res})
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  );


  linkKiosk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.linkKioskAgent),
      switchMap((action) => {
        return from(this.API.linkKiosk(action.kAgentId, action.kioskId)).pipe(
          mergeMap(() => {
            return [KioskActions.linkKioskAgentProcessSuccess(), KioskActions.getAllKagnets()]
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  );

  changeTheMachineName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.changeTheMachinename),
      switchMap((action) => {
        return from(this.API.changeMachineName(action.kAgentId, action.machineName)).pipe(
          mergeMap(() => {
            return [KioskActions.changeTheMachinenameSuccess(), KioskActions.getAllKagnets()]
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  )

  getKioskKAgentMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getKioskKAgentMetadata),
      switchMap((action) => {
        return from(this.API.getKAgentMetaData(action.module, action.typeId)).pipe(
          map((res: IKisoskSettingsMetaData[]) => {
            return KioskActions.setKioskKAgentMetadata({
              kioskKAgentMetaData: res[0],
              typeId: action.typeId
            })
          }),
          catchError(() => of(KioskActions.kAgentTabAPiFailure()))
        )
      })
    )
  )
  checkIfUserShiftInProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.checkIfUserShiftInProgress),
      switchMap(() => {
        return from(this.API.checkIfUserShiftInProgress()).pipe(
          map((res: any) => {
            return KioskActions.checkIfUserShiftInProgressSuccess({
              status: res?.status,
              created: res?.startDate
            })
          }),
          catchError(() => [])
        )
      })
    ))

  getUserAllShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getUserAllShift),
      switchMap((action) => {
        return from(this.API.getUserAllShifts(action.startDate, action.endDate)).pipe(
          map((res: IUserShift[]) => {
            return KioskActions.getUserAllShiftSucess({shifts: res})
          }),
          catchError(() => [])
        )
      })
    ))

  addUserShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.addUserShift),
      switchMap(() => {
        return from(this.API.addUserShift()).pipe(
          map((res: IUserShift) => {
            return KioskActions.checkIfUserShiftInProgressSuccess({
              status: res?.inProgress,
              created: res?.startDate
            })
          }),
          catchError(() => [])
        )
      })
    ))

  endUserShift$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.endUserShift),
      switchMap(() => {
        return from(this.API.endUserShift()).pipe(
          map((res: IUserShift) => {
            return KioskActions.checkIfUserShiftInProgressSuccess({
              status: res?.inProgress,
              created: res?.startDate
            })
          }),
          catchError(() => [])
        )
      })
    ))
  getRecieptTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getReceiptTemplate),
      switchMap((action) => {
        return from(this.API.getReceiptTemplate(action.posId)).pipe(
          map((res) => {
            return KioskActions.getReceiptTemplateSuccess({template: res})
          }),
          catchError(() => [])
        )
      })
    ))

  getMaestroTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KioskActions.getMaestroTable),
      switchMap((action) => {
        return from(this.API.getMaestroTableWithAreaId(action.areaId, action.status).pipe(
          switchMap(res => {
            return [KioskActions.getMaestroTableSuccess({
              maestroTable: {
                data: res,
                remote: action.remote
              }
            })]
          })
        ))
      }))
  )
}
