class t {
  constructor(t, e) {
    this.operator = t, this.value = e, Object.defineProperty(this, "t", {
      writable: !0
    });
  }
  get notes() {
    return this.t;
  }
  addNote(t) {
    this.t = this.t || [], this.t.push(t);
  }
}
class e extends t {}
class r extends e {
  constructor(t, e) {
    if (!Array.isArray(e)) throw new Error(`"${t}" operator expects to receive an array of conditions`);
    super(t, e);
  }
}
const n = "__itself__";
class o extends t {
  constructor(t, e, r) {
    super(t, r), this.field = e;
  }
}
const s = new e("__null__", null),
  i = Object.prototype.hasOwnProperty.call.bind(Object.prototype.hasOwnProperty);
function c(t, e) {
  return e instanceof r && e.operator === t;
}
function u(t, e) {
  return 1 === e.length ? e[0] : new r(t, function t(e, r, n) {
    const o = n || [];
    for (let n = 0, s = r.length; n < s; n++) {
      const s = r[n];
      c(e, s) ? t(e, s.value, o) : o.push(s);
    }
    return o;
  }(t, e));
}
const a = t => t,
  h = () => Object.create(null),
  f = Object.defineProperty(h(), "__@type@__", {
    value: "ignore value"
  });
function l(t, e, r = !1) {
  if (!t || t && t.constructor !== Object) return !1;
  for (const n in t) {
    if (i(t, n) && i(e, n) && (!r || t[n] !== f)) return !0;
  }
  return !1;
}
function d(t) {
  const e = [];
  for (const r in t) i(t, r) && t[r] !== f && e.push(r);
  return e;
}
function p(t, e) {
  e !== s && t.push(e);
}
const w = t => u("and", t),
  b = t => u("or", t),
  O = {
    compound(t, e, n) {
      const o = (Array.isArray(e) ? e : [e]).map(t => n.parse(t));
      return new r(t.name, o);
    },
    field: (t, e, r) => new o(t.name, r.field, e),
    document: (t, r) => new e(t.name, r)
  };
class j {
  constructor(t, e = h()) {
    this.o = void 0, this.s = void 0, this.i = void 0, this.u = void 0, this.h = void 0, this.parse = this.parse.bind(this), this.u = {
      operatorToConditionName: e.operatorToConditionName || a,
      defaultOperatorName: e.defaultOperatorName || "eq",
      mergeFinalConditions: e.mergeFinalConditions || w
    }, this.o = Object.keys(t).reduce((e, r) => (e[r] = Object.assign({
      name: this.u.operatorToConditionName(r)
    }, t[r]), e), {}), this.s = Object.assign({}, e.fieldContext, {
      field: "",
      query: {},
      parse: this.parse,
      hasOperators: t => l(t, this.o, e.useIgnoreValue)
    }), this.i = Object.assign({}, e.documentContext, {
      parse: this.parse,
      query: {}
    }), this.h = e.useIgnoreValue ? d : Object.keys;
  }
  setParse(t) {
    this.parse = t, this.s.parse = t, this.i.parse = t;
  }
  parseField(t, e, r, n) {
    const o = this.o[e];
    if (!o) throw new Error(`Unsupported operator "${e}"`);
    if ("field" !== o.type) throw new Error(`Unexpected ${o.type} operator "${e}" at field level`);
    return this.s.field = t, this.s.query = n, this.parseInstruction(o, r, this.s);
  }
  parseInstruction(t, e, r) {
    "function" == typeof t.validate && t.validate(t, e);
    return (t.parse || O[t.type])(t, e, r);
  }
  parseFieldOperators(t, e) {
    const r = [],
      n = this.h(e);
    for (let o = 0, s = n.length; o < s; o++) {
      const s = n[o];
      if (!this.o[s]) throw new Error(`Field query for "${t}" may contain only operators or a plain object as a value`);
      p(r, this.parseField(t, s, e[s], e));
    }
    return r;
  }
  parse(t) {
    const e = [],
      r = this.h(t);
    this.i.query = t;
    for (let n = 0, o = r.length; n < o; n++) {
      const o = r[n],
        s = t[o],
        i = this.o[o];
      if (i) {
        if ("document" !== i.type && "compound" !== i.type) throw new Error(`Cannot use parsing instruction for operator "${o}" in "document" context as it is supposed to be used in  "${i.type}" context`);
        p(e, this.parseInstruction(i, s, this.i));
      } else this.s.hasOperators(s) ? e.push(...this.parseFieldOperators(o, s)) : p(e, this.parseField(o, this.u.defaultOperatorName, s, t));
    }
    return this.u.mergeFinalConditions(e);
  }
}
function _(t, e) {
  const r = t[e];
  if ("function" != typeof r) throw new Error(`Unable to interpret "${e}" condition. Did you forget to register interpreter for it?`);
  return r;
}
function y(t) {
  return t.operator;
}
function m(t, e) {
  const r = e,
    n = r && r.getInterpreterName || y;
  let o;
  switch (r ? r.numberOfArguments : 0) {
    case 1:
      o = e => {
        const o = n(e, r);
        return _(t, o)(e, s);
      };
      break;
    case 3:
      o = (e, o, i) => {
        const c = n(e, r);
        return _(t, c)(e, o, i, s);
      };
      break;
    default:
      o = (e, o) => {
        const i = n(e, r);
        return _(t, i)(e, o, s);
      };
  }
  const s = Object.assign({}, r, {
    interpret: o
  });
  return s.interpret;
}
function v(t, e) {
  return (r, ...n) => {
    const o = t(r, ...n),
      s = e.bind(null, o);
    return s.ast = o, s;
  };
}
const x = j.prototype.parseInstruction;
export { r as CompoundCondition, t as Condition, e as DocumentCondition, o as FieldCondition, n as ITSELF, s as NULL_CONDITION, j as ObjectQueryParser, w as buildAnd, b as buildOr, m as createInterpreter, v as createTranslatorFactory, O as defaultInstructionParsers, l as hasOperators, a as identity, f as ignoreValue, c as isCompound, h as object, u as optimizedCompoundCondition, x as parseInstruction };
