import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  time = 1000 * 2;

  constructor(private http: HttpClient, private configService: ConfigService) {}

  verifyUserDetails(userDetails: {
    username: string;
    password: string;
    language: string;
  }) {
    return this.http.post<LoginData>(
      `${this.configService.appConfig.baseURL}/authentication/login`,
      userDetails
    );
  }

  refreshToken() {
    return this.http.post<RefreshToken>(
      `${this.configService.appConfig.appBaseUrl}user/refreshToken`,
      { userId: localStorage.getItem('userId') }
    );
  }
}

export interface RefreshToken {
  token: string;
  expiry: number;
}

export interface IMiscellaneous {
  autoCartOpen: boolean;
  wallpaper: boolean;
  showInstructions: boolean;
  printerPollingEnabled: boolean;
  discardOrderOnCancellingPayment: boolean;
  maxQuantityAllowed: number;
  showPopular: boolean;
  customerFeedback: boolean;
  showAddons: boolean;
  default: string;
}

export interface LoginData {
  token: string;
  roles: string[];
  roleInfo: {
    _id: string;
    ability: any;
    landingPage: string;
    role: string;
    dashBoard: string;
    accountId: string;
    __v: number;
  };
  account: {
    _id: string;
    title: string;
    name: string;
    organization: string;
    address: string;
    assetTypes: string[];
    timezone: string;
    language: string;
    show: boolean;
    updated: string;
    created: string;
    __v: number;
    images: {
      logo: {
        data: string;
        contentType: string;
        url: string;
      };
    };
    authentication: {
      jwtExpiryTime: string;
    };
    kioskModule: {
      settings: {
        kioskUISettings: {
          dineType: {
            dineIn: boolean;
            dineOut: boolean;
          };
          environment: string;
          landingPage: {
            landingPage: string;
          };
          menu: {
            initialState: {
              status: string;
              accordianState: string;
              accordionState: string;
            };
          };
          miscellaneous: IMiscellaneous;
          theme: {
            isEditable: boolean;
            default: string;
            darkPalette: string;
            colors: {
              dark: {
                accent: string;
                background: string;
                primary: string;
                secondary: string;
              };
              light: {
                accent: string;
                background: string;
                primary: string;
                secondary: string;
              };
            };
            lightPalette: string;
          };
          timer: {
            payment: {
              value: number;
              unit: string;
            };
            popup: {
              value: number;
              unit: string;
            };
            global: {
              value: number;
              unit: string;
            };
          };
        };
        language: {
          alternateLanguage1: {
            label: string;
            id: string;
            order: number;
            default: boolean;
          };
          defaultLanguage: {
            label: string;
            id: string;
            order: number;
            default: boolean;
          };
          systemLanguages: any[];
        };
        payment: {
          gateway: string;
          kioskFront: {
            inAppPayment: boolean;
            payInStore: boolean;
            sendPaymentLink: boolean;
            tryDifferentMethods: boolean;
          };
          paymentModes: string[];
          storeFront: {
            inAppPayment: boolean;
            payInStore: boolean;
            sendPaymentLink: boolean;
            tryDifferentMethods: boolean;
          };
        };
        parcelCharge: {
          enabled: boolean;
          flatFee: {
            enabled: boolean;
            value: number;
          };
          productAndCategory: {
            enabled: boolean;
          };
        };
        autoPrint: {
          invoice: boolean;
          token: boolean;
          kitchen: boolean;
        };
        productPricing: {
          taxIncluded: boolean;
          roundingEnabled: boolean;
        };
        storeFrontUISettings: {
          dineType: {
            dineIn: boolean;
            dineOut: boolean;
          };
          timer: {
            payment: {
              value: number;
              unit: string;
            };
            popup: {
              value: number;
              unit: string;
            };
            global: {
              value: number;
              unit: string;
            };
          };
        };
        table: {
          action: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          amount: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          expectedDeliveryDateTimeDetails: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          items: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          orderId: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          orderSource: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          orderStatusDetails: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          orderType: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          paymentMode: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          paymentStatus: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          pickupCity: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          pickupLocation: {
            isEnabled: boolean;
            showDefault: boolean;
          };
          serviceTime: {
            isEnabled: boolean;
            showDefault: boolean;
          };
        };
        posUISettings: {
          dineType: {
            dineOut: boolean;
            table: boolean;
            dineIn: boolean;
          };
          isSocketEnabled: boolean;
          isWallpaper: boolean;
          layout: {
            isButtonVisibile: boolean;
            cart: string;
            category: string;
          };
          otherServicesEnabled: boolean;
        };
      };
    };
    geoSettings: {
      geoFence: {
        isTrackable: boolean;
      };
    };
    validity: {
      isLocked: boolean;
      lastLocked: boolean;
      lastUnlocked: boolean;
    };
    fssai: any;
    gstin: string;
    o2d: {
      settings: {
        order: {
          table: {
            fields: {
              id: string;
              label: string;
              showDefault: boolean;
              isEnabled: boolean;
            }[];
          };
        };
      };
    };
    modules: {
      _id: string;
      name: string;
    }[];
  };
  languageUpdate: string;
  accountLicenseInfo: {
    isAccountHasLicensing: boolean;
  };
  roleId: string;
}
