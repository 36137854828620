import {APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import {provideRouter, Router} from '@angular/router';
import {routes} from './app.routes';
import {provideStore} from '@ngrx/store';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import {ConfigService} from './services/config.service';
import {KioskReducer} from './state/kiosk/kiosk.reducer';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideEffects} from "@ngrx/effects";
import {KioskEffects} from "src/app/state/kiosk/kiosk.effects";
import {provideStoreDevtools} from "@ngrx/store-devtools";
import {authenticationInterceptor} from "../app/interceptors/httpInterceptor";
import {provideClientHydration, withNoHttpTransferCache} from "@angular/platform-browser";
import {AuthService} from './services/auth.service';
import {provideServiceWorker} from '@angular/service-worker';
import {CustomTranslateHttpLoader} from "src/app/services/custom.translate.http.loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {RxdbSyncService} from "src/sync/rxdb.sync.service";
import {DateFormatterService} from './services/dateformat';
import {accountEffects} from './state/account/account.effects';
import {AccountReducer} from './state/account/account.reducer';
import {MessageService} from "primeng/api";
import * as Sentry from "@sentry/angular";
import pk from 'package.json'
import {WebWorkersService} from "src/app/workers/web.workers.service";
import {PrintReceiptService} from "src/app/services/print-receipt.service";

export function HttpLoaderFactory(http: HttpClient, config: ConfigService) {
  return new CustomTranslateHttpLoader(http, config);
}

Sentry.init({
  dsn: "https://3eb5a4a9c71c958fcb495be773f66c0f@o1302573.ingest.sentry.io/4506615499194368",
  environment: window.location.host,
  enabled: !window.location.host.includes('localhost'),
  release: pk.version,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  integrations: [
    Sentry.httpContextIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.browserApiErrorsIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
});


export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(withNoHttpTransferCache()),
    provideRouter(routes),
    provideStore({kiosk: KioskReducer, account: AccountReducer}),
    provideEffects([KioskEffects, accountEffects]),
    provideStoreDevtools({
      name: pk.name,
      maxAge: 25,
      logOnly: isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideAnimations(),
    provideHttpClient(withInterceptors([authenticationInterceptor])),
    ConfigService,
    AuthService,
    TranslateService,
    RxdbSyncService,
    DateFormatterService,
    PrintReceiptService,
    MessageService,
    WebWorkersService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'
    }),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, ConfigService],
        },
      })
    ])
  ],
};

export const KeyboardLayout = [
  {
    default: [
      '1 2 3 4 5 6 7 8 9 0 = {bksp}',
      'q w e r t y u i o p [ ] \\',
      'a s d f g h j k l ; \'',
      'z x c v b n m , . /',
      '@ @gmail.com {space}'
    ],
    shift: [
      '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
      '{tab} Q W E R T Y U I O P { } |',
      '{lock} A S D F G H J K L : "',
      '{shift} Z X C V B N M &lt; &gt; ? {shift}',
      '.com @ {space}'
    ]
  },
  {
    default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
  },

];

export const KeyboardThemes = {
  numeric: 'hg-theme-default hg-layout-numeric numeric-theme',
};
