import {isDevMode} from "@angular/core";

export function LogArgs(tag = "tag"): MethodDecorator {
  return (_target: object, _propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const targetMethod = descriptor.value;
    descriptor.value = function (...args) {
      try {
        if (isDevMode()) {
          console.log('LOG:', _propertyKey, tag, ...args);
        }
      } catch (e) {
        console.error(e);
      }
      return targetMethod.apply(this, args);
    };

    return descriptor;
  };
}
