<div class="order-card-container" (click)="showOverlayCartPanel()"
  pTooltip="{{item['metaInfo']['itemName']}}" tooltipPosition="bottom">
  <p class="order-card-name">{{item['metaInfo']['itemName']}}</p>
  @if (SelectedViewValue === 'rich') {
    <img class="order-card-image" [ngClass]="{'gray-scale': item?.metaInfo?.outOfStockStatus}" [src]="item['imageURL']"
      height="168px" width="228px" alt="product image"
      onerror="src='https://livingstonbagel.com/wp-content/uploads/2016/11/food-placeholder.jpg'" />
  }
  <div class="flex minMax-12">
    <div class="inner-flex">
      <div class="card-bottom">
        <p class="card-price">₹
          {{
          selectedDineType ? (item?.['metaInfo']?.['prices']?.[selectedDineType] ||
          item?.['metaInfo']?.['price']) :item?.['metaInfo']?.['price']
          }}
        </p>
        @if (item?.metaInfo?.outOfStockStatus) {
          <div class="font-bold"> Out of stock</div>
        }
        <div class="btn-container" [ngStyle]="{'cursor': !item?.qty ? 'pointer' : ''}"
          (click)=" $event.stopPropagation();addbuttonClick(item)">
          @if (!item?.metaInfo?.outOfStockStatus && item.qty) {
            <i
            (click)="changeCartItems && decrementQty(item)" class="pi pi-minus dec-btn"></i>
          }
          <p [ngClass]="{'grayed-out': item?.metaInfo?.outOfStockStatus,'extra-padded':!item.qty}"
            class="qty">{{
            item.qty ? item.qty:
          'Add' }}</p>
          @if (!item?.metaInfo?.outOfStockStatus && item.qty) {
            <i
            (click)="changeCartItems && incrementQty(item)" class="pi pi-plus inc-btn"></i>
          }
        </div>
      </div>
    </div>
  </div>
</div>
