import {createSelector} from "@ngrx/store";
import {AppState} from "../app.state";

export const selectAccount = (state: AppState) => state.account;

export const getUserProfileDetails = createSelector(
  selectAccount,
  (userProfile) => userProfile.profile
)
export const username = createSelector(
  selectAccount,
  (userProfile) => userProfile?.profile?.username
)

export const getIsInternetAvailable = createSelector(
  selectAccount,
  (userProfile) => userProfile['isInternetAvailable']
)

export const syncing = createSelector(
  selectAccount,
  (userProfile) => userProfile.syncing
)

export const loadServerData = createSelector(
  selectAccount,
  (userProfile) => userProfile['transactionalData']?.server
)

export const getIsVersionUpdated = createSelector(
  selectAccount,
  (userProfile) => userProfile.versionUpdate
)
