
import { Component, EventEmitter, Input, Output } from '@angular/core';
import Keyboard from 'simple-keyboard';
import { KeyboardLayout, KeyboardThemes } from 'src/app/app.config';

@Component({
  selector: 'app-pin-keyboard',
  standalone: true,
  imports: [],
  templateUrl: './pin-keyboard.component.html',
  styleUrl: './pin-keyboard.component.scss'
})
export class PinKeyboardComponent {

  @Output() emitConstructedCode = new EventEmitter<string>();
  @Output() inputFocused = new EventEmitter<any>();
  @Input() numberOfInputDigits :number;
  numberOfInput = [];
  keyboard: Keyboard = null;
  currentInputIndex:number;
  password = [];
  private keyupListener: (event: any) => void;

  handleZoomIn(event) {
    if (event && event.prevent) {
      event.prevent.default();
    }
  }

  onInputFocus(event) {
    this.currentInputIndex = Number(event.target['id'].slice(-1));
  }

  isListenerAdded(): boolean {
    return typeof this.keyupListener === 'function';
  }

  reportPinInputFocused() {
   this.password = [];
    this.numberOfInput = new Array(this.numberOfInputDigits).fill(null);
    this.keyboard?.destroy();
    setTimeout((()=>{
      if (this.isListenerAdded()) {
        document.querySelectorAll('.verification-code-inputs input').forEach((el: HTMLInputElement) => {
          el.removeEventListener('keyup', this.keyupListener);
        });
      }
      document.getElementById(`pin-input-1`)?.focus();
      this.keyboard =  new Keyboard(document.querySelector('#popMyKeyboard'),{
        onKeyPress: button => this.onKeyPress(button),
        layout: KeyboardLayout[1],
        theme: KeyboardThemes.numeric
      });
      const inputElements = document.querySelectorAll('.verification-code-inputs input');
      if(inputElements){
        inputElements.forEach((input: HTMLInputElement) => {
          input.value = '';
        });
      }
      this.keyupListener = (event: any) => {
        if (event?.key === 'Backspace') {
          this.pinInputPinChange('');
        } else {
          if (event?.target?.value) {
            this.pinInputPinChange(event?.target?.value);
          }
        }
      };
      document.querySelectorAll('.verification-code-inputs input').forEach((el: HTMLInputElement) => {
        el.addEventListener('keyup', this.keyupListener);
      });
    }),100)
  }

  onKeyPress(button: string) {
    if (button === '{bksp}') {
     this.pinInputPinChange('')
   }else{
     this.pinInputPinChange(button)
   }
 }

 pinInputPinChange(value:string)
 {  
   if(value) {
     document.getElementById(`pin-input-${Number(this.currentInputIndex)}`)['value'] = Number(value[0])
    this.password[this.currentInputIndex-1] = value[0]
       document.getElementById(`pin-input-${Number(this.currentInputIndex)+1}`)?.focus()
   } else { 
     document.getElementById(`pin-input-${Number(this.currentInputIndex)}`)['value'] = null
     this.password[this.currentInputIndex-1] = null
     document.getElementById(`pin-input-${Number(this.currentInputIndex)-1}`)?.focus()
   }
   if(this.password?.join('').length === this.numberOfInput?.length){
     this.emitConstructedCode.emit(this.password.join(''));
   }
 }
}
