import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {accountActions} from '.';
import {KioskStoreService} from "src/app/services/kiosk.store.service";
import {catchError, from, map, of, switchMap} from "rxjs";
import {IUserProfile} from "src/app/interface/maestro-interface";

@Injectable()
export class accountEffects {
  constructor(
    private actions$: Actions,
    private kioskStoreService: KioskStoreService
  ) {
  }

  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.getUserProfile),
      switchMap(() => {
        return from(this.kioskStoreService.getUserProfile()).pipe(
          map((res: IUserProfile) => {
              return accountActions.onUserProfileFetchSuccess({details: res})
            }
          ),
          catchError(() =>
            of(accountActions.onUserProfileFetchFailure())
          )
        );
      })
    )
  );
}
