export enum Subject {
    USER = 'User' ,
    ASSET = 'Asset' ,
    INSPECTION  = 'Inspection',
    SERVICE = 'Service',
    SETTINGS = 'Settings',
    GROUP = 'group',
    READ_ROLE = 'read_role',
    WRITE_ROLE = 'write_role',
    DELETE_ROLE = 'delete_role',
    UPDATE_ROLE = 'update_role',
    TRACKER = 'tracker',
    GEOFENCE ='geoFence',
    TRACKER_MAINTENANCE = 'tracker-maintenance',
    SUBSCRIPTION = 'subscription',
    DASHBOARD = 'dashboard',
    APIKEY = 'apiKey',
    ROLE_MANAGEMENT = 'role_management',
    FACE_MATCH = 'faceMatch',
    SECURITY = 'security',
    AUTO_REGISTER = 'autoRegister',
    SECURITY_AUTHORIZE = 'security_authorize',
    ACCOUNT = 'account',
    ASSET_TYPES = 'assetType',
    DISCOVER = 'discover',
    ATTENDANCE = 'attendance',
    KEY_MANAGEMENT = 'keyManagement',
    KEY_MANAGEMENT_QUEUE = 'keyManagementQueue',
    KEY_MANAGEMENT_ASSIGNMENT = 'keyManagementAssignment',
    DELIVERY_MANAGEMENT = 'deliveryManagement',
    O2D_MANAGEMENT = 'o2d',
    MMD_MANAGEMENT = 'mmd',
    LMD_MANAGEMENT = 'lmd',
    KIOSK_MANAGEMENT = 'kiosk',
    UI_SETTINGS = 'uiSettings',
    WAREHOUSE = 'warehouse',
    MMD_EVENT = 'mmdEvent',
    MMD_NOTIFICATION = 'mmdNotification',
    LMD_EVENT = 'lmdEvent',
    LMD_NOTIFICATION = 'lmdNotification',
    WAREHOUSE_EVENT = 'warehouseEvent',
    WAREHOUSE_NOTIFICATION = 'warehouseNotification',
    KIOSK_EVENT='kioskEvent',
    KIOSK_NOTIFICATION='kioskNotification',
    ROLE_ASSET_AUTH = 'roleAssetAuth',
    AUTHORIZATION = 'authorization',
    ACCOUNT_SETTINGS = 'accountSettings',
    SELF_ACCOUNT='selfAccount',
    SELF_PROFILE='selfProfile',
    ACCOUNT_ASSET_AUTHORIZATION = 'accountAssetAuthorization',
    K_AGENT = 'kAgent',
    PLUGIN ='plugin',
    ROUTE_PLAN_MANAGEMENT='routePlanManagement',
    MMD_REPORTS = 'mmdReports',
    TABLE_MANAGEMENT='tableManagement',
    GUEST_MANAGEMENT='guestManagement',
    POS_MANAGEMENT = 'pos',
    KIOSK_REPORTS = 'kioskReports',
    POS_REPORTS = 'posReports',
  }
export enum Actions {
  READ = 'read',
  WRITE = 'write',
  UPDATE = 'update',
  DELETE = 'delete',
  MANAGE = 'manage',
  ANALYTICS = 'analytics',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  DOWNLOAD = 'download',
  LIVE = 'live',
  SETTINGS = 'settings',
  AGENT_APP_SETTINGS = 'agentAppSettings',
  ASSIGN_TAB = 'assignTab',
  ORDER = 'order',
  CONSIGNMENT = 'consignment',
  QUEUE = 'queue',
  ADVANCE_SETTINGS = 'advancedSettings',
  BASIC_SETTINGS = 'basicSettings',
  MONITORING = 'monitoring',
  MANAGEMENT = 'management',
  ASSET_UPLOAD = 'assetUpload',
  CHANGE_PASSWORD = 'changePassword',
  UPLOAD_LOGO = 'uploadLogo',
  ASSET_EXCEPTION = 'assetException',
  LANGUAGE = 'language',
  PLUGIN = 'plugin',
  KIOSK_MANAGEMENT = 'kioskManagement',
  API_KEY = 'apiKey',
  SUPERADMIN = 'superAdmin',
  ROUTE_PLAN_BULK_UPLOAD = 'routePlanBulkUpload',
  UPDATE_LOCATION = 'updateLocation',
  ACCESS_ALL = 'accessAll',
  ROUTE_PLAN = 'routePlan',
  STORE = 'store',
  TABLE_IN_STORE = 'tableInStore',
  IS_ONLY_MAESTRO = 'isOnlyMaestro',
  MAESTRO = 'maestro',
  PRINT = 'print',
  SWITCH_TABLE = 'switchTable',
  EDIT_KITCHEN_ORDER = 'editKitchenOrder',
  SALES = 'sales',
  SHIFT = 'shift',
  PAY = 'pay',
  SHOW_SALES = 'showSales',
  IS_REPORT_PRINTABLE = 'isReportsPrintable',
  IS_REPORT_DOWNLOADABLE = 'isReportsDownloadable',
  KIOSK_REPORT_STORE_WISE_SALE = 'storeWiseSale',
  KIOSK_REPORT_CITY_WISE_SALE = 'cityWiseSale',
  KIOSK_REPORT_DAILY_SALE = 'dailySales',
  KIOSK_REPORT_DAILY_SALE_KIOSK = 'dailySalesForKiosk',
  KIOSK_REPORT_DAILY_SALE_POS = 'dailySalesForPos',
  POS_OVERALL_SALES_REPORT = 'overallSalesReport',
  POS_SALES_REPORT_BY_DINETYPE = 'salesReportByDineType',
  CASH = 'cash',
  ONLINE = 'online',
  QR= 'qr',
  ALLOW_AFTER_PRINT_RECEIPT= 'allowAfterPrintReciept'
}
