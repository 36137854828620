import { Component, HostListener, OnInit } from '@angular/core';

import { Router, RouterOutlet } from '@angular/router';
// import {TranslateService} from "@ngx-translate/core";
import { Ability, PureAbility } from '@casl/ability';
import { ToastModule } from "primeng/toast";
import { accountActions } from './state/account';
import { AppState } from './state/app.state';
import { Store } from '@ngrx/store';
import { RxdbSyncService } from "src/sync/rxdb.sync.service";
import { PrintReceiptService } from "src/app/services/print-receipt.service";
import { WebWorkersService } from "src/app/workers/web.workers.service";
import { T_EVENT, WW } from './constant';
import { ButtonModule } from "primeng/button";
import { KioskOrderService } from "src/app/services/kiosk-order-service";
import { MessageService } from "primeng/api";
import { PwaUpdateService } from "src/app/services/pwa-update.service";
import { LangUtilService } from './services/lang.util.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, ToastModule, ButtonModule],
  providers: [Ability, PureAbility]
})
export class AppComponent implements OnInit {
  title = 'iotzen-ui-maestro';
  netCheckTimer = null;

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    event.preventDefault();
    // console.log('radheradhe');
  }

  @HostListener('document:dblclick', ['$event'])
  onDoubleClick(event: MouseEvent) {
    event.preventDefault();
  }

  constructor(
    // private translate: TranslateService,
    private ability: Ability,
    private router: Router,
    private store: Store<AppState>,
    private rxdb: RxdbSyncService,
    private print: PrintReceiptService,
    private ww: WebWorkersService,
    private kioskOrderService: KioskOrderService,
    private msgService: MessageService,
    private update: PwaUpdateService,
    private langUtilService: LangUtilService
  ) {
  }

  ngOnInit() {
    const lang = localStorage.getItem('user_language');
    this.langUtilService.updateUserLanguage(lang);
    if (localStorage.getItem('rules')) {
      this.ability.update(JSON.parse(localStorage.getItem('rules')));
    }

    if (localStorage.getItem('token')) {
      this.router.navigate(['zenpos']).then(_r => this.store.dispatch(accountActions.getUserProfile()));
    }

    this.ww.on(WW.E_CONNECTIVITY, (data) => {
      if (data?.event === WW.E_CONNECTIVITY) {
        if (!data?.result) {
          this.store.dispatch(accountActions.checkInterconnectionAvailable({ isOnline: data.result }));
        } else {
          this.store.dispatch(accountActions.checkInterconnectionAvailable({ isOnline: data.result }));
        }
      }
    });
    this.netCheckTimer = setInterval(() => this.ww.emit(WW.E_CONNECTIVITY, {
      event: WW.E_CONNECTIVITY,
      data: {
        url: `https://1.1.1.1/`,
      }
    }), 1000 * 5);

    setTimeout(() => this.update.checkUpdate(), 1000 * 5);
  }

  async actionToast(data: any) {
    this.msgService.clear('action-error');
    data = { ...data, order: { ...data?.order, isRePrinting: true } };
    switch (data.event) {
      case T_EVENT.REPRINT:
        this.kioskOrderService.printerAndNotify(data?.order, data?.template, true);
        break;
      case T_EVENT.LOGIN:
        await this.logout();
        break;
    }
  }

  async logout() {
    const clean = ['token', 'userId', 'KIOSK_STORE_LOCATION', 'account', 'userRoles', 'rules', 'expiry'];

    for (let i = 0; i < clean.length; i++) {
      const s = clean[i];
      if (localStorage.getItem(s)) {
        localStorage.removeItem(s);
      }
    }

    if (this.netCheckTimer) {
      clearInterval(this.netCheckTimer);
    }

    this.ability.update([]);
    await this.print.clean();
    await this.rxdb.clean();
    await this.router.navigate(['']);
    document.location.reload();
  }


  // startPolling = async (interval: number): Promise<void> => {
  //   const pollingInterval = interval || 10000;
  //   this._ngZone.runOutsideAngular(async () => {
  //     const poll = async () => {
  //       const isOnline = await this.onlineCheckService.checkOnlineStatus();
  //       if (!isOnline) {
  //         this.store.dispatch(accountActions.checkInterconnectionAvailable({isOnline: isOnline}));
  //       } else {
  //         this.store.dispatch(accountActions.checkInterconnectionAvailable({isOnline: isOnline}));
  //       }
  //       setTimeout(poll, pollingInterval);
  //     };
  //     await poll();
  //   }).then(console.log).catch(console.error);
  // };
}
