import { Routes } from '@angular/router';
import { authGuard } from './auth/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { MaestroPageComponent } from './components/maestro-page/maestro-page.component';

export const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'zenpos',
    component: MaestroPageComponent,
    canActivate: [authGuard]
  },
  {
    path: 'sync',
    loadComponent: () => import('../app/components/sync/sync.component').then(e => e.SyncComponent),
    canActivate: [authGuard]
  }
];
